import {
  GetBalanceTransactionIdResponse,
  GetBalanceTransactionsParams,
  GetBalanceTransactionsResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getBalanceTransaction = async (
  params?: GetBalanceTransactionsParams,
  headers?: { [key: string]: string },
) => {
  try {
    return await http.authGet<GetBalanceTransactionsResponse>(`balance-transactions`, {
      params,
      headers,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getBalanceTransactionWithId = async (id: string) => {
  try {
    return await http.authGet<GetBalanceTransactionIdResponse>(`balance-transactions/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default { getBalanceTransaction, getBalanceTransactionWithId };
