import { IconButton } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Checkbox from 'src/component/Checkbox';
import { ThemeContext } from 'src/context/ThemeContext';
import { BankAccount } from 'src/model/Bank';

export type Props = {
  data: BankAccount[];
  onClickDelete: (id: string) => void;
  isBatchClick: boolean;
  selected: BankAccount[];
  onSelectOne: (v: BankAccount) => void;
};

const AccountCard = ({ data, onClickDelete, isBatchClick, selected, onSelectOne }: Props) => {
  const { t } = useTranslation();
  const { IcOpenInNew } = useContext(ThemeContext).image;
  const elementsMapFunction = (v: BankAccount) => {
    const handleOpenInNew = () => {
      if (!v.imageUrl) return;
      const tab = window.open(v.imageUrl, '_blank');
      if (!tab) return;
      tab.focus();
    };

    return (
      <div key={v.id}>
        <div className="relative my-[15px] flex flex-col gap-1 text-[14px] leading-[21px]">
          {v.paymentMethodType === 'bank' && (
            <>
              <div className="flex items-center gap-[15px]">
                <div className="font-bold text-grey-700 dark:text-grey-300">
                  {t('bankAccount.desc.accountName')}
                </div>
                <div>{v.name}</div>
              </div>
              <div className="flex items-center gap-[15px]">
                <div className="font-bold text-grey-700 dark:text-grey-300">
                  {t('bankAccount.desc.bankInformation')}
                </div>
                <div>{v.bankName}</div>
              </div>
              <div className="flex items-center gap-[15px]">
                <div className="font-bold text-grey-700 dark:text-grey-300">
                  {t('bankAccount.desc.accountId')}
                </div>
                <div>{v.account}</div>
              </div>
              <div className="flex items-center gap-[15px]">
                <div className="font-bold text-grey-700 dark:text-grey-300">
                  {t('bankAccount.desc.bankProvinceCity')}
                </div>
                <div>
                  {v.province}/{v.city}
                </div>
              </div>
              <div className="flex items-center gap-[15px]">
                <div className="font-bold text-grey-700 dark:text-grey-300">
                  {t('bankAccount.desc.bankBranchName')}
                </div>
                <div>{v.bankBranchName}</div>
              </div>
            </>
          )}
          {v.paymentMethodType === 'alipay' && (
            <>
              <div className="flex items-center gap-[15px]">
                <div className="font-bold text-grey-700 dark:text-grey-300">
                  {t('bankAccount.desc.alipayAccountName')}
                </div>
                <div>{v.name}</div>
              </div>
              <div className="flex items-center gap-[15px]">
                <div className="font-bold text-grey-700 dark:text-grey-300">
                  {t('bankAccount.desc.alipayAccount')}
                </div>
                <div>{v.account}</div>
              </div>
              {v.imageUrl ? (
                <div className={'flex flex-row  items-center'}>
                  <div> {t('bankAccount.desc.alipayQRCodeUploaded')}</div>
                  <div>
                    <IconButton onClick={handleOpenInNew} sx={{ padding: 0, margin: '2px 4px 0' }}>
                      <img className="w-[24px]" src={IcOpenInNew} alt="open-in-new" />
                    </IconButton>
                  </div>
                </div>
              ) : (
                <div>{t('bankAccount.desc.alipayQRCodeUnuploaded')}</div>
              )}
            </>
          )}
          {isBatchClick ? (
            <div className="absolute right-0 top-1/2 -translate-y-1/2">
              <Checkbox
                checked={selected.map((o) => o.id).includes(v.id)}
                onChange={() => onSelectOne(v)}
              />
            </div>
          ) : (
            <Button
              appearance="text"
              className="absolute bottom-0 right-0 font-bold text-crimson active:opacity-30 disabled:opacity-30 dark:text-red"
              onClick={() => onClickDelete(v.id)}
            >
              {t('account.act.delete')}
            </Button>
          )}
        </div>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      </div>
    );
  };

  return (
    <div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      {data.map(elementsMapFunction)}
    </div>
  );
};

export default AccountCard;
