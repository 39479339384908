import announcementEndpoint from 'src/api/announcementEndpoint';
import { AnnouncementForm } from 'src/model/Form';
import { PaginationParams } from 'src/model/Pagination';
import { dispatch } from 'src/redux/store';
import { finishWaiting, reduceUnreadAnnouncement, startWaiting } from 'src/redux/uiSlice';

export const getAnnouncementList = async (params?: PaginationParams) => {
  try {
    dispatch(startWaiting());
    const res = await announcementEndpoint.getAnnouncement({ ...params, order: 'desc' });

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const createAnnouncement = async (data: AnnouncementForm) => {
  try {
    dispatch(startWaiting());
    await announcementEndpoint.createAnnouncement({
      ...data,
      unpinnedAt: data.unpinnedAt === '' ? null : data.unpinnedAt,
      unpublishedAt: data.unpublishedAt === '' ? null : data.unpublishedAt,
    });
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateAnnouncement = async (id: string, data: AnnouncementForm) => {
  try {
    dispatch(startWaiting());
    await announcementEndpoint.updateAnnouncement(id, {
      ...data,
      unpinnedAt: data.unpinnedAt === '' ? null : data.unpinnedAt,
      unpublishedAt: data.unpublishedAt === '' ? null : data.unpublishedAt,
    });
  } finally {
    dispatch(finishWaiting());
  }
};

export const getAnnouncementById = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await announcementEndpoint.getAnAnnouncement(id);

    if (res.data.readAt === null) {
      await announcementEndpoint.readAnnouncement(id);
      dispatch(reduceUnreadAnnouncement());
    }

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const deleteAnnouncementById = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await announcementEndpoint.deleteAnnouncement(id);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};
