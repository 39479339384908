import { useState } from 'react';
import { DateRange } from 'src/model/DateFilter';
import { calibrateBeginDate, calibrateEndDate } from 'src/util/datetimeHelper';

type State = {
  defaultFrom?: string;
  defaultTo?: string;
};

export const useDateFilter = ({ defaultFrom, defaultTo }: State = {}) => {
  const [toDate, setToDate] = useState<Date>(
    defaultTo ? new Date(defaultTo) : calibrateEndDate(new Date()),
  );
  const [fromDate, setFromDate] = useState<Date>(
    defaultFrom ? new Date(defaultFrom) : calibrateBeginDate(new Date(toDate.getTime())),
  );

  const setDateRange = (dateRange: DateRange) => {
    dateRange.from && setFromDate(calibrateBeginDate(dateRange.from));
    dateRange.to && setToDate(calibrateEndDate(dateRange.to));
  };

  return {
    begin: fromDate.toISOString(),
    end: toDate.toISOString(),
    dateFilterProps: {
      fromDate,
      toDate,
      setDateRange,
    },
  };
};
