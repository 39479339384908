import { t } from 'i18next';
import {
  GetWithdrawalContactResponse,
  PostWithdrawalContactRequest,
  PostWithdrawalContactResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getWithdrawalContactList = async () => {
  try {
    return await http.authGet<GetWithdrawalContactResponse>('withdrawal-contacts');
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const createWithdrawalContact = async (data: PostWithdrawalContactRequest) => {
  try {
    return await http.authPost<PostWithdrawalContactResponse, PostWithdrawalContactRequest>(
      'withdrawal-contacts',
      { data },
    );
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 409 && error.response.data.code === 0)
      throw t('error.withdrawal.saveError');
    throw defaultErrorMessage(error);
  }
};

const deleteWithdrawalContact = async (id: string) => {
  try {
    return await http.authDelete(`withdrawal-contacts/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default { getWithdrawalContactList, createWithdrawalContact, deleteWithdrawalContact };
