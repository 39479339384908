import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import BackButton from 'src/component/BackButton';
import { MediaQuery } from 'src/constant/Media';
import { loadStatements } from 'src/service/reportService';
import { calibrateBeginDate, calibrateEndDate } from 'src/util/datetimeHelper';
import Interval from './component/Interval';
import ReportWithoutTabs from './component/ReportWithoutTabs';
import ReportWithTabs from './component/ReportWithTabs';

const Report = () => {
  const { t } = useTranslation();
  const isBiggerThanXsm = useMediaPredicate(MediaQuery.Xsm);
  const [begin, setBegin] = useState<string>(calibrateBeginDate(new Date()).toISOString());
  const [end, setEnd] = useState<string>(calibrateEndDate(new Date()).toISOString());

  useEffect(() => {
    loadStatements(begin, end);
  }, [begin, end]);

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] text-[28px] font-[600] sm:mt-[20px] sm:text-[32px]">
        {t('report.heading')}
      </div>
      <Interval begin={begin} setBegin={(v) => setBegin(v)} end={end} setEnd={(v) => setEnd(v)} />
      {isBiggerThanXsm ? <ReportWithoutTabs /> : <ReportWithTabs />}
    </div>
  );
};

export default Report;
