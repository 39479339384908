import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import { Ui } from 'src/constant/Env';
import { Page } from 'src/constant/Page';
import { PostAuthEmailResendRequest } from 'src/model/Api';
import { EmailVerificationForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { emailResend, emailVerify } from 'src/service/authService';

/**
 * Create state with a email address when navigating to this page:
 * navigate(Page.Verification, { state: 'pikachu@xxxxmail.com'})
 */
const EmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [count, setCount] = useState<number>(-1);
  const state = location.state as PostAuthEmailResendRequest | null;
  const methods = useForm<EmailVerificationForm>();

  useEffect(() => {
    if (state === null) navigate(Page.Landing);
  }, []);

  const onSubmit = (data: EmailVerificationForm) => {
    if (state === null) return;
    emailVerify(Ui, { ...state, code: data.code })
      .then(() => {
        if (Ui === 'seller') {
          navigate(Page.EnableTfa, { replace: true });
          dispatch(openSnackbar({ message: t('emailVerification.desc.verifySuccess') }));
        } else {
          navigate(Page.SignIn, { replace: true });
          dispatch(openSnackbar({ message: t('emailVerification.desc.alert') }));
        }
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onResend = () => {
    if (state === null) return;

    emailResend(state)
      .then(() => {
        setCount(30);
        dispatch(openSnackbar({ message: t('emailVerification.desc.emailSent') }));
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCount(count - 1);
    }, 1000);
    if (count - 1 < 0) {
      setCount(-1);
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [count]);

  return (
    <div className="mx-auto my-0 flex flex-col px-[15px] xs:max-w-[560px] xs:p-0 sm:max-w-[640px]">
      <div className="text-[28px] font-bold">{t('emailVerification.heading')}</div>
      <div className="mt-[30px] text-[20px] font-bold">{t('emailVerification.desc.emailSent')}</div>
      <Form className="mt-[20px] flex flex-col gap-[25px]" methods={methods} onSubmit={onSubmit}>
        <div className="box-border h-[54px] rounded-[4px] border-b-0 bg-light-200 p-[15px] font-bold text-grey-700 dark:bg-black-800 dark:text-grey-500">
          {state?.email ?? ''}
        </div>
        <FormInput name="code" label={t('emailVerification.desc.verifyCode')} required asterisked />
        <div className="text-right">
          <Button type="submit">{t('act.submit')}</Button>
        </div>
      </Form>
      <div className="mt-[40px] text-dark-700 dark:text-grey-300">
        <div>
          <div className="flex items-center gap-[10px]">
            {t('emailVerification.desc.didNotReceive')}

            <Button
              appearance="secondary"
              size="small"
              type="button"
              onClick={onResend}
              disabled={count > 0}
            >
              {count > 0 ? count : t('emailVerification.act.resend')}
            </Button>
          </div>
          <ul className="mx-0 my-[13px] pl-[24px]">
            {t<string, string[]>('emailVerification.desc.noticeItems', {
              returnObjects: true,
              defaultValue: [],
            }).map((value, index) => (
              <li key={`notice${index}`} className="pb-[10px] text-[14px]">
                {value}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
