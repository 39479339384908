import { Modal as MuiModal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IcClose from 'src/image/ic-close-image.svg';
import IcOpen from 'src/image/ic-open.svg';

/**
 * usage example:
 *
 * <button type="button" onClick={() => setOpen(true)}>Preview</button>
 * <Image
 *   src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
 *   open={open}
 *   onClose={() => setOpen(false)}
 * />
 */

type Props = {
  src: string;
  open: boolean;
  onClose: () => void;
};

const Image = ({ src, open, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <MuiModal open={open} onClose={onClose} keepMounted>
      <>
        <div className="box-border flex h-[70px] items-center justify-between px-[30px] py-[15px] xs:p-[15px]">
          <a
            className="flex cursor-pointer items-center font-bold text-white no-underline"
            href={src}
            target="_blank"
            rel="noreferrer"
            onClick={onClose}
          >
            <img src={IcOpen} />
            <div>{t('desc.openInNewTab')}</div>
          </a>
          <img src={IcClose} onClick={onClose} className="cursor-pointer" />
        </div>
        <img
          className="absolute left-1/2 top-1/2 max-h-[calc(100vh-200px)] w-auto max-w-[calc(100vw-30px)] translate-x-[-50%] translate-y-[-50%] xs:max-w-[80vw]"
          src={src}
        />
      </>
    </MuiModal>
  );
};

export default Image;
