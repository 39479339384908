import { t } from 'i18next';
import announcementEndpoint from 'src/api/announcementEndpoint';
import coinEndpoint from 'src/api/coinEndpoint';
import meEndpoint from 'src/api/meEndpoint';
import notificationEndpoints from 'src/api/notificationEndpoints';
import orderEndpoint from 'src/api/orderEndpoint';
import audioNotify from 'src/audio/notify.wav';
import audioStop from 'src/audio/stop.mp3';
import { Ui } from 'src/constant/Env';
import { Type } from 'src/constant/Notification';
import { Page } from 'src/constant/Page';
import { Crypto, Fiat } from 'src/model/Coin';
import { Notification as NotificationType, ProcessedNotification } from 'src/model/Notification';
import { setAccountInfo } from 'src/redux/authSlice';
import { setCoin } from 'src/redux/coinSlice';
import { dispatch, getState } from 'src/redux/store';
import {
  finishWaiting,
  pushNotification,
  setDemand,
  setPinnedAnnouncement,
  setUnreadAnnouncement,
  startWaiting,
} from 'src/redux/uiSlice';

export const init = async () => {
  try {
    dispatch(startWaiting());
    if ('Notification' in window && Notification.permission !== 'denied')
      Notification.requestPermission();
    if (Ui === 'admin') await Promise.all([loadCoins(), loadOwnInfo()]);
    else await Promise.all([loadUnreadAnnouncement(), loadCoins(), loadOwnInfo()]);
  } finally {
    dispatch(finishWaiting());
  }
};

const loadUnreadAnnouncement = async () => {
  const res = await announcementEndpoint.getAnnouncement({ unread: 'yes' });

  dispatch(setUnreadAnnouncement(res.data.filter((v) => v.readAt === null).length));
  dispatch(
    setPinnedAnnouncement(
      res.data.filter((v) => v.unpinnedAt !== null && new Date() < new Date(v.unpinnedAt)),
    ),
  );
};

export const loadCoins = async () => {
  const res = await coinEndpoint.listCoins();

  const crypto: Crypto[] = [];
  const fiat: Fiat[] = [];

  res.data.forEach((v) => {
    if (v.type === 'crypto') crypto.push(v);
    else fiat.push(v);
  });

  dispatch(setCoin({ crypto, fiat }));
};

const loadOwnInfo = async () => {
  const res = await meEndpoint.getOwnInfo();
  dispatch(setAccountInfo(res.data));
};

export const loadNotification = () => {
  notificationEndpoints.getNotificationList().then((res) => {
    if (res.data.length > 0) {
      const lastNotifications = localStorage.getItem('notification')?.split(',');
      const newNotifications = res.data.filter((v) => {
        if (!lastNotifications) return true;

        return !lastNotifications.includes(v.id);
      });
      if (newNotifications.filter((v) => Object.values(Type).includes(v.type)).length > 0) {
        const processedNotification = handleNotification(newNotifications);
        notify(processedNotification);
      }

      localStorage.setItem('notification', res.data.map((v) => v.id).join());
    } else localStorage.removeItem('notification');
  });
};

const handleNotification = (notifications: NotificationType[]) =>
  notifications.map((v) => {
    let message = '';
    let to = '';
    if (v.type === Type.FillCreated)
      if (Ui === 'seller') {
        message = t('notification.fillCreated');
        to = Page.Order;
      } else if (Ui === 'buyer' && v.fill) {
        message = t('notification.fillCreated', { orderId: v.fill.orderId });
        to = `${Page.Order}/${v.fill.orderId}`;
      }
    if (v.type === Type.FillReceiptUploaded && v.fill) {
      message = t('notification.fillReceiptUploaded', { fillId: v.fill.id });
      to = Page.Order;
    }
    if (v.type === Type.FillTotalSent && v.fill) {
      message = t('notification.fillTotalSent', { fillId: v.fill.id });
      to = Page.Order;
    }
    if (v.type === Type.FillTotalReceived && v.fill) {
      message = t('notification.fillTotalReceived', { fillId: v.fill.id });
      to = `${Page.Order}/${v.fill.orderId}`;
    }
    if (v.type === Type.FillTotalUnsentByAdmin && v.fill) {
      message = t('notification.fillTotalUnsentByAdmin', { fillId: v.fill.id });
      to = `${Page.Order}/${v.fill.orderId}`;
    }
    if (v.type === Type.FillRejected && v.fill) {
      message = t('notification.fillRejected', { fillId: v.fill.id });
      to = `${Page.Order}/${v.fill.orderId}`;
    }
    if (v.type === Type.FillCanceled && v.fill) {
      message = t('notification.fillCanceled', { fillId: v.fill.id });
      to = `${Page.Order}/${v.fill.id}`;
    }
    if (v.type === Type.OrderCanceledByAdmin && v.order) {
      message = t('notification.orderCanceledByAdmin', { orderId: v.order.id });
      to = `${Page.Order}/${v.order.id}`;
    }
    if (v.type === Type.DepositReceived && v.deposit) {
      message = t('notification.depositReceived');
      to = `${Page.Deposit}/${v.deposit.id}`;
    }
    if (v.type === Type.WithdrawalVerified && v.withdrawal) {
      message = t('notification.withdrawalVerified');
      to = `${Page.WithdrawalRecord}?approved=1`;
    }
    if (v.type === Type.AssetFreezing && v.withdrawal) {
      message = t('notification.assetFreezing');
      to = `${Page.Home}/${v.withdrawal.cryptoId}/frozen`;
    }
    if (v.type === Type.AssetFrozen && v.withdrawal) {
      message = t('notification.assetFrozen');
      to = `${Page.Home}/${v.withdrawal.cryptoId}/frozen`;
    }
    if (v.type === Type.InsufficientCrypto && v.crypto) {
      message = t('notification.insufficientCrypto', { cryptoId: v.crypto.id });
      to = `${Page.Home}/${v.crypto.id}/frozen`;
    }
    if (v.type === Type.InsufficientOnChainFeeCrypto && v.crypto) {
      message = t('notification.insufficientOnChainFeeCrypto', { cryptoId: v.crypto.id });
      to = `${Page.Home}/${v.crypto.id}/frozen`;
    }

    return { id: v.id, type: v.type, message, to };
  });

const notify = (notifications: ProcessedNotification[]) => {
  // play audio, if only 'fill-canceled' play stop, or play notify
  if (notifications.filter((v) => v.type === 'fill-canceled').length === notifications.length)
    new Audio(audioStop).play();
  else new Audio(audioNotify).play();

  // display toast component
  dispatch(pushNotification(notifications));

  // browser notification api
  if ('Notification' in window && Notification.permission === 'granted')
    notifications.forEach((v) => {
      const notification = new Notification(v.message);
      notification.onclick = () => {
        window.open(`${location.origin}${v.to}`);
      };
    });
};

export const loadDemand = async () => {
  if (Ui !== 'seller') return;

  const res = await orderEndpoint.getDemand();
  if (res.data.length === 0) dispatch(setDemand(null));
  else {
    const {
      ui: { demand },
    } = getState();

    if (demand === null || demand.amount !== res.data[0].amount) new Audio(audioNotify).play();
    dispatch(setDemand({ base: res.data[0].base, amount: res.data[0].amount }));
  }
};
