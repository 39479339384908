import { isValidElement, ReactNode, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import SelectContext from 'src/context/SelectContext';
import IcArrowDown from 'src/image/ic-arrow-down.svg';
import Popover from './Popover';

/**
 * usage example:
 *
 * <RoundSelect>
 *   <SelectOption value="aa">a</SelectOption>
 *   <SelectOption value="bb">b</SelectOption>
 *   <SelectOption value="cc">c</SelectOption>
 * </RoundSelect>
 */

export type Props = {
  children: ReactNode | ReactNode[];
  className?: string;
  defaultValue?: string;
  value?: string;
  onChange?: (v: string) => void;
  label?: string;
};

const RoundSelect = ({
  children,
  className,
  defaultValue,
  value: controlledSelectedValue,
  onChange,
  label,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<{ value: string; children: ReactNode }[]>([]);
  const [selected, setSelected] = useState<string>(defaultValue ?? '');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOptions(
      (Array.isArray(children) ? children : [children]).map((child) =>
        isValidElement(child) ? child.props : null,
      ),
    );
  }, []);

  const handleChange = (v: string) => {
    setOpen(false);
    if (controlledSelectedValue === undefined) setSelected(v);

    onChange && onChange(v);
  };

  return (
    <SelectContext.Provider value={{ current: controlledSelectedValue ?? selected, handleChange }}>
      <div className="box-border flex h-[30px] flex-row items-center gap-2 rounded-[4px] border border-solid border-light-500 pl-[8px] dark:border-dark-500">
        {label && <div className="text-[16px] text-dark-500 dark:text-grey-300">{label}</div>}
        <div
          className={twMerge(
            'flex cursor-pointer items-center text-black-900 dark:text-white',
            className,
          )}
          onClick={() => setOpen(!open)}
          ref={ref}
        >
          <div>
            {options.find((v) => v.value === (controlledSelectedValue ?? selected))?.children}
          </div>
          <img src={IcArrowDown} />
        </div>
      </div>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        cssProperties={{ width: 'fit-content' }}
      >
        {children}
      </Popover>
    </SelectContext.Provider>
  );
};

export default RoundSelect;
