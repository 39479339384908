import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { MediaQuery } from 'src/constant/Media';
import IcUsdt from 'src/image/ic-usdt.svg';
import { RebateInfo } from 'src/model/Rebate';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  info: RebateInfo;
  showEmail?: boolean;
  showCoin?: boolean;
  showDivider?: boolean;
};

const Statement = ({ info, showEmail = false, showCoin = false, showDivider = true }: Props) => {
  const { t } = useTranslation();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);

  if (isBiggerThanMd)
    return (
      <>
        <div className="flex items-center">
          {showEmail && <div className="w-[calc(100%/3)] px-0 py-[16px]">{info.email}</div>}
          {!showEmail && <div className="w-[calc(100%/3)] px-0 py-[16px]" />}
          <div className="w-[calc(100%/3)] px-0 py-[16px]">{info.askAmount}</div>
          <div className="w-[calc(100%/3)] px-0 py-[16px]">{info.rebateAmount}</div>
        </div>
        {showDivider && <div className="h-[1px] bg-light-200 dark:bg-dark-700" />}
      </>
    );

  return (
    <>
      {showCoin && (
        <div className="flex items-center gap-[8px]">
          <img src={IcUsdt} />
          <div className="font-bold text-black-900 dark:text-white">USDT</div>
        </div>
      )}
      <div className="mt-[15px]">
        {showEmail && <div className="mb-[4px] text-[14px]">{info.email}</div>}
        <div className="mb-[15px]">
          <div className="flex items-center gap-[15px]">
            <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300/80">
              {t('report.desc.amount')}
            </div>
            <div>{bnFormat(info.askAmount)}</div>
          </div>
          <div className="flex items-center gap-[15px]">
            <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300/80">
              {t('report.desc.rebate')}
            </div>
            <div>{bnFormat(info.rebateAmount)}</div>
          </div>
        </div>
        {showDivider && <div className="h-[1px] bg-light-200 dark:bg-dark-700" />}
      </div>
    </>
  );
};

export default Statement;
