import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Page } from './constant/Page';
import Account from './page/account/Account';
import AccountBatchConfirm from './page/accountBatchConfirm/AccountBatchConfirm';
import AddressManagement from './page/addressManagement/AddressManagement';
import Agency from './page/agency/Agency';
import Announcement from './page/announcement/Announcement';
import AnnouncementDetail from './page/announcementDetail/AnnouncementDetail';
import ForgetPassword from './page/authForgetPassword/ForgetPassword';
import Register from './page/authRegister/Register';
import SignIn from './page/authSignIn/SignIn';
import SignOut from './page/authSignOut/SignOut';
import BankAccount from './page/bankAccount/BankAccount';
import CommissionDetail from './page/commissionDetail/CommissionDetail';
import ContactUs from './page/contactUs/ContactUs';
import Deposit from './page/deposit/Deposit';
import DepositDetail from './page/depositDetail/DepositDetail';
import EmailVerification from './page/emailVerification/EmailVerification';
import EnableTfa from './page/enableTfa/EnableTfa';
import LandingAsk from './page/landingAsk/LandingAsk';
import MyTrade from './page/myTrade/MyTrade';
import OrderAll from './page/orderAll/OrderAll';
import OrderAsk from './page/orderAsk/OrderAsk';
import OrderAskDetail from './page/orderAskDetail/OrderAskDetail';
import Privacy from './page/privacy/Privacy';
import Property from './page/property/Property';
import Record from './page/record/Record';
import Report from './page/report/Report';
import ReportCommission from './page/reportCommission/ReportCommission';
import Support from './page/support/Support';
import TermsOfUse from './page/termsOfUse/TermsOfUse';
import TradeAsk from './page/tradeAsk/TradeAsk';
import Trading from './page/trading/Trading';
import TradingBatch from './page/tradingBatch/TradingBatch';
import TradingBatchConfirm from './page/tradingBatchConfirm/TradingBatchConfirm';
import TradingConfirm from './page/tradingConfirm/TradingConfirm';
import Withdrawal from './page/withdrawal/Withdrawal';
import WithdrawalDetail from './page/withdrawalDetail/WithdrawalDetail';
import WithdrawalSuccess from './page/withdrawalSuccess/WithdrawalSuccess';
import { RootState } from './redux/store';

const AppRoutes = () => {
  const { isLogin, accountInfo } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  const displayAgency = useMemo(() => {
    if (accountInfo === undefined) return true;

    return accountInfo.isKidsEditable !== true ? false : true;
  }, [accountInfo]);

  return (
    <Routes>
      <Route path={Page.Landing} element={<LandingAsk />} />
      <Route path={Page.TermsOfUse} element={<TermsOfUse />} />
      <Route path={Page.Privacy} element={<Privacy />} />
      {!isLogin && (
        <>
          <Route path={Page.SignIn} element={<SignIn />} />
          <Route path={Page.Register} element={<Register />} />
          <Route path={Page.ForgetPassword} element={<ForgetPassword />} />
          <Route path={Page.Verification} element={<EmailVerification />} />
          <Route
            path="*"
            element={<Navigate to={Page.SignIn} state={{ from: location.pathname }} />}
          />
        </>
      )}
      {isLogin && (
        <>
          <Route path={Page.SignOut} element={<SignOut />} />
          <Route path={Page.Trade} element={<TradeAsk />} />
          <Route path={Page.MyTrade} element={<MyTrade />} />
          <Route path={Page.Trading} element={<Trading />} />
          <Route path={Page.TradingBatch} element={<TradingBatch />} />
          <Route path={Page.TradingConfirm} element={<TradingConfirm />} />
          <Route path={Page.TradingBatchConfirm} element={<TradingBatchConfirm />} />
          <Route path={Page.Order} element={<OrderAsk />} />
          <Route path={Page.OrderAll} element={<OrderAll />} />
          <Route path={`${Page.Order}/:orderId`} element={<OrderAskDetail />} />
          <Route path={Page.Property} element={<Property />} />
          <Route path={Page.Deposit} element={<Deposit />} />
          <Route path={`${Page.Deposit}/:recordId`} element={<DepositDetail />} />
          <Route path={Page.Withdrawal} element={<Withdrawal />} />
          <Route path={`${Page.Withdrawal}/:recordId`} element={<WithdrawalDetail />} />
          <Route path={`${Page.Commission}/:commissionId`} element={<CommissionDetail />} />
          <Route path={Page.WithdrawalSuccess} element={<WithdrawalSuccess />} />
          <Route path={Page.AddressManagement} element={<AddressManagement />} />
          <Route path={Page.Record} element={<Record />} />
          <Route path={Page.AccountBatchConfirm} element={<AccountBatchConfirm />} />
          <Route path={Page.Account} element={<Account />} />
          {displayAgency && <Route path={Page.Agency} element={<Agency />} />}
          <Route path={Page.Report} element={<Report />} />
          <Route path={Page.ReportCommission} element={<ReportCommission />} />
          <Route path={Page.EnableTfa} element={<EnableTfa />} />
          <Route path={Page.Announcement} element={<Announcement />} />
          <Route path={`${Page.Announcement}/:id`} element={<AnnouncementDetail />} />
          <Route path={Page.Support} element={<Support />} />
          <Route path={Page.ContactUs} element={<ContactUs />} />
          <Route path={Page.CreateAccount} element={<BankAccount />} />
        </>
      )}
      <Route path="*" element={<Navigate to={Page.Landing} />} />
    </Routes>
  );
};

export default AppRoutes;
