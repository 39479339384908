import { Snackbar as MuiSnackbar, Slide, SlideProps, SnackbarCloseReason } from '@mui/material';
import classNames from 'classnames';
import { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { RootState } from 'src/redux/store';
import { dismissSnackbar } from 'src/redux/uiSlice';

const Transition = (props: SlideProps) => <Slide {...props} direction="down" />;

const Snackbar = () => {
  const { showSnackbar, snackbar } = useSelector((rootState: RootState) => rootState.ui);
  const dispatch = useDispatch();

  const onClose = (event: Event | SyntheticEvent, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') return;
    dispatch(dismissSnackbar());
  };

  return (
    <MuiSnackbar
      open={showSnackbar}
      autoHideDuration={4000}
      onClose={onClose}
      classes={{ root: '!left-0 !right-0 !top-0 h-fit' }}
      TransitionComponent={Transition}
    >
      <div
        className={twMerge(
          'flex h-[48px] w-[100%] items-center bg-gradient-92 from-skyblue to-lime px-[30px] py-0 text-black-900 dark:text-black-700',
          classNames({ 'bg-crimson text-white dark:bg-red': snackbar.severity === 'alert' }),
        )}
      >
        {snackbar.message}
      </div>
    </MuiSnackbar>
  );
};

export default Snackbar;
