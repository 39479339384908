import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProcessedNotification } from 'src/model/Notification';
import { reduceNotification } from 'src/redux/uiSlice';
import Toast from './Toast';

type Props = {
  content: ProcessedNotification[];
};

const ToastGroup = ({ content }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [top, setTop] = useState<number[]>([]);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const tmp = content.map((o, i) =>
      itemsRef.current
        .slice(0, i)
        .map((v) => v?.clientHeight ?? 0)
        .reduce((sum, current) => sum + current + 15, 15),
    );
    setTop(tmp);
  }, [content]);

  const onClose = (i: number) => {
    dispatch(reduceNotification(i));
  };

  return (
    <div>
      {content.map((v, i) => (
        <Toast
          key={v.id}
          myRef={(el) => (itemsRef.current[i] = el)}
          onClose={() => onClose(i)}
          top={top[i] === undefined ? 0 : top[i]}
          message={v.message}
          onNavigate={v.to.startsWith(pathname) ? () => navigate(0) : () => navigate(v.to)}
        />
      ))}
    </div>
  );
};

export default ToastGroup;
