import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import { Contact } from 'src/model/Contact';

type Props = {
  contactList: Contact[];
  onDelete: (id: string) => void;
};

const Table = ({ contactList, onDelete }: Props) => {
  const { t } = useTranslation();

  const elementMapFunction = (contact: Contact, index: number, array: Contact[]) => (
    <div key={contact.id}>
      <div className="flex flex-row py-[16px] text-[14px]">
        <div className="w-1/6">{contact.coin.toUpperCase()}</div>
        <div className={classNames('box-border break-words pr-[10px]', 'w-1/6')}>
          {contact.label}
        </div>
        <div className={classNames('box-border break-words pr-[10px]', 'w-1/2')}>
          {contact.address}
          <span className="ml-[10px] text-turquoise dark:text-lime">
            {contact.network?.toUpperCase()}
          </span>
        </div>
        <div className="text-crimson dark:text-red">
          <Button
            appearance="text"
            className="text-crimson dark:text-red"
            onClick={() => onDelete(contact.id)}
          >
            {t('addressManagement.act.delete')}
          </Button>
        </div>
      </div>
      <div
        className={classNames('h-[1px] bg-light-200 dark:bg-dark-700', {
          ['hidden']: index >= array.length - 1,
        })}
      />
    </div>
  );

  return (
    <div className="px-[40px] pb-[9px] pt-[10px]">
      <div className="flex flex-row py-[16px] text-[14px] text-grey-500 dark:text-grey-700">
        <div className={classNames('w-1/6')}>{t('addressManagement.desc.coin')}</div>
        <div className={classNames('w-1/6')}>{t('addressManagement.desc.label')}</div>
        <div className={classNames('w-1/2')}>{t('addressManagement.desc.address')}</div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      {contactList.map(elementMapFunction)}
    </div>
  );
};

export default Table;
