import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import { Announcement as AnnouncementData } from 'src/model/Announcement';
import { openSnackbar } from 'src/redux/uiSlice';
import { getAnnouncementById } from 'src/service/announcementService';

const AnnouncementDetail = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [announcement, setAnnouncement] = useState<AnnouncementData>();

  useEffect(() => {
    if (id === undefined) return;
    getAnnouncementById(id)
      .then((res) => setAnnouncement(res))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [id]);

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] sm:mt-[20px]">
        <div className="text-[28px] font-bold sm:text-[32px]">{announcement?.subject}</div>
      </div>
      {announcement && (
        <div className="mb-[16px] mt-[40px] text-[16px] text-grey-700 dark:text-grey-300">
          {format(Date.parse(announcement.publishedAt), 'yyyy-MM-dd HH:mm')}
        </div>
      )}
      <div className="my-[16px] h-[1px] bg-light-200 dark:bg-dark-700" />
      <div className="whitespace-pre-line break-words">{announcement?.content}</div>
    </div>
  );
};

export default AnnouncementDetail;
