import { camelCase, isArray, isObject, snakeCase } from 'lodash';

// eslint-disable-next-line
export const snake2Camel = (obj: any): any => {
  if (!isObject(obj) && !isArray(obj)) return obj;

  if (obj instanceof Blob) return obj;

  if (isArray(obj)) return obj.map(snake2Camel);

  const camelObj: { [key: string]: any } = {}; // eslint-disable-line
  Object.entries(obj).forEach(([key, value]) => {
    if (key === snakeCase(key)) camelObj[camelCase(key)] = snake2Camel(value);
    else camelObj[key] = snake2Camel(value);
  });

  return camelObj;
};

// eslint-disable-next-line
export const camel2Snake = (obj: any): any => {
  if (!isObject(obj) && !isArray(obj)) return obj;

  if (isArray(obj)) return obj.map(camel2Snake);

  const snakeObj: { [key: string]: any } = {}; // eslint-disable-line
  Object.entries(obj).forEach(([key, value]) => {
    if (key === camelCase(key)) snakeObj[snakeCase(key)] = camel2Snake(value);
    else snakeObj[key] = camel2Snake(value);
  });

  return snakeObj;
};
