import { t } from 'i18next';
import {
  GetWithdrawalsParams,
  GetWithdrawalsResponse,
  GetWithdrawalsWithIdResponse,
  PatchWithdrawalsIdVerifyRequest,
  PostWithdrawalRequest,
  PostWithdrawalResponse,
  PostWithdrawalsPreviewRequest,
  PostWithdrawalsPreviewResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const postWithdrawalPreview = async (data: PostWithdrawalsPreviewRequest) => {
  try {
    return await http.authPost<PostWithdrawalsPreviewResponse>('withdrawals/preview', {
      data,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const patchWithdrawalVerify = async (id: string, data: PatchWithdrawalsIdVerifyRequest) => {
  try {
    return await http.authPatch(`withdrawals/${id}/verify`, {
      data,
    });
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 409 && error.response.data.code === 0)
      throw t('error.withdrawal.codeExpired');
    throw defaultErrorMessage(error);
  }
};

const postWithdrawal = async (data: PostWithdrawalRequest, code: string) => {
  try {
    return await http.authPost<PostWithdrawalResponse>('withdrawals', {
      data,
      headers: { 'x-api-otp': `gauth=${code}` },
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getWithdrawals = async (
  params: GetWithdrawalsParams,
  headers?: { [key: string]: string },
) => {
  try {
    return await http.authGet<GetWithdrawalsResponse>('withdrawals', {
      params,
      headers,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getWithdrawalsWithId = async (id: string) => {
  try {
    return await http.authGet<GetWithdrawalsWithIdResponse>(`withdrawals/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const patchWithdrawalsIdApprove = async (id: string) => {
  try {
    return await http.authPatch(`withdrawals/${id}/approve`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const deleteWithdrawalsId = async (id: string) => {
  try {
    return await http.authDelete(`withdrawals/${id}`);
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 409 && error.response.data.code === 0)
      throw t('error.withdrawal.cancelError');
    throw defaultErrorMessage(error);
  }
};

const patchWithdrawalsIdResend = async (id: string) => {
  try {
    return await http.authPatch(`withdrawals/${id}/resend`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  postWithdrawalPreview,
  patchWithdrawalVerify,
  postWithdrawal,
  getWithdrawals,
  getWithdrawalsWithId,
  patchWithdrawalsIdApprove,
  deleteWithdrawalsId,
  patchWithdrawalsIdResend,
};
