import { forwardRef, useMemo } from 'react';
import Input, { Props as InputProps } from './Input';

export type Props = InputProps & {
  decimal?: number;
  allowMinus?: boolean;
};

const NumberInput = forwardRef<HTMLInputElement, Props>(
  ({ decimal = -1, allowMinus = false, ...props }, ref) => {
    const regex = useMemo(() => {
      if (decimal > 0)
        return new RegExp(`^${allowMinus ? '-?' : ''}[0-9]*\\.?[0-9]{0,${decimal}}$`);
      else if (decimal === 0) return new RegExp(`^${allowMinus ? '-?' : ''}[0-9]*$`);
      else return new RegExp(`^${allowMinus ? '-?' : ''}[0-9]*\\.?[0-9]*$`);
    }, [decimal]);

    return <Input regex={regex} ref={ref} {...props} />;
  },
);

NumberInput.displayName = 'NumberInput';

export default NumberInput;
