import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Crypto, Fiat } from 'src/model/Coin';

export type CoinState = {
  crypto: Crypto[];
  fiat: Fiat[];
};

const initialState: CoinState = {
  crypto: [],
  fiat: [],
};

export const coinSlice = createSlice({
  name: 'coin',
  initialState,
  reducers: {
    setCoin: (state: CoinState, action: PayloadAction<CoinState>) => {
      state.crypto = action.payload.crypto;
      state.fiat = action.payload.fiat;
    },
  },
});

export const { setCoin } = coinSlice.actions;

export default coinSlice.reducer;
