import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import { Page } from 'src/constant/Page';
import { TwoStepVerificationForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { verifyOtp } from 'src/service/authService';

const Step3 = () => {
  const { t } = useTranslation();
  const methods = useForm<TwoStepVerificationForm>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (data: TwoStepVerificationForm) => {
    verifyOtp('gauth', data.code)
      .then(() => {
        navigate(Page.Account);
        dispatch(openSnackbar({ message: t('enableTfa.desc.2FAEnabled'), severity: 'notify' }));
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <div>
      <div className="mt-[10px] text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('enableTfa.heading')}
      </div>
      <Form
        methods={methods}
        onSubmit={onSubmit}
        className="mt-[30px] box-border rounded-[12px] bg-white p-[30px] dark:bg-black-800 md:w-[50%]"
      >
        <FormInput
          name="code"
          label={t('enableTfa.desc.certificationCode')}
          required
          asterisked
          autoFocus
        />
        <div className="mt-[45px] text-right">
          <Button type="submit">{t('enableTfa.act.enable')}</Button>
        </div>
      </Form>
    </div>
  );
};

export default Step3;
