import classNames from 'classnames';
import { format } from 'date-fns';
import { useRef, useState } from 'react';
import Calendar from './Calendar';

type Props = {
  value?: Date;
  onChange?: (v: Date) => void;
  className?: string;
};

const DatePicker = ({ value = new Date(), onChange, className }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const onDateClick = (v: Date) => {
    setOpen(false);
    onChange && onChange(v);
  };

  return (
    <>
      <div
        className={classNames(
          'box-border h-[30px] w-[103px] cursor-pointer rounded-[4px] border-[1px] border-solid border-light-500 px-[8px] py-[3px] text-[16px] text-black-900 dark:border-dark-500 dark:text-white',
          { 'border-[1px] border-solid text-turquoise dark:text-aqua': open },
          className,
        )}
        ref={ref}
        onClick={() => setOpen(true)}
      >
        {format(value, 'yyyy/MM/dd')}
      </div>
      <Calendar
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        value={value}
        onChange={onDateClick}
      />
    </>
  );
};

export default DatePicker;
