import { ChangeEventHandler, forwardRef, ReactNode } from 'react';
import RadioContext from 'src/context/RadioContext';

/**
 * usage example:
 *
 * <RadioGroup name="sex">
 *   <Radio value="m" label="Male" defaultChecked/>
 *   <Radio value="f" label="Female" />
 *   <Radio value="n" label="Non-Binary" disabled />
 * </RadioGroup>
 */

type Props = {
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  children: ReactNode;
  className?: string;
};

const RadioGroup = forwardRef<HTMLInputElement, Props>(
  ({ name, onChange, children, className }, ref) => (
    <RadioContext.Provider value={{ name, onChange, ref }}>
      <div className={className}>{children}</div>
    </RadioContext.Provider>
  ),
);

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
