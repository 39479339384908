import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MenuLanguage from 'src/component/MenuLanguage';
import { Ui } from 'src/constant/Env';
import { Page } from 'src/constant/Page';
import IcArrowDown from 'src/image/ic-arrow-down.svg';
import Logo from 'src/image/ic-logo.svg';

const PublicBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="box-border flex h-[70px] flex-row justify-between p-[15px]">
      <img className="cursor-pointer" src={Logo} onClick={() => navigate(Page.Landing)} />
      {Ui !== 'admin' && (
        <div
          className="flex cursor-pointer flex-row items-center"
          onClick={() => setOpen(true)}
          ref={ref}
        >
          {t('language')}
          <img src={IcArrowDown} />
        </div>
      )}
      <MenuLanguage open={open} anchorEl={ref.current} onClose={() => setOpen(false)} />
    </div>
  );
};

export default PublicBar;
