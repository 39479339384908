import classNames from 'classnames';
import { ChangeEvent, forwardRef, InputHTMLAttributes, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  hint?: string;
  error?: boolean | string;
  asterisked?: boolean;
  regex?: RegExp;
};

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      hint,
      error,
      asterisked = false,
      className,
      disabled,
      autoComplete = 'off',
      onChange,
      regex,
      ...props
    },
    ref,
  ) => {
    const [value, setValue] = useState<string>();
    const onInput = (v: ChangeEvent<HTMLInputElement>) => {
      if (regex !== undefined && regex.test(v.target.value) === false) return;
      setValue(v.target.value);
      onChange && onChange(v);
    };

    return (
      <div>
        {label && (
          <div
            className={classNames('text-[14px] text-dark-500 dark:text-grey-300', {
              'text-dark-500/30 dark:text-grey-500/30': disabled,
            })}
          >
            {label}
            {asterisked && <span className="text-crimson dark:text-red">*</span>}
          </div>
        )}
        <input
          className={twMerge(
            `box-border h-[40px] w-full border-0 
            border-b border-solid border-b-light-500 
            bg-transparent px-0 py-[8px] font-[inherit] text-[1em] text-black-900
            placeholder:text-grey-500 focus:border-b-turquoise focus:outline-none dark:border-b-grey-500 dark:text-white 
            dark:placeholder:text-grey-300/70 dark:focus:border-b-aqua`,
            classNames({
              'border-b-crimson dark:border-b-red': !!error,
              'border-b-dark-500/30 placeholder:text-dark-500/30 dark:border-b-grey-500/30 dark:placeholder:text-grey-500/30':
                disabled,
            }),
            className,
          )}
          disabled={disabled}
          autoComplete={autoComplete}
          ref={ref}
          value={value}
          onChange={onInput}
          {...props}
        />
        {typeof error === 'string' && (
          <div className="mt-[5px] text-[12px] text-crimson dark:text-red">{error}</div>
        )}
        {hint && (
          <div className="mt-[5px] whitespace-pre-wrap text-[12px] text-grey-700 dark:text-grey-300/80">
            {hint}
          </div>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
