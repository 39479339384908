import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import Modal from 'src/component/Modal';
import { Kid } from 'src/model/AccountUser';
import { CommissionForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { editCommission } from 'src/service/agencyService';

type Props = {
  open: boolean;
  handleClose: () => void;
  kid?: Kid;
  doRefresh: () => void;
};

const CommissionModal = ({ open, handleClose, kid, doRefresh }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const methods = useForm<CommissionForm>();

  const onSubmit = (data: CommissionForm) => {
    if (!kid) return;
    handleClose();
    editCommission(kid.id, data)
      .then(() => {
        doRefresh();
        methods.reset();
      })
      .catch((e) => dispatch(openSnackbar(e.message)));
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Form methods={methods} onSubmit={onSubmit}>
        <div className="text-[32px]">{t('agency.desc.modalHead')}</div>
        <div className="mt-[10px]">{kid?.email}</div>
        <div className="my-[25px] text-[14px] leading-[1.5]">
          {t('agency.desc.currentValue')}
          {kid?.commissionDelta}
        </div>
        <FormNumberInput name="commission" required />
        <div className="mt-[65px] flex justify-end gap-5">
          <Button appearance="outlined" type="button" onClick={handleClose}>
            {t('act.cancel')}
          </Button>
          <Button type="submit">{t('act.submit')}</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CommissionModal;
