import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Pagination from 'src/component/Pagination';
import DateFilter from 'src/component/RoundDateFilter';
import RoundSelect from 'src/component/RoundSelect';
import SelectOption from 'src/component/SelectOption';
import SortingSelect from 'src/component/SortingSelect';
import { MediaQuery } from 'src/constant/Media';
import { ThemeContext } from 'src/context/ThemeContext';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import { Fill } from 'src/model/Order';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getFills } from 'src/service/orderService';
import OrderAllCard from './component/OrderAllCard';
import OrderAllTable from './component/OrderAllTable';

const OrderAll = () => {
  const { t, ready } = useTranslation();
  const dispatch = useDispatch();
  const { orderAsk: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const { offset, limit, setCount, paginationProps } = usePagination({ defaultPage: filter.page });
  const { begin, end, dateFilterProps } = useDateFilter({
    defaultFrom: filter.begin,
    defaultTo: filter.end,
  });
  const [filterStatus, setFilterStatus] = useState<string>(filter.status ?? 'all');
  const [filterSorting, setFilterSorting] = useState<string>(filter.sorting ?? 'default');
  const [fills, setFills] = useState<Fill[]>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const { IcEmpty } = useContext(ThemeContext).image;

  useEffect(() => {
    getFills(filterStatus, filterSorting, { begin, end, offset, limit })
      .then((res) => {
        setFills(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [refresh, filterStatus, filterSorting, begin, end, offset, limit]);

  const onStatusFilterChange = (value: string) => {
    paginationProps.setPage(1);
    setFilterStatus(value);
  };

  const onSortingFilterChange = (value: string) => {
    paginationProps.setPage(1);
    setFilterSorting(value);
  };

  if (!ready) return <div />;

  return (
    <div>
      <div className="mb-[10px]">
        <BackButton />
      </div>
      <div className="mt-[10px] text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('allOrders.heading')}
      </div>

      <div className="flex flex-row justify-end">
        <div className="flex flex-row justify-end gap-[12px]">
          <Button appearance="outlined" onClick={() => setRefresh(!refresh)}>
            {t('allOrders.act.refresh')}
          </Button>
        </div>
      </div>
      {fills && (
        <>
          <div className="mt-[15px] rounded-[4px] bg-white px-[15px] py-[25px] text-[14px] dark:bg-black-800 md:px-[40px] md:py-[25px]">
            <div className="relative xs:flex xs:flex-wrap xs:justify-between xs:gap-x-[25px]">
              <div className="flex flex-col xs:flex-row xs:gap-[15px]">
                <DateFilter onChange={() => paginationProps.setPage(1)} {...dateFilterProps} />
                <div className="mb-[15px] w-fit">
                  <RoundSelect
                    label={t('allOrders.desc.status')}
                    defaultValue={filter.status ?? 'all'}
                    onChange={onStatusFilterChange}
                  >
                    <SelectOption value="all">{t('allOrders.desc.statusOption.all')}</SelectOption>
                    <SelectOption value="executed">
                      {t('allOrders.desc.statusOption.executed')}
                    </SelectOption>
                    <SelectOption value="sent">
                      {t('allOrders.desc.statusOption.sent')}
                    </SelectOption>
                    <SelectOption value="received">
                      {t('allOrders.desc.statusOption.received')}
                    </SelectOption>
                    <SelectOption value="canceled">
                      {t('allOrders.desc.statusOption.canceled')}
                    </SelectOption>
                  </RoundSelect>
                </div>
              </div>
              <div className="absolute bottom-0 right-0 mb-[15px] xs:static">
                <SortingSelect
                  defaultValue={filter.sorting ?? 'default'}
                  onChange={onSortingFilterChange}
                >
                  <SelectOption value="default">
                    {t('allOrders.desc.sortingOption.default')}
                  </SelectOption>
                  <SelectOption value="-price">
                    {t('allOrders.desc.sortingOption.priceDesc')}
                  </SelectOption>
                  <SelectOption value="price">
                    {t('allOrders.desc.sortingOption.priceAsce')}
                  </SelectOption>
                  <SelectOption value="-amount">
                    {t('allOrders.desc.sortingOption.amountDesc')}
                  </SelectOption>
                  <SelectOption value="amount">
                    {t('allOrders.desc.sortingOption.amountAsce')}
                  </SelectOption>
                  <SelectOption value="-total">
                    {t('allOrders.desc.sortingOption.totalDesc')}
                  </SelectOption>
                  <SelectOption value="total">
                    {t('allOrders.desc.sortingOption.totalAsce')}
                  </SelectOption>
                </SortingSelect>
              </div>
            </div>
            <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
            {fills.length === 0 && (
              <div className="text-center">
                <img src={IcEmpty} />
              </div>
            )}
            {fills.length > 0 &&
              (isBiggerThanMd ? <OrderAllTable data={fills} /> : <OrderAllCard data={fills} />)}
          </div>
          <div className="mt-[15px] flex flex-row-reverse">
            <Pagination {...paginationProps} />
          </div>
        </>
      )}
    </div>
  );
};

export default OrderAll;
