import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  appearance?: 'primary' | 'secondary' | 'outlined' | 'text';
  size?: 'large' | 'medium' | 'small';
};

const Button = ({
  appearance = 'primary',
  size = 'medium',
  className,
  children,
  disabled,
  ...props
}: Props) => (
  <button
    className={twMerge(
      classNames(
        'cursor-pointer rounded-[28px] font-bold disabled:cursor-default',
        {
          'min-h-[50px] min-w-[131px] px-[40px] py-[13px] text-[16px]': size === 'large',
          'min-h-[40px] min-w-[104px] px-[30px] py-[9px] text-[14px]': size === 'medium',
          'min-h-[30px] min-w-[81px] px-[20px] py-[6px] text-[13px]': size === 'small',
          'bg-gradient-to-r from-skyblue to-lime text-black-900 hover:from-lightblue hover:to-lightgreen active:from-seablue active:to-seagreen disabled:from-skyblue/30 disabled:to-lime/30 disabled:text-black-900/60':
            appearance === 'primary',
          'bg-light-300 text-black-900 hover:bg-light-200 active:bg-light-400 disabled:bg-light-300 disabled:text-dark-500/30 dark:bg-darkgrey dark:text-white hover:dark:bg-dark-500 active:dark:bg-black-600 disabled:dark:bg-dark-700/30 disabled:dark:text-white/40':
            appearance === 'secondary',
          'border-[1px] border-solid border-light-300 bg-transparent text-black-900 hover:bg-light-300 active:bg-light-400 disabled:border-light-300 disabled:bg-transparent disabled:text-dark-500/30 dark:border-dark-500 dark:text-white hover:dark:bg-dark-500 active:dark:bg-black-600 disabled:dark:border-dark-500/30 disabled:dark:bg-transparent disabled:dark:text-white/40':
            appearance === 'outlined',
          'border-none': appearance !== 'outlined',
          '!min-h-fit !min-w-fit bg-transparent p-0 text-turquoise hover:underline disabled:text-turquoise/30 disabled:no-underline dark:text-aqua active:dark:text-aqua/60 disabled:dark:text-aqua/30':
            appearance === 'text',
        },
        className,
      ),
    )}
    disabled={disabled}
    {...props}
  >
    {children}
  </button>
);

export default Button;
