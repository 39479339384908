import { IconButton } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Checkbox from 'src/component/Checkbox';
import { ThemeContext } from 'src/context/ThemeContext';
import { BankAccount } from 'src/model/Bank';

export type Props = {
  data: BankAccount[];
  onClickDelete: (id: string) => void;
  isBatchClick: boolean;
  selected: BankAccount[];
  selectAll: boolean;
  onSelectAll: () => void;
  onSelectOne: (v: BankAccount) => void;
};

const AccountTable = ({
  data,
  onClickDelete,
  isBatchClick,
  selected,
  selectAll,
  onSelectAll,
  onSelectOne,
}: Props) => {
  const { t } = useTranslation();
  const { IcOpenInNew } = useContext(ThemeContext).image;
  const elementsMapFunction = (v: BankAccount) => {
    const handleOpenInNew = () => {
      if (!v.imageUrl) return;
      const tab = window.open(v.imageUrl, '_blank');
      if (!tab) return;
      tab.focus();
    };

    return (
      <div key={v.id}>
        <div className="flex items-center text-[14px]">
          {v.paymentMethodType === 'bank' && (
            <>
              <div className="box-border w-2/12 px-3 pb-5 pt-4">{v.name}</div>
              <div className="box-border w-2/12 px-3 pb-5 pt-4">{v.bankName}</div>
              <div className="box-border w-3/12 px-3 pb-5 pt-4">{v.account}</div>
              <div className="box-border w-1/12 px-3 pb-5 pt-4">{v.province}</div>
              <div className="box-border w-1/12 px-3 pb-5 pt-4">{v.city}</div>
              <div className="box-border w-2/12 px-3 pb-5 pt-4">{v.bankBranchName}</div>
            </>
          )}
          {v.paymentMethodType === 'alipay' && (
            <>
              <div className="box-border w-2/12 px-3 pb-5 pt-4">{v.name}</div>
              <div className="box-border w-3/12 px-3 pb-5 pt-4">{v.account}</div>
              <div className="box-border w-6/12 px-3 pb-5 pt-4">
                {v.imageUrl ? (
                  <div className={'flex flex-row items-center'}>
                    <div> {t('bankAccount.desc.alipayQRCodeUploaded')}</div>
                    <div>
                      <IconButton
                        onClick={handleOpenInNew}
                        sx={{ padding: 0, margin: '2px 4px 0' }}
                      >
                        <img className="w-[24px]" src={IcOpenInNew} alt="open-in-new" />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <div>{t('bankAccount.desc.alipayQRCodeUnuploaded')}</div>
                )}
              </div>
            </>
          )}
          <div className="box-border flex w-1/12 justify-end px-3 pb-5 pt-4">
            {isBatchClick ? (
              <Checkbox
                checked={selected.map((o) => o.id).includes(v.id)}
                onChange={() => onSelectOne(v)}
              />
            ) : (
              <Button
                appearance="text"
                className="font-bold text-crimson active:opacity-30 disabled:opacity-30 dark:text-red"
                onClick={() => onClickDelete(v.id)}
              >
                {t('account.act.delete')}
              </Button>
            )}
          </div>
        </div>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      </div>
    );
  };

  return (
    <div className="relative">
      <div className="absolute top-[-39px] font-bold text-black-900 dark:text-white">
        {t('bankAccount.desc.paymentType.bank')}
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <div className="flex">
        <div className="box-border w-2/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.accountName')}
        </div>
        <div className="box-border w-2/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.bankInformation')}
        </div>
        <div className="box-border w-3/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.accountId')}
        </div>
        <div className="box-border w-1/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.bankProvince')}
        </div>
        <div className="box-border w-1/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.bankCity')}
        </div>
        <div className="box-border w-2/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.bankBranchName')}
        </div>
        <div className="box-border flex w-1/12 justify-end break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {isBatchClick && <Checkbox checked={selectAll} onChange={onSelectAll} />}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      {data.filter((v) => v.paymentMethodType === 'bank').map(elementsMapFunction)}
      <div className="mb-[15px] mt-[30px] font-bold text-black-900 dark:text-white">
        {t('bankAccount.desc.paymentType.alipay')}
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <div className="flex">
        <div className="box-border w-2/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.alipayAccountName')}
        </div>
        <div className="box-border w-3/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.alipayAccount')}
        </div>
        <div className="box-border w-7/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.alipayQRCode')}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      {data.filter((v) => v.paymentMethodType === 'alipay').map(elementsMapFunction)}
    </div>
  );
};

export default AccountTable;
