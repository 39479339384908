import meEndpoint from 'src/api/meEndpoint';
import { setAccountInfo } from 'src/redux/authSlice';
import { dispatch, getState } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';

export const updateLocale = async (locale: string) => {
  if (!getState().auth.isLogin) return;

  try {
    dispatch(startWaiting());
    await meEndpoint.patchMe({
      locale: locale.toLowerCase(),
    });
    const ownInfoRes = await meEndpoint.getOwnInfo();
    dispatch(setAccountInfo(ownInfoRes.data));
  } finally {
    dispatch(finishWaiting());
  }
};
