import authenticationEndpoint from 'src/api/authenticationEndpoint';
import meEndpoint from 'src/api/meEndpoint';
import {
  PostAuthEmailResendRequest,
  PostAuthEmailVerifyRequest,
  PostAuthPasswordResetRequest,
  PostAuthPasswordSendRequest,
  PostAuthSigninRequest,
  PostAuthSignoutRequest,
  PostAuthSignupRequest,
} from 'src/model/Api';
import { resetAuth, setAccountInfo, setIsLogin } from 'src/redux/authSlice';
import { resetRebate } from 'src/redux/rebateSlice';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';

export const signup = async (data: PostAuthSignupRequest) => {
  try {
    dispatch(startWaiting());
    await authenticationEndpoint.signup(data);
  } finally {
    dispatch(finishWaiting());
  }
};

const emailVerifyForAsk = async (data: PostAuthEmailVerifyRequest) => {
  await authenticationEndpoint.emailVerify(data);
  const signinRes = await authenticationEndpoint.signin({
    email: data.email,
    password: data.password,
  });

  localStorage.setItem('token', signinRes.data.token);
  dispatch(setIsLogin());
};

const emailVerifyForBid = async (data: PostAuthEmailVerifyRequest) => {
  await authenticationEndpoint.emailVerify(data);
};

export const emailVerify = async (
  ui: 'seller' | 'buyer' | 'admin',
  data: PostAuthEmailVerifyRequest,
) => {
  try {
    dispatch(startWaiting());
    if (ui === 'seller') await emailVerifyForAsk(data);
    else await emailVerifyForBid(data);
  } finally {
    dispatch(finishWaiting());
  }
};

export const emailResend = async (data: PostAuthEmailResendRequest) => {
  await authenticationEndpoint.emailResend(data);
};

export const signin = async (data: PostAuthSigninRequest) => {
  try {
    dispatch(startWaiting());
    const res = await authenticationEndpoint.signin(data);

    localStorage.setItem('token', res.data.token);
    dispatch(setIsLogin());

    return data.otp !== undefined ? 'has-otp' : 'no-otp';
  } finally {
    dispatch(finishWaiting());
  }
};

export const signout = async (data: PostAuthSignoutRequest) => {
  try {
    dispatch(startWaiting());
    await authenticationEndpoint.signout(data);
    localStorage.removeItem('token');
    dispatch(resetRebate());
    dispatch(resetAuth());
  } finally {
    dispatch(finishWaiting());
  }
};

export const activateOtp = async (method: string) => {
  try {
    dispatch(startWaiting());
    const res = await authenticationEndpoint.activateOtp(method);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const verifyOtp = async (method: string, code: string) => {
  try {
    dispatch(startWaiting());
    await authenticationEndpoint.verifyOtp(method, code);

    const res = await meEndpoint.getOwnInfo();
    dispatch(setAccountInfo(res.data));
  } finally {
    dispatch(finishWaiting());
  }
};

export const deactivateOtp = async (method: string, code: string) => {
  try {
    dispatch(startWaiting());
    await authenticationEndpoint.deactivateOtp(method, code);
    const res = await meEndpoint.getOwnInfo();

    dispatch(setAccountInfo(res.data));
  } finally {
    dispatch(finishWaiting());
  }
};

export const sendPasswordResetVerification = async (data: PostAuthPasswordSendRequest) => {
  try {
    dispatch(startWaiting());
    await authenticationEndpoint.sendPasswordResetVerification(data);
  } finally {
    dispatch(finishWaiting());
  }
};

export const resetPassword = async (data: PostAuthPasswordResetRequest) => {
  try {
    dispatch(startWaiting());
    await authenticationEndpoint.resetPassword(data);
  } finally {
    dispatch(finishWaiting());
  }
};
