import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Modal from 'src/component/Modal';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmationBatchModal = ({ isOpen, onClose, onConfirm }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} handleClose={onClose}>
      <>
        <div>{t('myTrade.desc.batchEditAlertMessage')}</div>
        <div className="mt-[40px] flex flex-row justify-end gap-[20px]">
          <Button appearance="outlined" onClick={onClose}>
            {t('myTrade.act.cancel')}
          </Button>
          <Button onClick={onConfirm}>{t('myTrade.act.confirm')}</Button>
        </div>
      </>
    </Modal>
  );
};

export default ConfirmationBatchModal;
