import {
  GetBalancesIdResponse,
  GetBalancesListParams,
  GetBalancesListResponse,
  GetSummedBalanceRequest,
  GetSummedBalanceResponse,
  GetSystemBalanceResponse,
  PatchBalanceParams,
  PatchBalanceResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getBalanceList = async (
  params: GetBalancesListParams,
  headers?: { [key: string]: string },
) => {
  try {
    return await http.authGet<GetBalancesListResponse>('balances', { params, headers });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getBalance = async (id: string) => {
  try {
    return await http.authGet<GetBalancesIdResponse>(`balances/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getSummedBalance = async (params?: GetSummedBalanceRequest) => {
  try {
    return await http.authGet<GetSummedBalanceResponse>('summed-balances', { params });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const patchBalance = async (data: PatchBalanceParams) => {
  try {
    return await http.authPatch<PatchBalanceResponse, PatchBalanceParams>(`balances`, { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getSystemBalance = async () => {
  try {
    return await http.authGet<GetSystemBalanceResponse>('system-balances');
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  patchBalance,
  getBalanceList,
  getBalance,
  getSummedBalance,
  getSystemBalance,
};
