import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { Fill } from 'src/model/Order';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data?: Fill[];
};
const OrderAllCard = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: Fill) => (
    <div key={order.id}>
      <div className="flex flex-col gap-[4px] pt-[15px]">
        <div className="flex flex-row gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('allOrders.desc.orderId')}
          </div>
          <div className="text-[14px]">{order.id}</div>
        </div>
        <div className="flex flex-row gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('allOrders.desc.date')}
          </div>
          <div className="text-[14px]">
            {format(Date.parse(order.createdAt), 'yyyy/MM/dd HH:mm:ss')}
          </div>
        </div>
        <div className="flex flex-row gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('allOrders.desc.amount')}
          </div>
          <div className="text-[14px]">
            {`${bnFormat(order.amount)} ${order.base.toUpperCase()}`}
          </div>
        </div>
        <div className="flex flex-row gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('allOrders.desc.unitPrice')}
          </div>
          <div className="text-[14px]">
            {`${bnFormat(order.price)} ${order.quote.toUpperCase()}`}
          </div>
        </div>
        <div className="flex flex-row gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('allOrders.desc.total')}
          </div>
          <div className="text-[14px]">
            {`${bnFormat(order.total)} ${order.quote.toUpperCase()}`}
          </div>
        </div>
        <div className="flex flex-row justify-between gap-[15px]">
          <div className="flex flex-row gap-[15px]">
            <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
              {t('allOrders.desc.status')}
            </div>
            <div className="text-[14px]">
              <div className="text-green dark:text-lime">
                {t(`allOrders.desc.statusDisplay.${order.status}`)}
              </div>
            </div>
          </div>
          <Button appearance="text" onClick={() => navigate(Page.Order + '/' + order.id)}>
            {t('allOrders.act.detail')}
          </Button>
        </div>
      </div>
      <div className="mt-[13px] h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data?.map(elementMapFunction)}</div>;
};

export default OrderAllCard;
