import { Popover as MuiPopover, PopoverProps } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = PopoverProps & {
  open: boolean;
  onClose: () => void;
  anchorEl: Element | null;
  children: ReactNode;
  className?: string;
  cssProperties?: CSSProperties;
};

const Popover = ({
  open,
  anchorEl,
  onClose,
  children,
  className,
  cssProperties,
  ...props
}: Props) => (
  <MuiPopover
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    PaperProps={{
      style: cssProperties,
      className: twMerge(
        '!rounded-xl !bg-white !text-dark-700 dark:!bg-black-800 dark:!text-white',
        className,
      ),
    }}
    {...props}
  >
    {children}
  </MuiPopover>
);

export default Popover;
