import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Modal from 'src/component/Modal';
import { Action } from 'src/constant/Order';

type Props = {
  isOpen: boolean;
  action?: Action;
  onClose: (isConfirm: boolean) => void;
};

const ConfirmationModal = ({ isOpen, action, onClose }: Props) => {
  const { t } = useTranslation();

  const messageMap = {
    [Action.Suspend]: t('myTrade.desc.suspendAlertMessage'),
    [Action.Unsuspend]: t('myTrade.desc.unsuspendAlertMessage'),
    [Action.Delete]: t('myTrade.desc.deleteAlertMessage'),
    [Action.Edit]: t('myTrade.desc.editAlertMessage'),
  };

  return (
    <Modal open={isOpen} handleClose={() => onClose(false)}>
      <>
        <div>{messageMap[action ?? Action.Suspend]}</div>
        <div className="mt-[40px] flex flex-row justify-end gap-[20px]">
          <Button appearance="outlined" onClick={() => onClose(false)}>
            {t('myTrade.act.cancel')}
          </Button>
          <Button onClick={() => onClose(true)}>{t('myTrade.act.confirm')}</Button>
        </div>
      </>
    </Modal>
  );
};

export default ConfirmationModal;
