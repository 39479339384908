import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import { Commission } from 'src/model/Commission';
import { openSnackbar } from 'src/redux/uiSlice';
import { getCommissionById } from 'src/service/commssionService';

const CommissionDetail = () => {
  const { t } = useTranslation();
  const { commissionId } = useParams();
  const dispatch = useDispatch();
  const [commission, setCommission] = useState<Commission>();

  useEffect(() => {
    if (commissionId === undefined) return;
    getCommissionById(commissionId)
      .then((res) => setCommission(res))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [commissionId]);

  if (commission === undefined) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] text-[28px] font-[600] sm:mt-[20px] sm:text-[32px]">
        {t('commissionDetail.heading', { id: commissionId })}
      </div>
      <div className="mt-[30px] flex flex-col gap-5 rounded-xl bg-white px-4 py-[25px] dark:bg-black-800 xs:flex-row xs:flex-wrap xs:gap-0 xs:px-[45px] xs:py-10 md:gap-y-[30px]">
        <div className="box-border flex h-fit justify-between gap-[10px] pb-6 xs:w-1/3 xs:flex-col xs:gap-0 xs:px-[5px]">
          <div className="text-grey-700 dark:text-grey-300">
            {t('commissionDetail.desc.recommendedPrice')}
          </div>
          <div>
            {commission.resultPrice} {commission.quote.toUpperCase()}
          </div>
        </div>
        <div className="box-border flex h-fit justify-between gap-[10px] pb-6 xs:w-1/3 xs:flex-col xs:gap-0 xs:px-[5px]">
          <div className="text-grey-700 dark:text-grey-300">
            {t('commissionDetail.desc.commission')}
          </div>
          <div>
            {commission.amount} {commission.coin.toUpperCase()}
          </div>
        </div>
        <div className="box-border flex h-fit justify-between gap-[10px] pb-6 xs:w-1/3 xs:flex-col xs:gap-0 xs:px-[5px]">
          <div className="text-grey-700 dark:text-grey-300">{t('commissionDetail.desc.time')}</div>
          <div className="text-right xs:text-left">
            {format(new Date(commission.createdAt), 'yyyy-MM-dd HH:mm:ss')}
          </div>
        </div>
        <div className="box-border flex h-fit justify-between gap-[10px] pb-6 xs:w-1/3 xs:flex-col xs:gap-0 xs:px-[5px]">
          <div className="text-grey-700 dark:text-grey-300">
            {t('commissionDetail.desc.amount')}
          </div>
          <div>
            {commission.askAmount} {commission.base.toUpperCase()}
          </div>
        </div>
        <div className="box-border flex h-fit justify-between gap-[10px] pb-6 xs:w-1/3 xs:flex-col xs:gap-0 xs:px-[5px]">
          <div className="text-grey-700 dark:text-grey-300">
            {t('commissionDetail.desc.unitPrice')}
          </div>
          <div>
            {commission.askPrice} {commission.quote.toUpperCase()}
          </div>
        </div>
        <div className="box-border flex h-fit justify-between gap-[10px] pb-6 xs:w-1/3 xs:flex-col xs:gap-0 xs:px-[5px]">
          <div className="text-grey-700 dark:text-grey-300">{t('commissionDetail.desc.total')}</div>
          <div>
            {commission.total} {commission.quote.toUpperCase()}
          </div>
        </div>
        <div className="box-border flex h-fit justify-between gap-[10px] pb-6 xs:w-1/3 xs:flex-col xs:gap-0 xs:px-[5px]">
          <div className="text-grey-700 dark:text-grey-300">
            {t('commissionDetail.desc.invitee')}
          </div>
          <div className="break-all text-right xs:text-left">{commission.kidEmail}</div>
        </div>
        <div className="box-border flex h-fit justify-between gap-[10px] pb-6 xs:w-1/3 xs:flex-col xs:gap-0 xs:px-[5px]">
          <div className="text-grey-700 dark:text-grey-300">
            {t('commissionDetail.desc.fillId')}
          </div>
          <div className="break-all text-right xs:text-left">{commission.fillId}</div>
        </div>
      </div>
    </div>
  );
};

export default CommissionDetail;
