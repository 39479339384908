import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import { ThemeContext } from 'src/context/ThemeContext';
import Step1 from './component/Step1';
import Step2 from './component/Step2';
import Step3 from './component/Step3';

const EnableTfa = () => {
  const navigate = useNavigate();
  const { IcClosePrimary } = useContext(ThemeContext).image;
  const { t } = useTranslation();
  const [getStepPageIndex, setStepPageIndex] = useState<number>(0);

  return (
    <div>
      <Button appearance="text" className="flex flex-row items-center" onClick={() => navigate(-1)}>
        <img src={IcClosePrimary} />
        {t('enableTfa.act.cancel')}
      </Button>
      {getStepPageIndex === 0 && <Step1 />}
      {getStepPageIndex === 1 && <Step2 />}
      {getStepPageIndex === 2 && <Step3 />}
      <div className="my-[20px] h-[1px] bg-light-200 dark:bg-dark-700" />
      <div className="flex flex-row items-center justify-between">
        <div>
          {getStepPageIndex > 0 && (
            <BackButton
              label={t('enableTfa.act.prev')}
              onClick={() => setStepPageIndex(getStepPageIndex - 1)}
            />
          )}
        </div>
        <div>
          {getStepPageIndex < 2 && (
            <Button onClick={() => setStepPageIndex(getStepPageIndex + 1)}>
              {t('enableTfa.act.next')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EnableTfa;
