import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import { DepositRecord } from 'src/model/Deposit';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<DepositRecord>;
  navigateCallback: (record: DepositRecord) => void;
};

const DepositTable = ({ data, navigateCallback }: Props) => {
  const { t } = useTranslation();

  const onDetailClick = (record: DepositRecord) => () => {
    navigateCallback(record);
  };

  const elementMapFunction = (
    record: DepositRecord,
    index: number,
    array: Array<DepositRecord>,
  ) => (
    <div key={record.id} className="flex flex-col">
      <div className="flex flex-row">
        <div className={classNames('box-border break-words pr-[10px]', 'w-[10%]')}>
          {record.coin.toUpperCase()}
        </div>
        <div className={classNames('box-border break-words pr-[10px]', 'w-[20%]')}>
          {bnFormat(record.amount)}
        </div>
        <div
          className={classNames(
            'box-border break-words pr-[10px]',
            'text-grey-500 dark:text-grey-700',
            'w-[20%]',
          )}
        >
          {format(Date.parse(record.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </div>
        <div className={classNames('box-border break-words pr-[10px]', 'text-[12px]', 'w-[40%]')}>
          {record.address}
        </div>
        <div
          className={classNames(
            'box-border flex items-center justify-end break-words pr-[10px]',
            'text-center',
            'w-[10%]',
          )}
        >
          <Button appearance="text" onClick={onDetailClick(record)}>
            {t('record.act.detail')}
          </Button>
        </div>
      </div>
      <div
        className={classNames('my-[15px] h-[1px] bg-light-200 dark:bg-dark-700', {
          hidden: index >= array.length - 1,
        })}
      />
    </div>
  );

  return (
    <div className="mt-[15px]">
      <div className="flex flex-row text-[14px] font-bold text-grey-500 dark:text-grey-700">
        <div className="w-[10%]">{t('record.desc.coin')}</div>
        <div className="w-[20%]">{t('record.desc.amount')}</div>
        <div className="w-[20%]">{t('record.desc.date')}</div>
        <div className="w-[40%]">{t('record.desc.address')}</div>
      </div>
      <div className="my-[15px] h-[1px] bg-light-200 dark:bg-dark-700" />
      {data.map(elementMapFunction)}
    </div>
  );
};

export default DepositTable;
