import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { ThemeContext } from 'src/context/ThemeContext';

type Props = {
  label?: string;
  navigateToPath?: string;
  onClick?: () => void;
};

const BackButton = ({ label, navigateToPath, onClick, ...props }: Props) => {
  const navigate = useNavigate();
  const { IcBack } = useContext(ThemeContext).image;
  const { t } = useTranslation();

  const onButtonClick = () => {
    if (onClick) {
      onClick();

      return;
    }
    if (navigateToPath) {
      navigate(navigateToPath);

      return;
    }
    navigate(-1);
  };

  return (
    <div>
      <Button appearance="text" className="flex items-center" onClick={onButtonClick} {...props}>
        <img src={IcBack} />
        {label || t('act.back')}
      </Button>
    </div>
  );
};

export default BackButton;
