import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BankAccount } from 'src/model/Bank';
import { OneTradingForm } from 'src/model/Form';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  action?: 'trading' | 'edit' | 'unsuspend' | 'suspend' | 'delete';
  quote: string;
  base: string;
  batchArray: (OneTradingForm & {
    bankAccount?: BankAccount;
    status: string | null;
    message: string | null;
  })[];
};

const Table = ({ action, quote, base, batchArray }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="mx-[15px] xs:mx-[40px]">
      <div className="flex font-bold text-grey-700 dark:text-grey-300">
        <div className="box-border w-1/6 px-3 py-4">{t('trading.desc.unitPrice')}</div>
        <div className="box-border w-1/6 px-3 py-4">{t('trading.desc.amount')}</div>
        <div className="box-border w-1/6 px-3 py-4">{t('tradingConfirm.desc.paymentLimit')}</div>
        <div className="box-border w-2/6 px-3 py-4">{t('trading.desc.receivePayment')}</div>
        <div className="w-1/6" />
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      {batchArray?.map((v, i) => (
        <div key={i}>
          <div className="flex">
            <div className="box-border w-1/6 px-3 py-4">
              {bnFormat(v.price)} {quote.toUpperCase()}
            </div>
            <div className="box-border w-1/6 px-3 py-4">
              {bnFormat(v.amount)} {base.toUpperCase()}
            </div>
            <div className="box-border w-1/6 px-3 py-4">{`${bnFormat(v.minTotal)} ~ ${bnFormat(
              v.maxTotal,
            )} ${quote.toUpperCase()}`}</div>
            {v.bankAccount?.paymentMethodType === 'bank' && (
              <div className="box-border w-2/6 px-3 py-4">{`${v.bankAccount.name} / ${v.bankAccount.bankName} / ${v.bankAccount.account} / ${v.bankAccount.province} / ${v.bankAccount.city} / ${v.bankAccount.bankBranchName}`}</div>
            )}
            {v.bankAccount?.paymentMethodType === 'alipay' && (
              <div className="box-border w-2/6 px-3 py-4">
                {`${v.bankAccount.name} / ${v.bankAccount.account} / ${
                  v.bankAccount.imageUrl
                    ? t('trading.desc.alipayQRCodeUploaded')
                    : t('trading.desc.alipayQRCodeUnuploaded')
                }`}
              </div>
            )}
            <div className="box-border w-1/6 px-3 py-4">
              {v.status && (
                <div>
                  <div
                    className={classNames({
                      'text-green dark:text-lime': v.status === 'success',
                      'text-crimson dark:text-red': v.status === 'fail',
                      'text-khaki dark:text-yellow':
                        v.status === 'pending' || v.status === 'processing',
                    })}
                  >
                    {v.status !== 'success'
                      ? t(`tradingBatch.desc.status.${v.status}`)
                      : t(`tradingBatch.desc.success.${action}`)}
                  </div>
                  {v.message && (
                    <div className="break-all text-[12px] text-crimson dark:text-red">
                      {v.message}
                      {t(`tradingBatch.desc.errorExtension.${action}`)}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="my-[15px] h-[1px] bg-light-200 dark:bg-dark-700" />
        </div>
      ))}
    </div>
  );
};

export default Table;
