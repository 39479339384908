import {
  GetDepositAddressParams,
  GetDepositAddressResponse,
  GetDepositsParams,
  GetDepositsResponse,
  GetDepositsWithIdResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getDeposits = async (params: GetDepositsParams, headers?: { [key: string]: string }) => {
  try {
    return await http.authGet<GetDepositsResponse, GetDepositsParams>('deposits', {
      params,
      headers,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getDepositsWithId = async (id: string) => {
  try {
    return await http.authGet<GetDepositsWithIdResponse>(`deposits/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getAddress = async (params: GetDepositAddressParams) => {
  try {
    return await http.authGet<GetDepositAddressResponse, GetDepositAddressParams>(
      `deposits/address`,
      { params },
    );
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getDeposits,
  getDepositsWithId,
  getAddress,
};
