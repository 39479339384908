import { ChangeEvent, ChangeEventHandler, createContext, ForwardedRef } from 'react';

type ContextType = {
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  ref?: ForwardedRef<HTMLInputElement>;
};
const RadioContext = createContext<ContextType>({
  name: '',
  onChange: (e: ChangeEvent<HTMLInputElement>) => e,
});

export default RadioContext;
