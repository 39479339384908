import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useSelector } from 'react-redux';
import { MediaQuery } from 'src/constant/Media';
import IcUsdt from 'src/image/ic-usdt.svg';
import { RootState } from 'src/redux/store';
import { bn } from 'src/util/bigNumber';
import Statement from './Statement';

const ReportWithoutTabs = () => {
  const { t } = useTranslation();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const { me, kids, members } = useSelector((rootState: RootState) => rootState.rebate);

  return (
    <>
      <div className="mt-[15px] rounded-[4px] bg-white px-[15px] py-[25px] dark:bg-black-700 xs:px-[40px]">
        {members && (
          <>
            <div className="mb-[10px] text-[20px] font-bold">{t('report.desc.memberTotal')}</div>
            {isBiggerThanMd && (
              <>
                <div className="flex items-center">
                  <div className="flex w-[calc(100%/3)] items-center gap-[8px] px-0 py-[16px]">
                    <img src={IcUsdt} />
                    <div className="font-bold text-black-900 dark:text-white">USDT</div>
                  </div>
                  <div className="w-[calc(100%/3)] px-0 py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300/80">
                    {t('report.desc.amount')}
                  </div>
                  <div className="w-[calc(100%/3)] px-0 py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300/80">
                    {t('report.desc.rebate')}
                  </div>
                </div>
                <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
              </>
            )}
            <Statement
              info={{
                email: 'xx',
                userId: 'xx',
                askAmount: members
                  .reduce((sum, current) => sum.plus(bn(current.askAmount)), bn(0))
                  .toFormat(),
                rebateAmount: members
                  .reduce((sum, current) => sum.plus(bn(current.rebateAmount)), bn(0))
                  .toFormat(),
              }}
              showCoin
            />
          </>
        )}
        {me && (
          <>
            <div
              className={classNames('mb-[10px] text-[20px] font-bold', {
                'mt-[40px]': members && members.length > 0,
              })}
            >
              {t('report.desc.me')}
            </div>
            {isBiggerThanMd && (
              <>
                <div className="flex items-center">
                  <div className="flex w-[calc(100%/3)] items-center gap-[8px] px-0 py-[16px]">
                    <img src={IcUsdt} />
                    <div className="font-bold text-black-900 dark:text-white">USDT</div>
                  </div>
                  <div className="w-[calc(100%/3)] px-0 py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300/80">
                    {t('report.desc.amount')}
                  </div>
                  <div className="w-[calc(100%/3)] px-0 py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300/80">
                    {t('report.desc.rebate')}
                  </div>
                </div>
                <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
              </>
            )}
            <Statement info={me} showCoin />
          </>
        )}
        {kids && kids.length > 0 && (
          <>
            <div className="mb-[10px] mt-[40px] text-[20px] font-bold">{t('report.desc.kids')}</div>
            {isBiggerThanMd && (
              <>
                <div className="flex items-center">
                  <div className="flex w-[calc(100%/3)] items-center gap-[8px] px-0 py-[16px]">
                    <img src={IcUsdt} />
                    <div className="font-bold text-black-900 dark:text-white">USDT</div>
                  </div>
                  <div className="w-[calc(100%/3)] px-0 py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300/80">
                    {t('report.desc.amount')}
                  </div>
                  <div className="w-[calc(100%/3)] px-0 py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300/80">
                    {t('report.desc.rebate')}
                  </div>
                </div>
                <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
              </>
            )}
            {kids.map((v, i) => (
              <Statement key={v.userId} info={v} showCoin={i === 0} showEmail />
            ))}
          </>
        )}
        {members && (
          <>
            <div className="mb-[10px] mt-[40px] text-[20px] font-bold">
              {t('report.desc.members')}
            </div>
            {isBiggerThanMd && (
              <>
                <div className="flex items-center">
                  <div className="flex w-[calc(100%/3)] items-center gap-[8px] px-0 py-[16px]">
                    <img src={IcUsdt} />
                    <div className="font-bold text-black-900 dark:text-white">USDT</div>
                  </div>
                  <div className="w-[calc(100%/3)] px-0 py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300/80">
                    {t('report.desc.amount')}
                  </div>
                  <div className="w-[calc(100%/3)] px-0 py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300/80">
                    {t('report.desc.rebate')}
                  </div>
                </div>
                <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
              </>
            )}
            {members.map((v, i) => (
              <Statement key={v.userId} info={v} showCoin={i === 0} showEmail />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default ReportWithoutTabs;
