import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { BankAccount } from 'src/model/Bank';
import { deleteBankAccount } from 'src/service/accountService';
import Card from './component/Card';
import Table from './component/Table';

const AccountBatchConfirm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const state = location.state as BankAccount[] | null;
  const navigate = useNavigate();
  const [batchArray, setBatchArray] = useState<
    (BankAccount & {
      status: string | null;
    })[]
  >([]);
  const [currentIdx, setCurrentIdx] = useState<number>();
  const [complete, setComplete] = useState<boolean>(false);

  useEffect(() => {
    if (state === null) navigate(Page.Account);
    else setBatchArray(state.map((v) => ({ ...v, status: null, message: null })));
  }, [state]);

  useEffect(() => {
    if (!batchArray || currentIdx === undefined || batchArray[currentIdx] === undefined) return;
    const batchItem = batchArray[currentIdx];
    updateTradingArray(currentIdx, 'processing');
    deleteBankAccount(batchItem.id, false)
      .then(() => updateTradingArray(currentIdx, 'success'))
      .catch(() => updateTradingArray(currentIdx, 'fail'))
      .finally(() => {
        setCurrentIdx(currentIdx + 1);
        if (currentIdx === batchArray.length - 1) setComplete(true);
      });
  }, [currentIdx]);

  const updateTradingArray = (i: number, status: string) => {
    if (!batchArray) return;
    const temp = [...batchArray];
    temp[i].status = status;
    setBatchArray(temp);
  };

  const onSubmit = () => {
    if (state === null || !batchArray) return;
    const temp = [...batchArray];
    setBatchArray(temp.map((v) => ({ ...v, status: 'pending' })));
    setCurrentIdx(0);
  };

  const onComplete = () => navigate(Page.Account, { replace: true });

  return (
    <div>
      <div className="text-[27px] font-[600]">{t('account.desc.batchHead')}</div>
      <div className="mt-[30px] rounded-[12px] bg-white px-[15px] pb-10 pt-[25px] dark:bg-black-500 md:px-10">
        {isBiggerThanMd ? <Table batchArray={batchArray} /> : <Card batchArray={batchArray} />}
        <div className="mt-[55px] text-right md:mt-10">
          {currentIdx === undefined && <Button onClick={onSubmit}>{t('act.submit')}</Button>}
          {complete === true && <Button onClick={onComplete}>{t('account.act.complete')}</Button>}
        </div>
      </div>
    </div>
  );
};

export default AccountBatchConfirm;
