import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import { TwoStepVerificationForm } from 'src/model/Form';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (code: string) => void;
};

const ReceiptConfirmModal = ({ open, onClose, onSubmit }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<TwoStepVerificationForm>();

  const handleSubmit = (data: TwoStepVerificationForm) => {
    onClose();
    onSubmit(data.code);
    methods.reset();
  };

  return (
    <Modal open={open} handleClose={onClose}>
      <div>
        <div className="text-[32px] font-bold">{t('order.desc.confirmReceived')}</div>
        <div className="mt-[20px] text-[16px]">{t('order.desc.confirmNotice')}</div>
        <Form methods={methods} onSubmit={handleSubmit} className="mt-[25px]">
          <FormInput label={t('order.desc.certificationCode')} name="code" required />
          <div className="mt-[65px] flex flex-row justify-end gap-[20px]">
            <Button appearance="secondary" onClick={onClose} type="button">
              {t('act.cancel')}
            </Button>
            <Button type="submit">{t('act.submit')}</Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ReceiptConfirmModal;
