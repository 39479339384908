import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Checkbox from 'src/component/Checkbox';
import MenuTrading from 'src/component/MenuTrading';
import Pagination from 'src/component/Pagination';
import RoundSelect from 'src/component/RoundSelect';
import SelectOption from 'src/component/SelectOption';
import SortingSelect from 'src/component/SortingSelect';
import Tabs from 'src/component/Tabs';
import { MediaQuery } from 'src/constant/Media';
import { Action } from 'src/constant/Order';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import { usePagination } from 'src/hook/usePagination';
import IcArrowDown from 'src/image/ic-arrow-down-s.svg';
import { TradingForm } from 'src/model/Form';
import { OrderAction, UserOrder } from 'src/model/Order';
import { RootState } from 'src/redux/store';
import { openSnackbar, setBatchOrder } from 'src/redux/uiSlice';
import { actionOrder, getUserOrders, suspendOrderOnce } from 'src/service/orderService';
import Card from './component/Card';
import ConfirmationBatchModal from './component/ConfirmationBatchModal';
import ConfirmationModal from './component/ConfirmationModal';
import Table from './component/Table';

const MyTrade = () => {
  const { t, ready } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as string | null;
  const { batchOrder } = useSelector((rootState: RootState) => rootState.ui);
  const isBiggerThanMedium = useMediaPredicate(MediaQuery.Md);
  const { IcEmpty, IcBatch, IcClosePrimary } = useContext(ThemeContext).image;
  const { limit, offset, setCount, paginationProps } = usePagination();
  const [filterStatus, setFilterStatus] = useState<string>('open,suspended');
  const [filterSorting, setFilterSorting] = useState<string>('default');
  const [orders, setOrders] = useState<UserOrder[]>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [orderAction, setOrderAction] = useState<OrderAction>();
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [batchConfirmModalOpen, setBatchConfirmModalOpen] = useState<boolean>(false);
  const [isActionConfirmed, setActionConfirmed] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isBatchClick, setIsBatchClick] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selected, setSelected] = useState<UserOrder[]>([]);
  const tradingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (state === 'DISPLAY_CHECKED') {
      setIsBatchClick(true);
      setSelected([...batchOrder]);
    } else dispatch(setBatchOrder([]));
  }, [state]);

  useEffect(() => {
    getUserOrders(filterSorting, { limit, offset, status: filterStatus })
      .then((res) => {
        setOrders(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [refresh, limit, offset, filterStatus, filterSorting]);

  useEffect(() => {
    if (orderAction === undefined || !isActionConfirmed) return;
    if (orderAction.action === Action.Edit) {
      const form: TradingForm = {
        base: 'usdt',
        quote: 'cny',
        price: orderAction.userOrder.price,
        amount: orderAction.userOrder.openAmount,
        minTotal: orderAction.userOrder.minTotal,
        maxTotal: orderAction.userOrder.maxTotal,
        bankAccountId: orderAction.userOrder.bankAccount.id,
        orderId: orderAction.userOrder.id,
      };
      if (orderAction.userOrder.status === 'open')
        actionOrder(Action.Suspend, orderAction.userOrder.id)
          .then(() => navigate(Page.Trading, { state: form }))
          .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
      else navigate(Page.Trading, { state: form });
    } else
      actionOrder(orderAction.action, orderAction.userOrder.id)
        .then(() => setRefresh(!refresh))
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));

    setOrderAction(undefined);
    setActionConfirmed(false);
  }, [orderAction, isActionConfirmed]);

  useEffect(() => {
    const temp = [...selected].map((v) => v.id);
    if (isBatchClick === false) setSelectAll(false);
    else if (orders?.every((v) => temp.includes(v.id))) setSelectAll(true);
    else setSelectAll(false);
  }, [isBatchClick, orders, selected]);

  const onAction = (id: string, thisAction: Action) => {
    const userOrder = orders?.find((value) => value.id === id);
    if (userOrder === undefined) return;

    setOrderAction({
      action: thisAction,
      userOrder,
    });
    if (thisAction === Action.Edit && userOrder?.status === 'suspended') setActionConfirmed(true);
    else setConfirmationModalOpen(true);
  };

  const onConfirmationModalClosed = (isConfirm: boolean) => {
    setConfirmationModalOpen(false);
    setActionConfirmed(isConfirm);
  };

  const onSortingFilterChange = (value: string) => {
    paginationProps.setPage(1);
    setFilterSorting(value);
  };

  const onStatusFilterChange = (value: string) => {
    paginationProps.setPage(1);
    setFilterStatus(value);
  };

  const onSelectAll = useCallback(() => {
    if (!orders) return;
    const temp = [...selected];
    orders.forEach((order) => {
      const idx = temp.findIndex((v) => v.id === order.id);
      if (selectAll === false && idx < 0) temp.push(order);
      else if (selectAll === true && idx >= 0) temp.splice(idx, 1);
    });
    setSelected(temp);
  }, [selected, orders, selectAll]);

  const onSelectOne = useCallback(
    (order: UserOrder) => {
      const temp = [...selected];
      const idx = temp.findIndex((v) => v.id === order.id);
      if (idx >= 0) temp.splice(idx, 1);
      else temp.push(order);
      setSelected(temp);
    },
    [selected],
  );

  const onBatchEdit = () => {
    setBatchConfirmModalOpen(false);
    suspendOrderOnce(selected.filter((v) => v.status === 'open').map((v) => v.id))
      .then(() => {
        navigate(Page.TradingBatch, {
          state: selected.map((v) => ({
            id: v.id,
            price: v.price,
            amount: v.amount,
            minTotal: v.minTotal,
            maxTotal: v.maxTotal,
            bankAccountId: v.bankAccount.id,
          })),
        });
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onBatchAction = (action: 'suspend' | 'unsuspend' | 'delete') => {
    dispatch(setBatchOrder([...selected]));
    navigate(Page.TradingBatchConfirm, {
      replace: true,
      state: {
        action,
        base: 'usdt',
        quote: 'cny',
        batchData: selected.map((v) => ({
          id: v.id,
          price: v.price,
          amount: v.amount,
          minTotal: v.minTotal,
          maxTotal: v.maxTotal,
          bankAccountId: v.bankAccount.id,
          bankAccount: v.bankAccount,
        })),
      },
    });
  };

  if (!ready) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] flex flex-row justify-between text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('myTrade.heading')}
        <div className="flex h-fit flex-row gap-[12px]">
          <Button appearance="text" onClick={() => navigate(Page.Trade, { replace: true })}>
            {t('myTrade.act.trade')}
          </Button>
          <div
            className="flex cursor-pointer items-center"
            onClick={() => setMenuOpen(true)}
            ref={tradingRef}
          >
            <div className="text-[14px] text-turquoise hover:underline dark:text-aqua">
              {t('trade.act.trading')}
            </div>
            <img src={IcArrowDown} />
          </div>
        </div>
      </div>
      <div className="mt-[16px]">
        <Tabs labels={['USDT']} />
      </div>
      <div className="mt-[15px] rounded-[4px] bg-white px-[15px] py-[25px] dark:bg-black-800 md:px-[40px] md:py-[25px]">
        <div className="mb-[15px] flex flex-col flex-wrap justify-between gap-[25px] md:flex-row md:items-center">
          <div className="flex gap-2">
            <RoundSelect defaultValue="open,suspended" onChange={onStatusFilterChange}>
              <SelectOption value="open,suspended">
                {t('myTrade.desc.statusOption.all')}
              </SelectOption>
              <SelectOption value="open">{t('myTrade.desc.statusOption.open')}</SelectOption>
              <SelectOption value="suspended">
                {t('myTrade.desc.statusOption.suspended')}
              </SelectOption>
            </RoundSelect>
            <SortingSelect defaultValue="default" onChange={onSortingFilterChange}>
              <SelectOption value="default">{t('myTrade.desc.sortingOption.default')}</SelectOption>
              <SelectOption value="status">{t('myTrade.desc.sortingOption.status')}</SelectOption>
              <SelectOption value="-price">
                {t('myTrade.desc.sortingOption.priceDesc')}
              </SelectOption>
              <SelectOption value="price">{t('myTrade.desc.sortingOption.priceAsce')}</SelectOption>
              <SelectOption value="-open_amount">
                {t('myTrade.desc.sortingOption.amountDesc')}
              </SelectOption>
              <SelectOption value="open_amount">
                {t('myTrade.desc.sortingOption.amountAsce')}
              </SelectOption>
            </SortingSelect>
          </div>
          {isBatchClick ? (
            <div className="relative flex gap-6">
              <div className="flex gap-6">
                <Button
                  appearance="text"
                  disabled={selected.length === 0}
                  onClick={() => setBatchConfirmModalOpen(true)}
                >
                  {t('myTrade.act.edit')}
                </Button>
                <Button
                  appearance="text"
                  disabled={
                    selected.length === 0 ||
                    [...selected].filter((v) => v.status === 'suspended').length !== selected.length
                  }
                  onClick={() => onBatchAction('delete')}
                >
                  {t('myTrade.act.delete')}
                </Button>
                <Button
                  appearance="text"
                  disabled={
                    selected.length === 0 ||
                    [...selected].filter((v) => v.status === 'suspended').length !== selected.length
                  }
                  onClick={() => onBatchAction('unsuspend')}
                >
                  {t('myTrade.act.republish')}
                </Button>
                <Button
                  appearance="text"
                  disabled={
                    selected.length === 0 ||
                    [...selected].filter((v) => v.status === 'open').length !== selected.length
                  }
                  onClick={() => onBatchAction('suspend')}
                >
                  {t('myTrade.act.close')}
                </Button>
              </div>
              <img
                src={IcClosePrimary}
                className="-order-1 cursor-pointer md:order-1"
                onClick={() => {
                  setIsBatchClick(false);
                  setSelected([]);
                  dispatch(setBatchOrder([]));
                }}
              />
              <div className="absolute bottom-0 right-0 md:hidden">
                <Checkbox checked={selectAll} onChange={onSelectAll} />
              </div>
            </div>
          ) : (
            <div className="flex cursor-pointer items-center" onClick={() => setIsBatchClick(true)}>
              <img src={IcBatch} />
              <Button appearance="text">{t('myTrade.desc.batch')}</Button>
            </div>
          )}
        </div>
        <div className="my-[15px] h-[1px] bg-light-200 dark:bg-dark-700" />
        {orders?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {orders &&
          orders.length > 0 &&
          (isBiggerThanMedium ? (
            <Table
              data={orders}
              onAction={onAction}
              isBatchClick={isBatchClick}
              selected={selected}
              selectAll={selectAll}
              onSelectAll={onSelectAll}
              onSelectOne={onSelectOne}
            />
          ) : (
            <Card
              data={orders}
              onAction={onAction}
              isBatchClick={isBatchClick}
              selected={selected}
              onSelectOne={onSelectOne}
            />
          ))}
      </div>
      <div className="mt-[20px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        action={orderAction?.action}
        onClose={onConfirmationModalClosed}
      />
      <ConfirmationBatchModal
        isOpen={batchConfirmModalOpen}
        onClose={() => setBatchConfirmModalOpen(false)}
        onConfirm={onBatchEdit}
      />
      <MenuTrading
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={tradingRef.current}
      />
    </div>
  );
};

export default MyTrade;
