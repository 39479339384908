import { useTranslation } from 'react-i18next';
import { OpenOrder } from 'src/model/Order';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: OpenOrder[];
};

const TradeTable = ({ data }: Props) => {
  const { t } = useTranslation();

  const elementMapFunction = (transaction: OpenOrder) => (
    <div key={transaction.id} className="flex flex-col">
      <div className="flex flex-row">
        <div className="box-border w-1/3 break-words pr-[10px] text-[14px] font-bold text-turquoise dark:text-aqua">
          <div className="text-[14px] font-bold text-turquoise dark:text-aqua">
            {transaction.userSlug}
          </div>
        </div>
        <div className="box-border w-1/3 break-words pr-[10px]">
          <div className="flex flex-row items-center gap-[10px]">
            <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
              {t('trade.desc.amount')}
            </div>
            <div>{`${bnFormat(transaction.amount)} ${transaction.base.toUpperCase()}`}</div>
          </div>
          <div className="flex flex-row items-center gap-[10px]">
            <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
              {t('trade.desc.paymentLimit')}
            </div>
            <div>{`${bnFormat(transaction.minTotal)} - ${bnFormat(
              transaction.maxTotal,
            )} ${transaction.quote.toUpperCase()}`}</div>
          </div>
        </div>
        <div className="box-border w-1/3 break-words pr-[10px]">
          <div className="text-[12px] text-green dark:text-lime">
            {transaction.paymentMethodType === 'bank'
              ? t('trade.desc.bankCard')
              : t('trade.desc.alipay')}
          </div>
        </div>
      </div>
      <div className="my-[15px] h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div className="mt-[15px]">
      <div className="flex flex-row text-[14px] font-bold text-grey-500 dark:text-grey-700">
        <div className="w-1/3">{t('trade.desc.owner')}</div>
        <div className="w-1/3">{`${t('trade.desc.amount')}/${t('trade.desc.paymentLimit')}`}</div>
        <div className="w-1/3">{t('trade.desc.paymentMethod')}</div>
      </div>
      <div className="my-[15px] h-[1px] bg-light-200 dark:bg-dark-700" />
      {data.map(elementMapFunction)}
    </div>
  );
};

export default TradeTable;
