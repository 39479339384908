import { t } from 'i18next';
import { Page } from 'src/constant/Page';
import {
  PostAuthEmailResendRequest,
  PostAuthEmailVerifyRequest,
  PostAuthOtpResponse,
  PostAuthPasswordResetRequest,
  PostAuthPasswordSendRequest,
  PostAuthSigninRequest,
  PostAuthSigninResponse,
  PostAuthSignoutRequest,
  PostAuthSignupRequest,
  PostAuthSignupResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import { emitter } from 'src/util/eventBus';
import http from 'src/util/http';

const signup = async (data: PostAuthSignupRequest) => {
  try {
    return await http.post<PostAuthSignupResponse, PostAuthSignupRequest>('auth/signup', { data });
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 409 && error.response.data.code === 0)
      throw t('error.auth.conflict');
    throw defaultErrorMessage(error);
  }
};

const emailVerify = async (data: PostAuthEmailVerifyRequest) => {
  try {
    return await http.post<void, PostAuthEmailVerifyRequest>('auth/email/verify', { data });
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 403 && error.response.data.code === 0)
      throw t('error.auth.verfyFailed');

    throw defaultErrorMessage(error);
  }
};

const emailResend = async (data: PostAuthEmailResendRequest) => {
  try {
    return await http.post<void, PostAuthEmailResendRequest>('auth/email/resend', { data });
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 403 && error.response.data.code === 0) {
      emitter.emit('force-redirect', Page.Trade);
      throw t('error.auth.emailVerified');
    }
    throw defaultErrorMessage(error);
  }
};

const signin = async (data: PostAuthSigninRequest) => {
  try {
    return await http.post<PostAuthSigninResponse, PostAuthSigninRequest>('auth/signin', { data });
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 400 && error.response.data.code === 0)
      throw t('error.auth.wrongEmailOrPwd');
    if (error.response?.status === 401 && error.response.data.code === 4) throw 'otp';
    if (error.response?.status === 401 && error.response.data.code === 5) throw 'unverified';
    throw defaultErrorMessage(error);
  }
};

const signout = async (data: PostAuthSignoutRequest) => {
  try {
    return await http.authPost<void, PostAuthSignoutRequest>('auth/signout', { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const activateOtp = async (method: string) => {
  try {
    return await http.authPost<PostAuthOtpResponse>(`auth/otp/${method}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const verifyOtp = async (method: string, code: string) => {
  try {
    return await http.authPatch(`auth/otp/${method}`, {
      headers: { 'x-api-otp': `${method}=${code}` },
    });
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 403 && error.response.data.code === 0) {
      emitter.emit('force-redirect', Page.Account);
      throw t('error.auth.alreadyVerified');
    }
    throw defaultErrorMessage(error);
  }
};

const deactivateOtp = async (method: string, code: string) => {
  try {
    return await http.authDelete(`auth/otp/${method}`, {
      headers: { 'x-api-otp': `${method}=${code}` },
    });
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 403 && error.response.data.code === 0) {
      emitter.emit('force-redirect', Page.Account);
      throw t('error.auth.alreadyDeactivated');
    }
    throw defaultErrorMessage(error);
  }
};

const sendPasswordResetVerification = async (data: PostAuthPasswordSendRequest) => {
  try {
    return await http.authPost<void, PostAuthPasswordSendRequest>('auth/password/send', { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const resetPassword = async (data: PostAuthPasswordResetRequest) => {
  try {
    return await http.authPost<void, PostAuthPasswordResetRequest>('auth/password/reset', { data });
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 400 && error.response.data.code === 0)
      throw t('error.auth.wrongResetPw');
    throw defaultErrorMessage(error);
  }
};

export default {
  resetPassword,
  sendPasswordResetVerification,
  signup,
  emailVerify,
  emailResend,
  signin,
  signout,
  activateOtp,
  verifyOtp,
  deactivateOtp,
};
