import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = HTMLAttributes<HTMLDivElement> & { focus?: boolean };

const ListItem = ({ children, focus, className, ...props }: Props) => (
  <div
    className={twMerge(
      `flex h-[41px] cursor-pointer items-center bg-white px-[15px] text-dark-700 
      hover:bg-light-100 dark:bg-black-700 dark:text-white hover:dark:bg-black-600`,
      className,
      classNames({ 'text-turquoise dark:text-aqua': focus }),
    )}
    {...props}
  >
    {children}
  </div>
);

export default ListItem;
