import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountInfo } from 'src/model/Account';

export type AuthState = {
  isLogin: boolean;
  accountInfo?: AccountInfo;
};

const initialState: AuthState = {
  isLogin: !!localStorage.getItem('token'),
  accountInfo: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLogin: (state: AuthState) => {
      state.isLogin = true;
    },
    setAccountInfo: (state: AuthState, action: PayloadAction<AccountInfo>) => {
      state.accountInfo = action.payload;
    },
    resetAuth: (state: AuthState) => {
      state.isLogin = false;
      state.accountInfo = undefined;
    },
  },
});

export const { setIsLogin, setAccountInfo, resetAuth } = authSlice.actions;

export default authSlice.reducer;
