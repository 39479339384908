import { ReactNode } from 'react';
import SelectContext from 'src/context/SelectContext';
import ListItem from './ListItem';

/**
 * usage note:
 * this component is for AutoComplete component, please use this only with it.
 */

type Props = {
  value: string;
  children: ReactNode;
};

const AutoCompleteOption = ({ value, children }: Props) => (
  <SelectContext.Consumer>
    {({ current, handleChange, filter }) =>
      (!filter || value.toLowerCase().includes(current.toLowerCase())) && (
        <ListItem
          onMouseDown={() => handleChange(value)}
          focus={current === value}
          className="rounded-xl"
        >
          {children}
        </ListItem>
      )
    }
  </SelectContext.Consumer>
);

export default AutoCompleteOption;
