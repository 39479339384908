import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Alert from 'src/component/Alert';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import { Severity } from 'src/constant/Notification';
import { Page } from 'src/constant/Page';
import { ForgetPasswordForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { resetPassword, sendPasswordResetVerification } from 'src/service/authService';

const ForgetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [count, setCount] = useState<number>(-1);
  const methods = useForm<ForgetPasswordForm>();
  const formData = useWatch({ control: methods.control });

  useEffect(() => {
    const timer = setInterval(() => {
      setCount(count - 1);
    }, 1000);
    if (count - 1 < 0) {
      setCount(-1);
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [count]);

  const onSubmit = (data: ForgetPasswordForm) => {
    if (data.password !== data.confirmationpassword) {
      methods.setError('confirmationpassword', {}, { shouldFocus: true });

      return;
    }

    resetPassword({
      email: data.email,
      password: data.password,
      code: data.code,
    })
      .then(() => {
        navigate(Page.SignIn);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const getVerificationCode = () => {
    if (formData.email === undefined || formData.email?.length === 0) {
      methods.setError('email', {}, { shouldFocus: true });

      return;
    }
    sendPasswordResetVerification({
      email: formData.email,
    })
      .then(() => {
        setCount(30);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <div className="auth-layout">
      <BackButton
        label={t('login.heading')}
        navigateToPath={Page.SignIn}
        data-testid="signin-btn"
      />
      <Form methods={methods} onSubmit={onSubmit}>
        <div className="mt-[15px] flex flex-col">
          <FormInput
            label={t('forgot.desc.account')}
            type="email"
            name="email"
            placeholder={t('forgot.desc.registerEmailAddress')}
            asterisked
            required
            data-testid="account-input"
          />
          <div className="mt-[5px] text-right">
            <Button
              appearance="text"
              onClick={getVerificationCode}
              type="button"
              disabled={count > 0}
              data-testid="get-code-btn"
            >
              {count > 0 ? count : t('forgot.act.getVerificationCode')}
            </Button>
          </div>
        </div>
        <div className="mt-[25px]">
          <FormInput
            label={t('forgot.desc.emailVerificationCode')}
            type="text"
            name="code"
            placeholder={t('forgot.desc.emailVerificationCode')}
            asterisked
            required
            data-testid="code-input"
          />
        </div>
        <Alert severity={Severity.Error} className="mt-[30px]">
          {t('forgot.desc.noWithdrawal')}
        </Alert>
        <div className="mt-[25px]">
          <FormInput
            label={t('forgot.desc.password')}
            type="password"
            name="password"
            placeholder={t('forgot.desc.passwordPlaceholder')}
            asterisked
            required
            data-testid="password-input"
          />
        </div>
        <div className="mt-[25px]">
          <FormInput
            name="confirmationpassword"
            label={t('forgot.desc.passwordConfirmation')}
            type="password"
            placeholder={t('forgot.desc.confirmPasswordPlaceholder')}
            asterisked
            required
            data-testid="confirm-password-input"
          />
        </div>
        <div className="mt-[24px] text-right">
          <Button size="large" type="submit" data-testid="submit-btn">
            {t('forgot.act.submit')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ForgetPassword;
