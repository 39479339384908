import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch } from 'react-redux';
import BackButton from 'src/component/BackButton';
import { MediaQuery } from 'src/constant/Media';
import { ThemeContext } from 'src/context/ThemeContext';
import { Kid } from 'src/model/AccountUser';
import { openSnackbar } from 'src/redux/uiSlice';
import { getKids } from 'src/service/agencyService';
import Card from './component/Card';
import CommissionModal from './component/CommissionModal';
import Table from './component/Table';

const Agency = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { IcEmpty } = useContext(ThemeContext).image;
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const [kids, setKids] = useState<Kid[]>();
  const [targetId, setTargetId] = useState<string>();
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    getKids()
      .then((res) => setKids(res))
      .catch((e) => dispatch(openSnackbar(e.message)));
  }, [refresh]);

  const onEdit = (id: string) => () => setTargetId(id);

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] text-[28px] font-semibold sm:mt-[20px] sm:text-[32px]">
        {t('agency.heading')}
      </div>
      <div className="mt-[30px] rounded bg-white px-[15px] py-[25px] dark:bg-black-800 xs:px-10">
        {kids?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {kids &&
          (isBiggerThanMd ? (
            <Table kids={kids} onEdit={onEdit} />
          ) : (
            <Card kids={kids} onEdit={onEdit} />
          ))}
      </div>
      <CommissionModal
        open={targetId !== undefined}
        handleClose={() => setTargetId(undefined)}
        kid={kids?.find((v) => v.id === targetId)}
        doRefresh={() => setRefresh(!refresh)}
      />
    </div>
  );
};

export default Agency;
