import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Alert from 'src/component/Alert';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import { Ui } from 'src/constant/Env';
import { Severity } from 'src/constant/Notification';
import { Page } from 'src/constant/Page';
import { PostWithdrawalResponse } from 'src/model/Api';
import { WithdrawalVeriyfyForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { resendWithdrawalVerify, verifyWithdrawal } from 'src/service/propertyService';

type Props = {
  open: boolean;
  onClose: () => void;
  withdrawalData?: PostWithdrawalResponse;
};

const VerifyModal = ({ open, onClose, withdrawalData }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm<WithdrawalVeriyfyForm>();

  const onSubmit = (data: WithdrawalVeriyfyForm) => {
    if (!withdrawalData) return;
    verifyWithdrawal(withdrawalData.id, data.code)
      .then(() => {
        onClose();
        methods.reset();
        navigate(Ui === 'admin' ? `${Page.UserAdmin}/withdrawal/success` : Page.WithdrawalSuccess, {
          replace: true,
          state: { userId: withdrawalData.userId },
        });
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onResend = () => {
    if (!withdrawalData) return;
    resendWithdrawalVerify(withdrawalData.id).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <Modal open={open} handleClose={onClose}>
      <div>
        <div className="mb-[20px] text-[32px] font-bold">{t('withdrawal.desc.verifyHead')}</div>
        <div>{t('withdrawal.desc.verfiyText')}</div>
        <Form methods={methods} onSubmit={onSubmit} className="mt-[25px]">
          <FormInput label={t('withdrawal.desc.verifyLabel')} name="code" autoFocus />
          <Alert className="mb-[20px] mt-[25px]" severity={Severity.Clear}>
            {t('withdrawal.desc.verifyHint')}
          </Alert>
          <div className="flex gap-[5px]">
            <div>{t('withdrawal.desc.resendHint')}</div>
            <Button appearance="text" size="large" onClick={onResend} type="button">
              {t('withdrawal.desc.resend')}
            </Button>
          </div>
          <div className="mt-[40px] flex flex-row justify-end gap-[20px]">
            <Button appearance="secondary" onClick={onClose} type="button">
              {t('act.cancel')}
            </Button>
            <Button type="submit">{t('act.submit')}</Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default VerifyModal;
