import { Controller, useFormContext } from 'react-hook-form';
import NumberInput, { Props as NumberInputProps } from './NumberInput';

/**
 * usage note:
 * Please see Form.tsx
 */

type Props = NumberInputProps & {
  name: string;
  required?: boolean;
  asterisked?: boolean;
  min?: number;
};

const FormNumberInput = ({ name, required = false, defaultValue, min, ...props }: Props) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue ?? ''}
      rules={{ required, min }}
      render={({ field }) => (
        <NumberInput {...field} {...props} error={!!errors[name]?.message || !!errors[name]} />
      )}
    />
  );
};

export default FormNumberInput;
