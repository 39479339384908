import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import DatetimePicker from 'src/component/DatetimePicker';
import Modal from 'src/component/Modal';

type Props = {
  open: boolean;
  onClose: () => void;
  defaultBegin: string;
  defaultEnd: string;
  onSubmit: (from: string, to: string) => void;
};

const QueryModal = ({ open, onClose, defaultBegin, defaultEnd, onSubmit }: Props) => {
  const { t } = useTranslation();
  const [begin, setBegin] = useState<string>(defaultBegin);
  const [end, setEnd] = useState<string>(defaultEnd);

  return (
    <Modal open={open} handleClose={onClose}>
      <>
        <div className="text-[32px] font-bold text-black-900 dark:text-white">
          {t('report.desc.interval')}
        </div>
        <div className="mt-[25px] text-[14px] text-dark-500 dark:text-grey-300">
          {t('report.desc.from')}
        </div>
        <DatetimePicker
          value={begin}
          onChange={(e) => {
            if (!e) return;
            if (new Date(e) > new Date(end)) setEnd(e);
            setBegin(e);
          }}
        />
        <div className="mt-[25px] text-[14px] text-dark-500 dark:text-grey-300">
          {t('report.desc.to')}
        </div>
        <DatetimePicker
          value={end}
          onChange={(e) => {
            if (!e) return;
            if (new Date(e) < new Date(end)) setBegin(e);
            setEnd(e);
          }}
        />
        <div className="mt-[65px] flex justify-end gap-[20px]">
          <Button className="font-bold" appearance="outlined" onClick={onClose}>
            取消
          </Button>
          <Button
            className="font-bold"
            onClick={() => {
              onClose();
              onSubmit(begin, end);
            }}
          >
            送出
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default QueryModal;
