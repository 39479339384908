import { GetNotificationResponse } from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getNotificationList = async () => {
  try {
    return await http.authGet<GetNotificationResponse>('notifications');
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getNotificationList,
};
