import { Popover } from '@mui/material';
import { format } from 'date-fns';
import ReactCalendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import './calendar.scss';

type Props = {
  open: boolean;
  onClose: () => void;
  anchorEl?: Element | null;
  value: Date;
  onChange: (v: Date) => void;
  onClear?: () => void;
};

const Calendar = ({ open, onClose, anchorEl, value, onChange, onClear }: Props) => {
  const { t } = useTranslation();

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 40,
        horizontal: 'left',
      }}
      className="!rounded-[4px]"
    >
      <div className="relative box-border h-[102px] bg-turquoise px-[20px] py-[25px] text-white dark:bg-lime dark:text-black">
        <div className="text-[12px]">{format(value, 'yyyy')}</div>
        <div className="text-[24px] font-bold">{format(value, 'E, MMMM d')}</div>
        {onClear && (
          <div className="absolute right-[9px] top-[6px] cursor-pointer" onClick={onClear}>
            {t('act.clear')}
          </div>
        )}
      </div>
      <ReactCalendar locale="en-US" defaultValue={value} onChange={onChange} />
    </Popover>
  );
};

export default Calendar;
