import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import IcLogo from 'src/image/ic-logo.svg';

const Bar = () => {
  const navigate = useNavigate();

  return (
    <div className="box-border p-[15px]">
      <img className="cursor-pointer" src={IcLogo} onClick={() => navigate(Page.Landing)} />
    </div>
  );
};

export default Bar;
