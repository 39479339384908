import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Modal from 'src/component/Modal';
import { Page } from 'src/constant/Page';

type Props = {
  open: boolean;
  onClose: () => void;
};

const RedirectModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal open={open} handleClose={onClose}>
      <div>
        <div>{t('property.desc.redirectHint')}</div>
        <div className="mt-[40px] flex flex-row justify-end gap-[20px]">
          <Button appearance="secondary" onClick={onClose} type="button">
            {t('act.cancel')}
          </Button>
          <Button type="button" onClick={() => navigate(Page.EnableTfa)}>
            {t('act.go')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RedirectModal;
