import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AppName } from './constant/Env';
import { ThemeProvider } from './context/ThemeContext';
import { configStore } from './redux/store';
import './style/index.css';
import './util/i18n';

document.title = AppName;

const store = configStore();

const root = createRoot(document.getElementById('root') as Element);

root.render(
  <Provider store={store}>
    <ThemeProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
);
