import {
  GetFillAdminResponse,
  GetFillIdResponse,
  GetFillsAdminParams,
  GetFillsAdminResponse,
  GetFillsParams,
  GetFillsResponse,
  PatchFillIdRejectResponse,
  PatchFillsSend,
  PostFillIdMessageRequest,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getFills = async (params?: GetFillsParams) => {
  try {
    return await http.authGet<GetFillsResponse, GetFillsParams>('fills', { params });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getFillsAdmin = async (params?: GetFillsAdminParams, headers?: { [key: string]: string }) => {
  try {
    return await http.authGet<GetFillsAdminResponse, GetFillsAdminParams>('fills', {
      params,
      headers,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getFillId = async (id: string) => {
  try {
    return await http.authGet<GetFillIdResponse>(`fills/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getFillIdAdmin = async (id: string) => {
  try {
    return await http.authGet<GetFillAdminResponse>(`fills/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const patchFillsSend = async (id: string, data: PatchFillsSend) => {
  try {
    await http.authPatch(`fills/${id}/send`, { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const patchFillsReceive = async (id: string, code?: string) => {
  try {
    return await http.authPatch(`fills/${id}/receive`, {
      headers: code ? { 'x-api-otp': `gauth=${code}` } : undefined,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const patchFillsUnsend = async (id: string) => {
  try {
    return await http.authPatch(`fills/${id}/unsend`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const postFillsIdMessage = async (id: string, data: PostFillIdMessageRequest) => {
  try {
    return await http.authPost<void, PostFillIdMessageRequest>(`fills/${id}/message`, { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const patchFillsIdReject = async (id: string, code?: string) => {
  try {
    return await http.authPatch<PatchFillIdRejectResponse>(`fills/${id}/reject`, {
      headers: code ? { 'x-api-otp': `gauth=${code}` } : undefined,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getFills,
  getFillsAdmin,
  getFillId,
  getFillIdAdmin,
  patchFillsReceive,
  patchFillsSend,
  patchFillsUnsend,
  postFillsIdMessage,
  patchFillsIdReject,
};
