import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch } from 'react-redux';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import { MediaQuery } from 'src/constant/Media';
import { ThemeContext } from 'src/context/ThemeContext';
import { Contact } from 'src/model/Contact';
import { openSnackbar } from 'src/redux/uiSlice';
import { deleteContact, getContactList } from 'src/service/propertyService';
import AddWithdrawalAddressModal from './component/AddWithdrawalAddressModal';
import Card from './component/Card';
import Table from './component/Table';

const AddressManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { IcAdd, IcEmpty } = useContext(ThemeContext).image;
  const [isAddAddressModalOpen, setAddAddressModalOpen] = useState<boolean>(false);
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const [contactList, setContactList] = useState<Contact[]>();
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    getContactList()
      .then((res) => setContactList(res))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [refresh]);

  const onAddressDelete = (id: string) => {
    deleteContact(id)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  if (!contactList) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] flex flex-col xs:flex-row xs:justify-between xs:justify-items-end sm:mt-[20px]">
        <div className="text-[28px] font-bold sm:text-[32px]">{t('addressManagement.heading')}</div>
        <Button
          appearance="text"
          className="mt-[20px] flex flex-row items-center xs:mt-[0px]"
          onClick={() => setAddAddressModalOpen(true)}
        >
          <img src={IcAdd} />
          {t('addressManagement.act.addAddress')}
        </Button>
      </div>
      <div className="mt-[30px] rounded-[4px] bg-white dark:bg-black-800">
        {contactList.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {contactList.length > 0 &&
          (isBiggerThanMd ? (
            <Table contactList={contactList} onDelete={onAddressDelete} />
          ) : (
            <Card contactList={contactList} onDelete={onAddressDelete} />
          ))}
      </div>
      <AddWithdrawalAddressModal
        open={isAddAddressModalOpen}
        handleClose={() => setAddAddressModalOpen(false)}
        onFinish={() => setRefresh(!refresh)}
      />
    </div>
  );
};

export default AddressManagement;
