import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import FormRadioGroup from 'src/component/FormRadioGroup';
import SearchInput from 'src/component/SearchInput';
import Tabs from 'src/component/Tabs';
import { MediaQuery } from 'src/constant/Media';
import IcSortDefault from 'src/image/ic-sort-default.svg';
import IcSortDown from 'src/image/ic-sort-down.svg';
import IcSortUp from 'src/image/ic-sort-up.svg';
import { BankAccount } from 'src/model/Bank';
import { TradingForm } from 'src/model/Form';
import { compare } from 'src/util/compare';
import PaymentCard from './PaymentCard';
import PaymentTable from './PaymentTable';

type Props = {
  name: string;
  bankArray: BankAccount[];
  defaultSelected?: string;
};

const PaymentMethodsList = ({ name, bankArray, defaultSelected }: Props) => {
  const { t } = useTranslation();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const { getValues, setError, clearErrors } = useFormContext<TradingForm>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [sorting, setSorting] = useState<'default' | 'asc' | 'desc'>('default');
  const [sortedKey, setSortedKey] = useState<keyof BankAccount>();
  const [tab, setTab] = useState<number>(0);

  const defaultSelectedIndex = useMemo(() => {
    const index = bankArray.findIndex((value) => value.id === defaultSelected);

    return index >= 0 ? index : undefined;
  }, [bankArray, defaultSelected]);

  const sortedBankArray = useMemo(() => {
    if (sorting === 'default') return bankArray.filter((v) => v.name.includes(searchValue));
    if (sortedKey === 'name')
      return bankArray.filter((v) => v.name.includes(searchValue)).sort(compare('name', sorting));
    if (sortedKey === 'bankName')
      return bankArray
        .filter((v) => v.name.includes(searchValue))
        .sort(compare('bankName', sorting));

    return bankArray.filter((v) => v.name.includes(searchValue));
  }, [bankArray, sorting, sortedKey, searchValue]);

  useEffect(() => {
    if (sortedBankArray.map((v) => v.id).includes(getValues('bankAccountId')))
      clearErrors('bankAccountId');
    else setError('bankAccountId', {});
  }, [searchValue, getValues('bankAccountId')]);

  const onClickSorting = (key: keyof BankAccount) => () => {
    if (key === 'name' && sortedKey === 'bankName') {
      setSortedKey('name');
      setSorting('asc');
    } else if (key === 'bankName' && sortedKey === 'name') {
      setSortedKey('bankName');
      setSorting('asc');
    } else if (sorting === 'asc') setSorting('desc');
    else if (sorting === 'desc') setSorting('default');
    else {
      setSortedKey(key);
      setSorting('asc');
    }
  };

  const getSortingImage = (key: keyof BankAccount) => {
    if (key === 'name' && sortedKey === 'name') {
      if (sorting === 'asc') return IcSortUp;
      if (sorting === 'desc') return IcSortDown;
    } else if (key === 'bankName' && sortedKey === 'bankName') {
      if (sorting === 'asc') return IcSortUp;
      if (sorting === 'desc') return IcSortDown;
    }

    return IcSortDefault;
  };

  return (
    <>
      <div className="mx-0 mt-[15px] flex items-center justify-between gap-[15px]">
        <SearchInput
          value={searchValue}
          setValue={setSearchValue}
          placeholder={t('trading.desc.accountName')}
          className="w-[172px]"
        />
        <div className="flex gap-[15px] text-black-900 dark:text-white">
          <div
            className="flex cursor-pointer gap-[15px] rounded-[4px] border-[1px] border-solid border-light-500 px-[4px] py-[3px] dark:border-dark-500"
            onClick={onClickSorting('name')}
          >
            <div>{t('trading.desc.accountName')}</div>
            <img src={getSortingImage('name')} />
          </div>
          {tab === 0 && (
            <div
              className="flex cursor-pointer gap-[15px] rounded-[4px] border-[1px] border-solid border-light-500 px-[4px] py-[3px] dark:border-dark-500"
              onClick={onClickSorting('bankName')}
            >
              <div>{t('trading.desc.bank')}</div>
              <img src={getSortingImage('bankName')} />
            </div>
          )}
        </div>
      </div>
      {!isBiggerThanMd && (
        <div className="my-[15px]">
          <Tabs
            labels={[
              t('bankAccount.desc.paymentType.bank'),
              t('bankAccount.desc.paymentType.alipay'),
            ]}
            defaultIndex={tab}
            onChange={(v) => setTab(v)}
          />
        </div>
      )}
      <FormRadioGroup name={name}>
        {isBiggerThanMd && (
          <PaymentTable accountArray={sortedBankArray} defaultIndex={defaultSelectedIndex} />
        )}
        {!isBiggerThanMd && (
          <PaymentCard
            accountArray={sortedBankArray}
            defaultIndex={defaultSelectedIndex}
            type={tab === 0 ? 'bank' : 'alipay'}
          />
        )}
      </FormRadioGroup>
    </>
  );
};

export default PaymentMethodsList;
