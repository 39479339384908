import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import { Page } from 'src/constant/Page';
import { Bank } from 'src/model/Bank';
import { AlipayAccountForm, BankAccountForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { createBankAccount, getBank } from 'src/service/accountService';
import AlipayForm from './component/AlipayForm';
import BankForm from './component/BankForm';

const BankAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState<'bank' | 'alipay'>('bank');
  const [bank, setBank] = useState<Bank[]>();

  useEffect(() => {
    getBank()
      .then((res) => setBank(res))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, []);

  const onSubmit = (data: BankAccountForm | AlipayAccountForm) => {
    createBankAccount({ ...data, paymentMethodType: type })
      .then(() => navigate(Page.Account))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  if (!bank) return <></>;

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('bankAccount.heading')}
      </div>
      <div className="mt-[10px] text-[14px]">{t('bankAccount.headDetail')}</div>
      <div className="mx-auto mt-[30px]">
        <div className="box-border xs:px-[30px] md:w-1/3">
          <Select
            label={t('bankAccount.desc.paymentMethodType')}
            defaultValue={type}
            onChange={(v) => setType(v as 'bank' | 'alipay')}
          >
            <SelectOption value="bank">{t('bankAccount.desc.paymentType.bank')}</SelectOption>
            <SelectOption value="alipay">{t('bankAccount.desc.paymentType.alipay')}</SelectOption>
          </Select>
        </div>
      </div>
      {type === 'bank' && <BankForm bank={bank} onSubmit={onSubmit} />}
      {type === 'alipay' && <AlipayForm onSubmit={onSubmit} />}
    </div>
  );
};

export default BankAccount;
