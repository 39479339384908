import { Dispatch, SetStateAction } from 'react';
import { twMerge } from 'tailwind-merge';
import IcClose from 'src/image/ic-close-s.svg';
import IcSearch from 'src/image/ic-search.svg';
import Input from './Input';

type Props = {
  placeholder?: string;
  className?: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

const SearchInput = ({ placeholder, className, value, setValue }: Props) => (
  <div className={twMerge('relative w-[300px]', className)}>
    <div className="absolute top-[7px] box-border">
      <img src={IcSearch} />
    </div>
    <Input
      type="text"
      placeholder={placeholder}
      className="flex-[1] pl-[26px]"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
    {value && (
      <div
        className="absolute right-0 top-[12px] flex cursor-pointer items-center justify-center rounded-[50%] bg-dark-500 p-[3px] dark:bg-grey-300"
        onClick={() => setValue('')}
      >
        <img className="h-[12px] w-[12px]" src={IcClose} />
      </div>
    )}
  </div>
);

export default SearchInput;
