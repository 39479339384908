import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CannedMessage } from 'src/model/Message';

export type ApiState = {
  cannedMessageList: CannedMessage[] | null;
};

const initialState: ApiState = {
  cannedMessageList: null,
};

export const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setCannedMessageList: (state: ApiState, action: PayloadAction<CannedMessage[]>) => {
      state.cannedMessageList = action.payload;
    },
  },
});

export const { setCannedMessageList } = apiSlice.actions;

export default apiSlice.reducer;
