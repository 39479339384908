import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import RadioGroup from './RadioGroup';

/**
 * usage note:
 * Please see Form.tsx
 */

type Props = {
  name: string;
  children: ReactNode;
  className?: string;
};

const FormRadioGroup = ({ name, children, ...props }: Props) => {
  const { register } = useFormContext();

  return (
    <RadioGroup {...register(name)} {...props}>
      {children}
    </RadioGroup>
  );
};

export default FormRadioGroup;
