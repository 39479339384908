import classNames from 'classnames';
import { HTMLAttributes, useContext } from 'react';
import { twMerge } from 'tailwind-merge';
import { Severity } from 'src/constant/Notification';
import { ThemeContext } from 'src/context/ThemeContext';

type Props = HTMLAttributes<HTMLDivElement> & {
  severity: Severity;
};

const Alert = ({ severity, className, children, ...props }: Props) => {
  const { IcNoticeClear, IcNoticeWarning, IcNoticeError } = useContext(ThemeContext).image;
  const icons = {
    [Severity.Clear]: IcNoticeClear,
    [Severity.Warning]: IcNoticeWarning,
    [Severity.Error]: IcNoticeError,
  };

  return (
    <div
      className={twMerge(
        className,
        'flex flex-row rounded-[4px] p-[10px] text-black-900 dark:text-white',
        classNames({
          'bg-turquoise/30 dark:bg-lime/30': severity === Severity.Clear,
          'bg-khaki/30 dark:bg-yellow/30': severity === Severity.Warning,
          'bg-crimson/30 dark:bg-red/30': severity === Severity.Error,
        }),
      )}
      {...props}
    >
      <img className="mr-[10px] h-[21px] w-[21px]" src={icons[severity]} />
      <p className="m-0 text-[14px]">{children}</p>
    </div>
  );
};

export default Alert;
