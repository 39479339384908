import classNames from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Page, PageWithPublicBar, PrivatePage } from 'src/constant/Page';
import Loader from './component/Loader';
import Navbar from './component/Navbar';
import PinnedNotice from './component/PinnedNotice';
import PublicBar from './component/PublicBar';
import SideMenu from './component/SideMenu';
import SideMenuAdmin from './component/SideMenuAdmin';
import Snackbar from './component/Snackbar';
import ToastGroup from './component/ToastGroup';
import TopBar from './component/TopBar';
import { Ui } from './constant/Env';
import { MediaQuery } from './constant/Media';
import { RootState } from './redux/store';
import { openSnackbar } from './redux/uiSlice';
import AppRoutes from './Routes';
import { init, loadDemand, loadNotification } from './service/appService';
import { emitter } from './util/eventBus';

const App = () => {
  const isBiggerThanSm = useMediaPredicate(MediaQuery.Sm);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const {
    auth: { accountInfo, isLogin },
    ui: { notification, demand },
  } = useSelector((state: RootState) => state);
  const isPrivate = PrivatePage.find((value) => location.pathname.startsWith(value)) !== undefined;
  const hasPublicBar =
    PageWithPublicBar.find((value) => location.pathname.startsWith(value)) !== undefined;

  useEffect(() => {
    emitter.on('auth-expired', () => navigate(Page.SignIn));
    emitter.on('force-redirect', (path: Page) => navigate(path));
    emitter.on('force-tfa-process', () => navigate(Page.EnableTfa));
  }, []);

  useEffect(() => {
    if (!accountInfo?.locale) return;

    i18n.changeLanguage(accountInfo.locale === 'zh' ? 'zh-cn' : accountInfo.locale);
  }, [accountInfo?.locale]);

  useEffect(() => {
    let timerNotification: NodeJS.Timer;
    let timerDemand: NodeJS.Timer;
    if (isLogin) {
      init().catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));

      timerNotification = setInterval(() => {
        loadNotification();
      }, 10 * 1000);

      loadDemand();
      timerDemand = setInterval(loadDemand, 60 * 1000);
    }

    return () => {
      clearInterval(timerNotification);
      clearInterval(timerDemand);
    };
  }, [isLogin]);

  const customRoutes = () => {
    if (Ui === 'admin' && !location.pathname.startsWith('/auth'))
      return (
        <div className="flex">
          <SideMenuAdmin />
          <div className="h-[100vh] flex-1 overflow-y-auto [&>div]:mx-auto [&>div]:my-0 [&>div]:w-[85%] [&>div]:max-w-[1200px] [&>div]:px-0 [&>div]:pb-[90px] [&>div]:pt-[30px]">
            <AppRoutes />
          </div>
        </div>
      );

    if (isPrivate)
      return (
        <>
          {Ui === 'seller' && <TopBar />}
          <div className="block sm:flex">
            {isBiggerThanSm ? <SideMenu hasTopBar={!!demand} /> : <Navbar />}
            <div
              className={twMerge(
                'h-[calc(100vh-56px)] w-full flex-1 overflow-y-auto sm:h-[100vh] [&>div]:max-w-[1200px] [&>div]:px-[10px] [&>div]:pb-[40px] [&>div]:pt-[10px] [&>div]:xs:px-[40px] [&>div]:sm:mx-auto [&>div]:sm:my-0 [&>div]:sm:w-[85%] [&>div]:sm:px-0 [&>div]:sm:py-[30px] [&>div]:sm:pb-[70px]',
                classNames({
                  'h-[calc(100vh-56px-64px)] xs:h-[calc(100vh-56px-40px)] sm:h-[calc(100vh-40px)]':
                    !!demand,
                }),
              )}
            >
              <AppRoutes />
            </div>
          </div>
        </>
      );

    // public pages
    return (
      <div className="block">
        {hasPublicBar && <PublicBar />}
        <div className={classNames({ 'h-[calc(100vh-70px)] overflow-y-auto': hasPublicBar })}>
          <AppRoutes />
        </div>
      </div>
    );
  };

  return (
    <>
      {customRoutes()}
      <ToastGroup content={notification} />
      <Loader />
      <Snackbar />
      <PinnedNotice />
    </>
  );
};

export default App;
