import { InputHTMLAttributes } from 'react';
import RadioContext from 'src/context/RadioContext';

/**
 * usage note:
 * this component should be only used as a child of RadioGroup.
 */

type Props = InputHTMLAttributes<HTMLInputElement> & {
  value: string;
  label?: string;
};

const Radio = ({ value, label, ...props }: Props) => (
  <RadioContext.Consumer>
    {({ name, onChange, ref }) => (
      <div className="flex w-fit items-center">
        <input
          id={value}
          value={value}
          name={name}
          onChange={onChange}
          ref={ref}
          {...props}
          type="radio"
          className="m-0 h-[16px] w-[16px] cursor-pointer appearance-none rounded-[50%] border-[1px] border-solid border-dark-500 transition-all duration-100 ease-linear checked:border-[6px] checked:border-turquoise disabled:border-dark-500/30 disabled:checked:border-[6px] disabled:checked:border-turquoise/30 dark:border-grey-200 dark:checked:border-aqua dark:disabled:border-grey-200/30 dark:disabled:checked:border-aqua/30"
        />
        {label && (
          <label className="cursor-pointer pl-[10px] text-[14px]" htmlFor={value}>
            {label}
          </label>
        )}
      </div>
    )}
  </RadioContext.Consumer>
);

export default Radio;
