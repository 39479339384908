import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import IcDownloadApp from 'src/image/downloadapp-bitop.png';
import IcHero from 'src/image/hero-g.png';
import { RootState } from 'src/redux/store';
import Bar from './component/Bar';
import Footer from './component/Footer';

const LandingAsk = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { FeatureSecure, FeaturePrivate, FeatureReserves } = useContext(ThemeContext).image;
  const { isLogin } = useSelector((state: RootState) => state.auth);

  return (
    <div>
      <Bar />
      <div className="px-[15px] py-0 xs:mx-auto xs:my-0 xs:w-[85%] xs:max-w-[1200px]">
        <div className="xs:min-h-[484px] xs:bg-[url('src/image/hero-g.png')] xs:bg-[length:484px] xs:bg-right-top xs:bg-no-repeat lg:min-h-[712px] lg:bg-[length:auto_712px]">
          <div className="max-w-[491px] pt-[20px] text-[56px] font-bold leading-[1.2]">
            {t('landing.heading')}
          </div>
          <div className="pb-[40px] pt-[20px] text-[24px] font-bold leading-[1.4]">
            {t('landing.desc.heroDesc')}
          </div>
          <div className="flex w-full flex-col items-center xs:block">
            <Button
              className="mx-auto my-0 w-[180px]"
              size="large"
              onClick={() => (isLogin ? navigate(Page.Trade) : navigate(Page.SignIn))}
            >
              {t('landing.act.startNow')}
            </Button>
          </div>
          <div className="text-center xs:hidden">
            <img className="w-full" src={IcHero} />
          </div>
        </div>
        <div className="mt-[70px] flex flex-col xs:flex-row xs:justify-around">
          <div className="flex flex-col items-center py-[70px] text-center xs:max-w-[272px] xs:px-[40px] lg:max-w-[400px]">
            <img className="w-[100px]" src={FeatureSecure} />
            <div className="text-[24px] font-bold leading-[1.4]">
              {t('landing.desc.featureSecure')}
            </div>
            <p className="text-[16px] leading-[1.5]">{t('landing.desc.featureDesc1')}</p>
          </div>
          <div className="flex flex-col items-center py-[70px] text-center xs:max-w-[272px] xs:px-[40px] lg:max-w-[400px]">
            <img className="w-[100px]" src={FeaturePrivate} />
            <div className="text-[24px] font-bold leading-[1.4]">
              {t('landing.desc.featurePrivate')}
            </div>
            <p className="text-[16px] leading-[1.5]">{t('landing.desc.featureDesc2')}</p>
          </div>
          <div className="flex flex-col items-center py-[70px] text-center xs:max-w-[272px] xs:px-[40px] lg:max-w-[400px]">
            <img className="w-[100px]" src={FeatureReserves} />
            <div className="text-[24px] font-bold leading-[1.4]">
              {t('landing.desc.featureReserves')}
            </div>
            <p className="text-[16px] leading-[1.5]">{t('landing.desc.featureDesc3')}</p>
          </div>
        </div>
        <div className="my-[70px] flex flex-col sm:min-h-[375px] sm:items-start sm:bg-[url('src/image/downloadapp-bitop.png')] sm:bg-[length:375px] sm:bg-right-top sm:bg-no-repeat lg:min-h-[551px] lg:bg-[length:auto_551px]">
          <div className="text-[42px] font-bold leading-[1.2]">{t('landing.desc.appTitle')}</div>
          <div className="pb-[40px] pt-[20px] text-[24px] font-bold leading-[1.4]">
            {t('landing.desc.appDesc')}
          </div>
          <Button
            className="mt-[30px]"
            appearance="text"
            onClick={() => (location.href = '/download/ios')}
          >
            {t('landing.act.downloadIos')}
          </Button>
          <Button
            className="mt-[30px]"
            appearance="text"
            onClick={() => (location.href = '/download/android/bitop.apk')}
          >
            {t('landing.act.downloadAndroid')}
          </Button>
          <img className="w-full sm:hidden" src={IcDownloadApp} />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default LandingAsk;
