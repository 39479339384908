import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { RootState } from 'src/redux/store';
import { bnFormat } from 'src/util/bigNumber';

const TopBar = () => {
  const { t } = useTranslation();
  const { demand } = useSelector((state: RootState) => state.ui);

  return (
    <div
      className={twMerge(
        'flex h-0 items-center justify-center bg-black-500 text-center text-[20px] text-white transition-all duration-500 dark:bg-dark-500',
        classNames({
          'box-border h-[64px] px-[30px] py-[8px] sm:h-[40px]': !!demand,
        }),
      )}
    >
      {demand && (
        <div>
          {t('message.demand1')}{' '}
          <span className="font-bold">
            {t('message.demand2', {
              base: demand.base.toUpperCase(),
              amount: bnFormat(demand.amount),
            })}
          </span>{' '}
          {t('message.demand3')}
        </div>
      )}
    </div>
  );
};

export default TopBar;
