import bankAccountEndpoint from 'src/api/bankAccountEndpoint';
import bankEndpoint from 'src/api/bankEndpoint';
import meEndpoint from 'src/api/meEndpoint';
import { PostBankAccountRequest } from 'src/model/Api';
import { setAccountInfo } from 'src/redux/authSlice';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';

export const getBank = async () => {
  try {
    dispatch(startWaiting());
    const res = await bankEndpoint.getBankList();

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const createBankAccount = async (data: PostBankAccountRequest) => {
  try {
    dispatch(startWaiting());
    const res = await bankAccountEndpoint.createBankAccount(data);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getAccountInfo = async () => {
  try {
    dispatch(startWaiting());
    const bankAccount = await bankAccountEndpoint.getBankAccountList();

    return bankAccount.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const deleteBankAccount = async (id: string, showLoading = true) => {
  try {
    if (showLoading) dispatch(startWaiting());
    await bankAccountEndpoint.deleteBankAccount(id);
  } finally {
    if (showLoading) dispatch(finishWaiting());
  }
};

export const updatePassword = async (originPassword: string, newPassword: string) => {
  try {
    dispatch(startWaiting());
    await meEndpoint.patchMe({
      originalPassword: originPassword,
      password: newPassword,
    });
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateSlug = async (slug: string) => {
  try {
    dispatch(startWaiting());
    await meEndpoint.patchMe({
      slug,
    });
    const ownInfoRes = await meEndpoint.getOwnInfo();
    dispatch(setAccountInfo(ownInfoRes.data));
  } finally {
    dispatch(finishWaiting());
  }
};
