export enum Page {
  Landing = '/',
  SignIn = '/auth/login',
  Register = '/auth/register',
  ForgetPassword = '/auth/forget',
  Verification = '/auth/verification',
  TermsOfUse = '/tou',
  Privacy = '/privacy',
  Trade = '/trade',
  MyTrade = '/trade/my-trade',
  Trading = '/trade/trading',
  TradingBatch = '/trade/trading/batch',
  TradingConfirm = '/trade/trading/confirm',
  TradingBatchConfirm = '/trade/trading/batch/confirm',
  Order = '/order',
  OrderHistory = '/order/history',
  Property = '/property',
  Deposit = '/property/deposit',
  Withdrawal = '/property/withdrawal',
  WithdrawalSuccess = '/property/withdrawal/success',
  AddressManagement = '/property/withdrawal/management',
  Record = '/property/records',
  Commission = '/commission',
  Account = '/account',
  AccountBatchConfirm = '/account/batch/confirm',
  EnableTfa = '/account/enable-tfa',
  Agency = '/agency',
  Report = '/report',
  ReportCommission = '/commission-report',
  ParentRebate = '/parent-rebate',
  AgentRebate = '/agent-rebate',
  Support = '/support',
  ContactUs = '/support/contact-us',
  CreateAccount = '/account/create-account',
  Announcement = '/announcement',
  SignOut = '/auth/logout',
  OrderAll = '/order/all',
  UserAsk = '/user-ask',
  UserBid = '/user-bid',
  UserAdmin = '/user-admin',
  Home = '/home',
  Fill = '/fill',
  Setting = '/setting',
  DepositRecord = '/deposit',
  WithdrawalRecord = '/withdrawal',
  Bank = '/bank',
  Message = '/message',
  MessageCanned = '/message-canned',
  AskerLimit = '/asker-limit',
  Balance = '/balance',
}

// for seller and buyer site which has sideMenu & navbar
export const PrivatePage = [
  Page.Trade,
  Page.Order,
  Page.Property,
  Page.Account,
  Page.EnableTfa,
  Page.Agency,
  Page.Report,
  Page.ReportCommission,
  Page.Support,
  Page.Announcement,
  Page.Commission,
];

// for which has publicBar
export const PageWithPublicBar = [
  Page.SignIn,
  Page.Register,
  Page.ForgetPassword,
  Page.Verification,
];
