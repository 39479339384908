import { GetCoinsParams, GetCoinsResponse, PutCoinRequest, PutCoinResponse } from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const listCoins = async (params?: GetCoinsParams) => {
  try {
    return await http.authGet<GetCoinsResponse, GetCoinsParams>('coins', { params });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const updateCoin = async (id: string, data: PutCoinRequest) => {
  try {
    return await http.authPut<PutCoinResponse, PutCoinRequest>(`coins/${id}`, { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  listCoins,
  updateCoin,
};
