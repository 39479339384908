import statementEndpoint from 'src/api/statementEndpoint';
import {
  setCommission,
  setRebateStatementKids,
  setRebateStatementMe,
  setRebateStatementMembers,
} from 'src/redux/rebateSlice';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';

export const loadStatements = async (begin: string, end: string) => {
  try {
    dispatch(startWaiting());
    const res = await statementEndpoint.getStatements({ begin, end });

    dispatch(setRebateStatementMe(res.data.me));
    dispatch(setRebateStatementKids(res.data.kids));
    if (res.data.members) dispatch(setRebateStatementMembers(res.data.members));
  } finally {
    dispatch(finishWaiting());
  }
};

export const loadCommissions = async (begin: string, end: string) => {
  try {
    dispatch(startWaiting());
    const res = await statementEndpoint.getStatementsCommission({ begin, end });

    dispatch(setCommission(res.data.kids));
  } finally {
    dispatch(finishWaiting());
  }
};
