import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Checkbox from 'src/component/Checkbox';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import { Page } from 'src/constant/Page';
import useQuery from 'src/hook/useQuery';
import { RegistrationForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { signup } from 'src/service/authService';

const Register = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasRead, setHasRead] = useState<boolean>(false);
  const methods = useForm<RegistrationForm>();
  const query = useQuery<{ invitation?: string }>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHasRead(event.target.checked);
  };

  const onClickSignIn = () => {
    navigate(Page.SignIn);
  };

  const onSubmit = (data: RegistrationForm) => {
    if (data.password !== data.confirmPassword) {
      methods.setError('confirmPassword', {}, { shouldFocus: true });

      return;
    }
    signup({
      email: data.email,
      password: data.password,
      mobile: data.mobile,
      slug: data.slug === '' ? undefined : data.slug,
      invitation: data.invitation === '' ? undefined : data.invitation,
    })
      .then(() =>
        navigate(Page.Verification, { state: { email: data.email, password: data.password } }),
      )
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Form
      className="px-[15px] pb-10 pt-[10px] xs:mx-auto xs:w-[560px] xs:px-0 xs:pb-[70px] md:w-[640px]"
      methods={methods}
      onSubmit={onSubmit}
    >
      <div className="text-[32px] font-bold">{t('register.desc.register')}</div>
      <div className="mt-[15px] flex items-center gap-2 text-sm">
        <div>{t('register.heading')}</div>
        <Button appearance="text" onClick={onClickSignIn} type="button" data-testid="signIn-btn">
          {t('register.act.signIn')}
        </Button>
      </div>
      <div className="mt-[25px]">
        <FormInput
          name="mobile"
          label={t('register.desc.mobile')}
          type="number"
          data-testid="mobile-input"
          asterisked
          required
        />
      </div>
      <div className="mt-[25px]">
        <FormInput
          name="email"
          label={t('register.desc.emailAddress')}
          type="email"
          data-testid="email-input"
          asterisked
          required
        />
      </div>
      <div className="mt-[25px]">
        <FormInput
          name="password"
          label={t('register.desc.password')}
          type="password"
          data-testid="password-input"
          asterisked
          required
        />
      </div>
      <div className="mt-[25px]">
        <FormInput
          name="confirmPassword"
          label={t('register.desc.passwordConfirmation')}
          type="password"
          data-testid="confirmpassword-input"
          asterisked
          required
        />
      </div>
      <div className="mt-[25px]">
        <FormInput
          name="slug"
          label={t('register.desc.slug')}
          type="string"
          data-testid="slug-input"
        />
      </div>
      <div className="mt-[25px]">
        <FormInput
          name="invitation"
          label={t('register.desc.invitation')}
          type="string"
          data-testid="invitation-input"
          defaultValue={query.invitation}
        />
      </div>
      <div className="mt-[30px]">
        <Checkbox
          checked={hasRead}
          onChange={handleChange}
          label={t('register.desc.readAndAccept')}
          data-testid="read-checkbox"
          asterisked
        />
      </div>
      <div className="mt-[40px] text-right">
        <Button size="large" type="submit" disabled={!hasRead} data-testid="submit-btn">
          {t('register.act.submit')}
        </Button>
      </div>
    </Form>
  );
};

export default Register;
