import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Pagination from 'src/component/Pagination';
import DateFilter from 'src/component/RoundDateFilter';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import Switcher from 'src/component/Switcher';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import useQuery from 'src/hook/useQuery';
import { DepositRecord } from 'src/model/Deposit';
import { WithdrawalRecord } from 'src/model/Withdrawal';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { cancelWithdrawal, getRecordData } from 'src/service/propertyService';
import CancelModal from './component/CancelModal';
import DepositCard from './component/DepositCard';
import DepositTable from './component/DepositTable';
import VerifyModal from './component/VerifyModal';
import WithdrawalCard from './component/WithdrawalCard';
import WithdrawalTable from './component/WithdrawalTable';

const Record = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { record: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const { offset, limit, setCount, paginationProps } = usePagination({ defaultPage: filter.page });
  const { begin, end, dateFilterProps } = useDateFilter({
    defaultFrom: filter.begin,
    defaultTo: filter.end,
  });
  const { IcEmpty } = useContext(ThemeContext).image;
  const navigate = useNavigate();
  const { type: queryType } = useQuery<{ type: string }>();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const [coinType, setCoinType] = useState<string>(filter.coin ?? 'all');
  const [refresh, setRefresh] = useState<boolean>(false);
  const [cancelId, setCancelId] = useState<string>();
  const [verifyWithdrawalData, setVerifyWithdrawalData] = useState<WithdrawalRecord>();
  const [transactionType, setTransactionType] = useState<'deposit' | 'withdrawal'>(
    (filter.type as 'deposit' | 'withdrawal') ??
      (queryType === 'deposit' || queryType === 'withdrawal' ? queryType : 'deposit'),
  );
  const [depositRecordArray, setDepositRecordArray] = useState<Array<DepositRecord>>([]);
  const [withdrawalRecordArray, setWithdrawalRecordArray] = useState<Array<WithdrawalRecord>>([]);

  useEffect(() => {
    getRecordData(transactionType, coinType !== 'all' ? coinType : undefined, {
      begin,
      end,
      offset,
      limit,
    })
      .then((res) => {
        if (res.transactionType === 'deposit') setDepositRecordArray(res.data);
        else setWithdrawalRecordArray(res.data);

        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [coinType, transactionType, begin, end, offset, limit, refresh]);

  const navigateCallback = (record: DepositRecord | WithdrawalRecord) => {
    if (transactionType === 'deposit') navigate(`${Page.Deposit}/${record.id}`);
    else navigate(`${Page.Withdrawal}/${record.id}`);
  };

  const onCancelWithdrawal = (id: string) => {
    setCancelId(id);
  };

  const onCancelConfirm = () => {
    if (!cancelId) return;
    setCancelId(undefined);
    cancelWithdrawal(cancelId)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onVerifyClick = (data: WithdrawalRecord) => {
    setVerifyWithdrawalData(data);
  };

  return (
    <div>
      <BackButton />
      <div className="mb-[15px] mt-[10px] text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('record.heading')}
      </div>
      <div className="h-[40px]">
        <Switcher
          labels={[t('record.act.deposit'), t('record.act.withdrawal')]}
          defaultIndex={transactionType === 'deposit' ? 0 : 1}
          onChange={(index) => {
            setTransactionType(index === 0 ? 'deposit' : 'withdrawal');
            paginationProps.setPage(1);
          }}
        />
      </div>
      <div className="mt-[15px] rounded-[4px] bg-white px-[15px] pb-[10px] pt-[25px] text-[14px] dark:bg-black-800 md:px-[40px] md:pb-[15px]">
        <div className="flex flex-col xs:flex-row xs:gap-[36px]">
          <DateFilter onChange={() => paginationProps.setPage(1)} {...dateFilterProps} />
          <div className="mb-[15px] flex grow flex-row items-center gap-[12px] text-[16px] text-grey-500 dark:text-grey-700">
            {t('record.desc.coin')}
            <div className="max-w-[270px] flex-1">
              <Select
                defaultValue={filter.coin ?? 'all'}
                onChange={(v) => {
                  setCoinType(v);
                  paginationProps.setPage(1);
                }}
              >
                <SelectOption value="all">All</SelectOption>
                <SelectOption value="usdt">USDT</SelectOption>
              </Select>
            </div>
          </div>
        </div>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        {((transactionType === 'deposit' && depositRecordArray.length === 0) ||
          (transactionType === 'withdrawal' && withdrawalRecordArray.length === 0)) && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {transactionType === 'deposit' &&
          depositRecordArray.length > 0 &&
          (isBiggerThanMd ? (
            <DepositTable data={depositRecordArray} navigateCallback={navigateCallback} />
          ) : (
            <DepositCard data={depositRecordArray} navigateCallback={navigateCallback} />
          ))}
        {transactionType === 'withdrawal' &&
          withdrawalRecordArray.length > 0 &&
          (isBiggerThanMd ? (
            <WithdrawalTable
              data={withdrawalRecordArray}
              navigateCallback={navigateCallback}
              onCancel={onCancelWithdrawal}
              onVerifyClick={onVerifyClick}
            />
          ) : (
            <WithdrawalCard
              data={withdrawalRecordArray}
              navigateCallback={navigateCallback}
              onCancel={onCancelWithdrawal}
              onVerifyClick={onVerifyClick}
            />
          ))}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
      <CancelModal
        open={!!cancelId}
        onClose={() => setCancelId(undefined)}
        onSubmit={onCancelConfirm}
      />
      <VerifyModal
        open={!!verifyWithdrawalData}
        onClose={() => setVerifyWithdrawalData(undefined)}
        withdrawalData={verifyWithdrawalData}
        refresh={() => setRefresh(!refresh)}
      />
    </div>
  );
};

export default Record;
