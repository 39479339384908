import { t } from 'i18next';
import {
  GetUserRelationResponse,
  GetUserResponse,
  GetUsersKidsResponse,
  GetUsersParams,
  GetUsersResponse,
  PostUsersRequest,
  PutUserRequest,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getUsers = async (params?: GetUsersParams) => {
  try {
    return await http.authGet<GetUsersResponse>('users', { params });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getTheUser = async (id: string) => {
  try {
    return await http.authGet<GetUserResponse>(`users/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getUserRelation = async (id: string) => {
  try {
    return await http.authGet<GetUserRelationResponse>(`users/${id}/relation`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const postUsers = async (data: PostUsersRequest) => {
  try {
    return await http.authPost('users', { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const putUser = async (id: string, data: PutUserRequest) => {
  try {
    return await http.authPut<GetUserResponse, PutUserRequest>(`users/${id}`, { data });
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 409 && error.response.data.code === 0)
      throw t('error.user.agentConflict');
    throw defaultErrorMessage(error);
  }
};

const patchUserApprove = async (id: string) => {
  try {
    await http.authPatch(`users/${id}/approve`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const deleteUser = async (id: string) => {
  try {
    await http.authDelete(`users/${id}`);
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 422 && error.response.data.code === 0)
      throw t('error.user.userUndeletable');
    throw defaultErrorMessage(error);
  }
};

const blockUser = async (id: string) => {
  try {
    await http.authPatch(`users/${id}/block`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const unblockUser = async (id: string) => {
  try {
    await http.authPatch(`users/${id}/unblock`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const deactivateUserOtp = async (id: string, method: string) => {
  try {
    await http.authDelete(`users/${id}/otp/${method}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getUsersKids = async () => {
  try {
    return await http.authGet<GetUsersKidsResponse>('users/kids');
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  putUser,
  getUsers,
  getTheUser,
  getUserRelation,
  postUsers,
  patchUserApprove,
  deleteUser,
  blockUser,
  unblockUser,
  deactivateUserOtp,
  getUsersKids,
};
