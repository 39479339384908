import commissionEndpoint from 'src/api/commissionEndpoint';
import { PaginationParams } from 'src/model/Pagination';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting, updateFilter } from 'src/redux/uiSlice';
import { getPage } from 'src/util/paginationHelper';
import { toCsvFile } from 'src/util/toCsv';

export const getCommissionById = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await commissionEndpoint.getCommissionsId(id);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getCommissionList = async (paginationParams?: PaginationParams, csv = false) => {
  try {
    dispatch(startWaiting());
    const res = await commissionEndpoint.getCommissions(
      {
        ...paginationParams,
        order: 'desc',
      },
      csv ? { Accept: 'text/csv' } : undefined,
    );

    dispatch(
      updateFilter({
        key: 'adminCommission',
        filter: {
          id: undefined,
          begin: paginationParams?.begin,
          end: paginationParams?.end,
          limit: paginationParams?.limit,
          page: getPage(paginationParams?.offset, paginationParams?.limit),
        },
      }),
    );

    if (csv) toCsvFile('commission.csv', res.data as unknown as string);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getCommissionAdminById = async (id: string, save?: boolean) => {
  try {
    dispatch(startWaiting());
    const res = await commissionEndpoint.getCommissionsIdAdmin(id);

    save && dispatch(updateFilter({ key: 'adminCommission', filter: { id } }));

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};
