export enum Severity {
  Clear = 'clear',
  Warning = 'warning',
  Error = 'error',
}

export enum Type {
  FillCreated = 'fill-created',
  FillReceiptUploaded = 'fill-receipt-uploaded',
  FillTotalSent = 'fill-total-sent',
  FillTotalReceived = 'fill-total-received',
  FillTotalUnsentByAdmin = 'fill-total-unsent-by-admin',
  FillRejected = 'fill-rejected',
  FillCanceled = 'fill-canceled',
  OrderCanceledByAdmin = 'order-canceled-by-admin',
  DepositReceived = 'deposit-received',
  WithdrawalVerified = 'withdrawal-verified',
  AssetFreezing = 'asset-freezing',
  AssetFrozen = 'asset-frozen',
  InsufficientCrypto = 'insufficient-crypto',
  InsufficientOnChainFeeCrypto = 'insufficient-on-chain-fee-crypto',
}
