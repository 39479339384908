import { t } from 'i18next';
import {
  GetBankAccountResponse,
  PostBankAccountRequest,
  PostBankAccountResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getBankAccountList = async () => {
  try {
    return await http.authGet<GetBankAccountResponse>('bank-accounts');
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const createBankAccount = async (data: PostBankAccountRequest) => {
  try {
    return await http.authPost<PostBankAccountResponse, PostBankAccountRequest>('bank-accounts', {
      data,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const deleteBankAccount = async (id: string) => {
  try {
    return await http.authDelete(`bank-accounts/${id}`);
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 409 && error.response.data.code === 0)
      throw t('error.bankAccount.undeletable');
    if (error.response?.status === 410 && error.response.data.code === 0)
      throw t('error.bankAccount.alreadyDeleted');
    throw defaultErrorMessage(error);
  }
};

export default {
  getBankAccountList,
  createBankAccount,
  deleteBankAccount,
};
