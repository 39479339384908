import { useTranslation } from 'react-i18next';
import Radio from 'src/component/Radio';
import { BankAccount } from 'src/model/Bank';

type Props = {
  name: string;
  accountArray: BankAccount[];
  defaultIndex?: number;
  type: 'bank' | 'alipay';
};

const PaymentCard = ({ name, accountArray, defaultIndex, type }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      {accountArray
        .filter((v) => v.paymentMethodType === type)
        .map((v, index) => (
          <div key={v.id}>
            <div className="flex px-0 py-[16px]">
              <div className="mr-[8px] mt-[1px] flex flex-row items-start justify-center pt-[3px] md:m-0 md:w-[40px] md:items-center md:pt-0">
                <Radio value={name + ',' + v.id} defaultChecked={index === defaultIndex} />
              </div>
              <label htmlFor={name + ',' + v.id} className="text-[14px] leading-[21px]">
                {v.paymentMethodType === 'bank' && (
                  <>
                    <div className="flex gap-[15px]">
                      <div className="font-bold text-grey-700 dark:text-grey-300">
                        {t('trading.desc.accountName')}
                      </div>
                      <div>{v.name}</div>
                    </div>
                    <div className="flex gap-[15px]">
                      <div className="font-bold text-grey-700 dark:text-grey-300">
                        {t('trading.desc.bank')}
                      </div>
                      <div>{v.bankName}</div>
                    </div>
                    <div className="flex gap-[15px]">
                      <div className="font-bold text-grey-700 dark:text-grey-300">
                        {t('trading.desc.bankAccount')}
                      </div>
                      <div>{v.account}</div>
                    </div>
                    <div className="flex gap-[15px]">
                      <div className="font-bold text-grey-700 dark:text-grey-300">
                        {t('trading.desc.bankProvinceCity')}
                      </div>
                      <div>
                        {v.province}/{v.city}
                      </div>
                    </div>
                    <div className="flex gap-[15px]">
                      <div className="font-bold text-grey-700 dark:text-grey-300">
                        {t('trading.desc.bankBranchName')}
                      </div>
                      <div>{v.bankBranchName}</div>
                    </div>
                  </>
                )}
                {v.paymentMethodType === 'alipay' && (
                  <>
                    <div className="flex gap-[15px]">
                      <div className="font-bold text-grey-700 dark:text-grey-300">
                        {t('trading.desc.alipayAccountName')}
                      </div>
                      <div>{v.name}</div>
                    </div>
                    <div className="flex gap-[15px]">
                      <div className="font-bold text-grey-700 dark:text-grey-300">
                        {t('trading.desc.alipayAccount')}
                      </div>
                      <div>{v.account}</div>
                    </div>
                    <div className="flex gap-[15px]">
                      <div className="font-bold text-grey-700 dark:text-grey-300">
                        {t('trading.desc.alipayQRCode')}
                      </div>
                      <div>
                        {v.imageUrl
                          ? t('trading.desc.alipayQRCodeUploaded')
                          : t('trading.desc.alipayQRCodeUnuploaded')}
                      </div>
                    </div>
                  </>
                )}
              </label>
            </div>
            <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
          </div>
        ))}
    </div>
  );
};

export default PaymentCard;
