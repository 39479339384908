import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Tabs from 'src/component/Tabs';
import { RootState } from 'src/redux/store';
import { bn } from 'src/util/bigNumber';
import Statement from './Statement';

const ReportWithTabs = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<number>(0);
  const { me, kids, members } = useSelector((rootState: RootState) => rootState.rebate);
  const labels = useMemo(() => {
    const res = [t('report.desc.me')];
    if (kids && kids.length > 0) res.push(t('report.desc.kids'));
    if (members) res.push(t('report.desc.members'));

    return res;
  }, [kids, members, t]);

  return (
    <>
      <Tabs labels={labels} onChange={(i) => setTab(i)} defaultIndex={0} />
      <div className="mt-[15px] rounded-[4px] bg-white px-[15px] py-[25px] dark:bg-black-700 xs:px-[40px]">
        {tab === 0 && me && <Statement info={me} showCoin />}
        {tab === 1 &&
          kids &&
          kids.map((v, i) => <Statement key={v.userId} info={v} showEmail showCoin={i === 0} />)}
        {tab === 2 && members && (
          <>
            <div className="mb-[10px] text-[13px] font-bold text-dark-500 dark:text-grey-300">
              {t('report.desc.memberTotal')}
            </div>
            <Statement
              info={{
                email: 'xx',
                userId: 'xx',
                askAmount: members
                  .reduce((sum, current) => sum.plus(bn(current.askAmount)), bn(0))
                  .toFormat(),
                rebateAmount: members
                  .reduce((sum, current) => sum.plus(bn(current.rebateAmount)), bn(0))
                  .toFormat(),
              }}
              showCoin
              showDivider={false}
            />
            <div className="mx-0 my-[25px] h-[1px] bg-light-400 dark:bg-dark-500" />
            <div className="mb-[10px] text-[13px] font-bold text-dark-500 dark:text-grey-300">
              {t('report.desc.memberList')}
            </div>
            {members.map((v, i) => (
              <Statement key={v.userId} info={v} showEmail showCoin={i === 0} />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default ReportWithTabs;
