import { intervalToDuration } from 'date-fns';

export const calibrateBeginDate = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);

  return newDate;
};

export const calibrateEndDate = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);

  return newDate;
};

export const formatDuration = (end: number) => {
  const interval = intervalToDuration({ start: 0, end });

  return [interval.hours, interval.minutes, interval.seconds]
    .map((v) => String(v).padStart(2, '0'))
    .join(':');
};

export const getYesterdayDate = () => {
  const newDate = new Date();
  newDate.setDate(newDate.getDate() - 1);

  return newDate;
};

export const getStartOfMonth = () => {
  const newDate = new Date();

  return new Date(newDate.getFullYear(), newDate.getMonth(), 1);
};
