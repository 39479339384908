import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import DatetimePicker from 'src/component/DatetimePicker';
import Radio from 'src/component/Radio';
import RadioGroup from 'src/component/RadioGroup';
import { MediaQuery } from 'src/constant/Media';
import {
  calibrateBeginDate,
  calibrateEndDate,
  getStartOfMonth,
  getYesterdayDate,
} from 'src/util/datetimeHelper';
import QueryModal from './QueryModal';

type Props = {
  begin: string;
  setBegin: (v: string) => void;
  end: string;
  setEnd: (v: string) => void;
};

const Interval = ({ begin, setBegin, end, setEnd }: Props) => {
  const { t } = useTranslation();
  const isBiggerThanXsm = useMediaPredicate(MediaQuery.Xsm);
  const [open, setOpen] = useState<boolean>(false);
  const radioValue = useMemo(() => {
    if (
      begin === calibrateBeginDate(new Date()).toISOString() &&
      end === calibrateEndDate(new Date()).toISOString()
    )
      return 'today';
    if (
      begin === calibrateBeginDate(getYesterdayDate()).toISOString() &&
      end === calibrateEndDate(getYesterdayDate()).toISOString()
    )
      return 'yesterday';
    if (
      begin === calibrateBeginDate(getStartOfMonth()).toISOString() &&
      end === calibrateEndDate(new Date()).toISOString()
    )
      return 'month';

    return 'custom';
  }, [begin, end]);

  const onSubmit = (from: string, to: string) => {
    setBegin(from);
    setEnd(to);
  };

  return (
    <>
      <div className="mb-[17px] mt-[20px] flex gap-[30px]">
        <div className="font-bold">{t('report.desc.interval')}</div>
        <RadioGroup
          name="interval"
          className="flex flex-row gap-[23px]"
          onChange={(e) => {
            const value = e.target.value;
            if (value === 'today') {
              setBegin(calibrateBeginDate(new Date()).toISOString());
              setEnd(calibrateEndDate(new Date()).toISOString());
            } else if (value === 'yesterday') {
              setBegin(calibrateBeginDate(getYesterdayDate()).toISOString());
              setEnd(calibrateEndDate(getYesterdayDate()).toISOString());
            } else if (value === 'month') {
              setBegin(calibrateBeginDate(getStartOfMonth()).toISOString());
              setEnd(calibrateEndDate(new Date()).toISOString());
            }
          }}
        >
          <Radio value="today" label={t('report.desc.today')} checked={radioValue === 'today'} />
          <Radio
            value="yesterday"
            label={t('report.desc.yesterday')}
            checked={radioValue === 'yesterday'}
          />
          <Radio
            value="month"
            label={t('report.desc.thisMonth')}
            checked={radioValue === 'month'}
          />
        </RadioGroup>
      </div>
      {isBiggerThanXsm ? (
        <div className="flex gap-[30px]">
          <div className="flex w-[265px] items-center gap-[10px]">
            <div className="text-[14px] text-dark-500 dark:text-grey-300">
              {t('report.desc.from')}
            </div>
            <DatetimePicker
              value={begin}
              onChange={(e) => {
                if (!e) return;
                if (new Date(e) > new Date(end)) setEnd(e);
                setBegin(e);
              }}
            />
          </div>
          <div className="flex w-[265px] items-center gap-[10px]">
            <div className="text-[14px] text-dark-500 dark:text-grey-300">
              {t('report.desc.to')}
            </div>
            <DatetimePicker
              value={end}
              onChange={(e) => {
                if (!e) return;
                if (new Date(e) < new Date(end)) setBegin(e);
                setEnd(e);
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className="mx-0 my-[15px] flex cursor-pointer justify-between rounded-[4px] border-[1px] border-solid border-light-500 px-[8px] py-[3px] dark:border-dark-500"
          onClick={() => setOpen(true)}
        >
          <div>{format(new Date(begin), 'yyyy/MM/dd HH:mm')}</div>
          <div>—</div>
          <div>{format(new Date(end), 'yyyy/MM/dd HH:mm')}</div>
        </div>
      )}
      <QueryModal
        open={open}
        onClose={() => setOpen(false)}
        defaultBegin={begin}
        defaultEnd={end}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default Interval;
