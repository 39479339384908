import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from 'src/component/BackButton';
import { Ui } from 'src/constant/Env';
import { ThemeContext } from 'src/context/ThemeContext';
import IcContactTelegram from 'src/image/ic-contact-telegram.svg';

const ContactUs = () => {
  const { t } = useTranslation();
  const { IcContactEmail } = useContext(ThemeContext).image;

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] text-[28px] font-[600] sm:mt-[20px] sm:text-[32px]">
        {t('contactUs.heading')}
      </div>
      <div className="mt-[20px]">
        <div className="mb-[20px] flex items-center gap-[25px] rounded-[4px]">
          <img className="h-[32px] w-[32px]" src={IcContactEmail} />
          <div>
            <div className="text-[16px] text-grey-700 dark:text-grey-300">
              {t('contactUs.desc.email')}
            </div>
            <a
              className="text-[16px] text-turquoise no-underline dark:text-aqua"
              href={
                Ui === 'seller' ? 'mailto:BITOP16881588@gmail.com' : 'mailto:maibee168520@gmail.com'
              }
              target="_blank"
              rel="noreferrer"
            >
              {Ui === 'seller' ? 'BITOP16881588@gmail.com' : 'maibee168520@gmail.com'}
            </a>
          </div>
        </div>
        <div className="mb-[20px] h-[1px] bg-light-200 dark:bg-dark-700" />
        <div className="mb-[20px] flex items-center gap-[25px] rounded-[4px]">
          <img className="h-[32px] w-[32px]" src={IcContactTelegram} />
          <div>
            <div className="text-[16px] text-grey-700 dark:text-grey-300">
              {t('contactUs.desc.telegram')}
            </div>
            <a
              className="text-[16px] text-turquoise no-underline dark:text-aqua"
              href={Ui === 'seller' ? 'https://t.me/BITOP1688' : 'https://t.me/maibee1688'}
              target="_blank"
              rel="noreferrer"
            >
              {Ui === 'seller' ? '@BITOP1688' : '@maibee1688'}
            </a>
          </div>
        </div>
        <div className="mb-[20px] h-[1px] bg-light-200 dark:bg-dark-700" />
        {Ui === 'seller' && (
          <>
            <div className="mb-[20px] flex items-center gap-[25px] rounded-[4px]">
              <img className="h-[32px] w-[32px]" src={IcContactTelegram} />
              <div>
                <div className="text-[16px] text-grey-700 dark:text-grey-300">
                  {t('contactUs.desc.orderInformation')}
                </div>
                <a
                  className="text-[16px] text-turquoise no-underline dark:text-aqua"
                  href="https://t.me/BITOP168888"
                  target="_blank"
                  rel="noreferrer"
                >
                  @BITOP168888
                </a>
              </div>
            </div>
            <div className="mb-[20px] h-[1px] bg-light-200 dark:bg-dark-700" />
          </>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
