import { useContext, useState } from 'react';
import { ThemeContext } from 'src/context/ThemeContext';
import Drawer from './Drawer';

const Navbar = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { IcMenu } = useContext(ThemeContext).image;

  return (
    <div className="box-border h-[56px] px-[15px] py-[10px]">
      <img className="cursor-pointer" src={IcMenu} onClick={() => setOpen(true)} />
      <Drawer open={open} onClose={() => setOpen(false)} />
    </div>
  );
};

export default Navbar;
