import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import { openSnackbar } from 'src/redux/uiSlice';
import { signout } from 'src/service/authService';

const AuthSignOut = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    signout({})
      .then(() => navigate(Page.SignIn))
      .catch((e) => {
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
        navigate(-1);
      });
  }, []);

  return <></>;
};

export default AuthSignOut;
