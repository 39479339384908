import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetConfigsResponse, GetExchangeRateResponse } from 'src/model/Api';
import { Rebate } from 'src/model/Rebate';

export type SettingState = {
  config: GetConfigsResponse | null;
  exchangeRate: GetExchangeRateResponse | null;
  bidPrice: string | null;
  parentRebatePercentage: string | null;
  agentRebate: Rebate[];
};

const initialState: SettingState = {
  config: null,
  exchangeRate: null,
  bidPrice: null,
  parentRebatePercentage: null,
  agentRebate: [],
};

export const settingSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state: SettingState, action: PayloadAction<GetConfigsResponse>) => {
      state.config = action.payload;
    },
    setExchangeRate: (state: SettingState, action: PayloadAction<GetExchangeRateResponse>) => {
      state.exchangeRate = action.payload;
    },
    setBidPrice: (state: SettingState, action: PayloadAction<string>) => {
      state.bidPrice = action.payload;
    },
    setParentRebatePercentage: (state: SettingState, action: PayloadAction<string>) => {
      state.parentRebatePercentage = action.payload;
    },
    setAgentRebate: (state: SettingState, action: PayloadAction<Rebate[]>) => {
      state.agentRebate = action.payload;
    },
  },
});

export const {
  setConfig,
  setExchangeRate,
  setBidPrice,
  setParentRebatePercentage,
  setAgentRebate,
} = settingSlice.actions;

export default settingSlice.reducer;
