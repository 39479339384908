import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import ListItem from './ListItem';
import Popover from './Popover';

type Props = { open: boolean; onClose: () => void; anchorEl: Element | null };

const MenuTrading = ({ open, onClose, anchorEl }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      className="!bg-white py-[5px] dark:!bg-black-700"
    >
      <ListItem onClick={() => navigate(Page.Trading)}>{t('trade.desc.oneTrade')}</ListItem>
      <ListItem onClick={() => navigate(Page.TradingBatch)}>{t('trade.desc.batchTrade')}</ListItem>
    </Popover>
  );
};

export default MenuTrading;
