import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { bn } from 'src/util/bigNumber';
import Statement from './Statement';

const ReportWithTabs = () => {
  const { t } = useTranslation();
  const { commission } = useSelector((rootState: RootState) => rootState.rebate);

  return (
    <>
      <div className="mt-[15px] rounded-[4px] bg-white px-[15px] py-[25px] dark:bg-black-700 xs:px-[40px]">
        {commission && (
          <>
            <div className="mb-[10px] text-[13px] font-bold text-dark-500 dark:text-grey-300">
              {t('reportCommission.desc.memberTotal')}
            </div>
            <Statement
              info={{
                email: 'xx',
                userId: 'xx',
                askAmount: commission
                  .reduce((sum, current) => sum.plus(bn(current.askAmount)), bn(0))
                  .toFormat(),
                commissionAmount: commission
                  .reduce((sum, current) => sum.plus(bn(current.commissionAmount)), bn(0))
                  .toFormat(),
              }}
              showCoin
              showDivider={false}
            />
            <div className="mx-0 my-[25px] h-[1px] bg-light-400 dark:bg-dark-500" />
            <div className="mb-[10px] text-[13px] font-bold text-dark-500 dark:text-grey-300">
              {t('reportCommission.desc.kids')}
            </div>
            {commission.map((v, i) => (
              <Statement key={v.userId} info={v} showEmail showCoin={i === 0} />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default ReportWithTabs;
