import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import { Page } from 'src/constant/Page';
import IcContact from 'src/image/ic-support-contact.svg';
import IcPrivacypolicy from 'src/image/ic-support-privacypolicy.svg';
import IcTermofuse from 'src/image/ic-support-termofuse.svg';

const Support = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] text-[28px] font-[600] sm:mt-[20px] sm:text-[32px]">
        {t('support.heading')}
      </div>
      <div className="mt-[28px] flex flex-col xs:flex-row xs:gap-[30px]">
        <div
          className="mb-[20px] flex cursor-pointer flex-col items-center rounded-[4px] bg-white dark:bg-black-800 xs:flex-1"
          onClick={() => navigate(Page.ContactUs)}
        >
          <img
            className="mb-[10px] mt-[40px] h-[36px] w-[36px] xs:h-[72px] xs:w-[72px]"
            src={IcContact}
          />
          <div className="mb-[40px]">{t('support.desc.contactUs')}</div>
        </div>
        <div
          className="mb-[20px] flex cursor-pointer flex-col items-center rounded-[4px] bg-white dark:bg-black-800 xs:flex-1"
          onClick={() => navigate(Page.TermsOfUse)}
        >
          <img
            className="mb-[10px] mt-[40px] h-[36px] w-[36px] xs:h-[72px] xs:w-[72px]"
            src={IcTermofuse}
          />
          <div className="mb-[40px]">{t('support.desc.terms')}</div>
        </div>
        <div
          className="mb-[20px] flex cursor-pointer flex-col items-center rounded-[4px] bg-white dark:bg-black-800 xs:flex-1"
          onClick={() => navigate(Page.Privacy)}
        >
          <img
            className="mb-[10px] mt-[40px] h-[36px] w-[36px] xs:h-[72px] xs:w-[72px]"
            src={IcPrivacypolicy}
          />
          <div className="mb-[40px]">{t('support.desc.privacyPolicy')}</div>
        </div>
      </div>
    </div>
  );
};

export default Support;
