import FeaturePrivateDark from 'src/image/feature-private-dark.svg';
import FeaturePrivateLight from 'src/image/feature-private-light.svg';
import FeatureReservesDark from 'src/image/feature-reserves-dark.svg';
import FeatureReservesLight from 'src/image/feature-reserves-light.svg';
import FeatureSecureDark from 'src/image/feature-secure-dark.svg';
import FeatureSecureLight from 'src/image/feature-secure-light.svg';
import IcAccountDark from 'src/image/ic-account-dark.svg';
import IcAccountLight from 'src/image/ic-account-light.svg';
import IcAddDark from 'src/image/ic-add-dark.svg';
import IcAddLight from 'src/image/ic-add-light.svg';
import IcAddSmallDark from 'src/image/ic-add-s-dark.svg';
import IcAddSmallLight from 'src/image/ic-add-s-light.svg';
import IcAgencyDark from 'src/image/ic-agency-dark.svg';
import IcAgencyLight from 'src/image/ic-agency-light.svg';
import IcAnnouncementDark from 'src/image/ic-announcement-dark.svg';
import IcAnnouncementLight from 'src/image/ic-announcement-light.svg';
import IcAnnouncementRedDark from 'src/image/ic-announcement-red-dark.svg';
import IcAnnouncementRedLight from 'src/image/ic-announcement-red-light.svg';
import IcAppStoreDark from 'src/image/ic-app-store-dark.svg';
import IcAppStoreLight from 'src/image/ic-app-store-light.svg';
import IcBackDark from 'src/image/ic-back-dark.svg';
import IcBackLight from 'src/image/ic-back-light.svg';
import IcBatchDark from 'src/image/ic-batch-dark.svg';
import IcBatchLight from 'src/image/ic-batch-light.svg';
import IcCalendarDark from 'src/image/ic-calendar-dark.svg';
import IcCalendarLight from 'src/image/ic-calendar-light.svg';
import IcClosePrimaryDark from 'src/image/ic-close-primary-dark.svg';
import IcClosePrimaryLight from 'src/image/ic-close-primary-light.svg';
import IcContactEmailDark from 'src/image/ic-contact-email-dark.svg';
import IcContactEmailLight from 'src/image/ic-contact-email-light.svg';
import IcCopyDark from 'src/image/ic-copy-dark.svg';
import IcCopyLight from 'src/image/ic-copy-light.svg';
import IcEditDark from 'src/image/ic-edit-dark.svg';
import IcEditLight from 'src/image/ic-edit-light.svg';
import IcEmptyDark from 'src/image/ic-empty-dark.svg';
import IcEmptyLight from 'src/image/ic-empty-light.svg';
import IcForwardDark from 'src/image/ic-forward-dark.svg';
import IcForwardLight from 'src/image/ic-forward-light.svg';
import IcGoMessageDark from 'src/image/ic-go-message-dark.svg';
import IcGoMessageLight from 'src/image/ic-go-message-light.svg';
import IcGooglePlayDark from 'src/image/ic-google-play-dark.svg';
import IcGooglePlayLight from 'src/image/ic-google-play-light.svg';
import IcMenuDark from 'src/image/ic-menu-dark.svg';
import IcMenuLight from 'src/image/ic-menu-light.svg';
import IcNoticeClearDark from 'src/image/ic-notice-clear-dark.svg';
import IcNoticeClearLight from 'src/image/ic-notice-clear-light.svg';
import IcNoticeErrorDark from 'src/image/ic-notice-error-dark.svg';
import IcNoticeErrorLight from 'src/image/ic-notice-error-light.svg';
import IcNoticeWarningDark from 'src/image/ic-notice-warning-dark.svg';
import IcNoticeWarningLight from 'src/image/ic-notice-warning-light.svg';
import IcOpenInNewDark from 'src/image/ic-open-in-new-dark.svg';
import IcOpenInNewLight from 'src/image/ic-open-in-new-light.svg';
import IcOrderDark from 'src/image/ic-order-dark.svg';
import IcOrderLight from 'src/image/ic-order-light.svg';
import IcPaginationNextDark from 'src/image/ic-pagination-next-dark.svg';
import IcPaginationNextLight from 'src/image/ic-pagination-next-light.svg';
import IcPaginationPrevDark from 'src/image/ic-pagination-prev-dark.svg';
import IcPaginationPrevLight from 'src/image/ic-pagination-prev-light.svg';
import IcPropertyDark from 'src/image/ic-property-dark.svg';
import IcPropertyLight from 'src/image/ic-property-light.svg';
import IcQrcodeDark from 'src/image/ic-qrcode-dark.svg';
import IcQrcodeLight from 'src/image/ic-qrcode-light.svg';
import IcRecordDark from 'src/image/ic-record-dark.svg';
import IcRecordLight from 'src/image/ic-record-light.svg';
import IcReportDark from 'src/image/ic-report-dark.svg';
import IcReportLight from 'src/image/ic-report-light.svg';
import IcSearchDark from 'src/image/ic-search-dark.svg';
import IcSearchLight from 'src/image/ic-search-light.svg';
import IcSupportDark from 'src/image/ic-support-dark.svg';
import IcSupportLight from 'src/image/ic-support-light.svg';
import IcThemeDark from 'src/image/ic-theme-dark.svg';
import IcThemeLight from 'src/image/ic-theme-light.svg';
import IcTradeDark from 'src/image/ic-trade-dark.svg';
import IcTradeLight from 'src/image/ic-trade-light.svg';

export enum Theme {
  Light = 'light',
  Dark = 'dark',
}

export const Image = {
  [Theme.Light]: {
    IcAdd: IcAddLight,
    IcAddSmall: IcAddSmallLight,
    IcAnnouncement: IcAnnouncementLight,
    IcAnnouncementRed: IcAnnouncementRedLight,
    IcTheme: IcThemeLight,
    IcAccount: IcAccountLight,
    IcOrder: IcOrderLight,
    IcProperty: IcPropertyLight,
    IcSupport: IcSupportLight,
    IcTrade: IcTradeLight,
    IcAgency: IcAgencyLight,
    IcReport: IcReportLight,
    IcBack: IcBackLight,
    IcForward: IcForwardLight,
    IcMenu: IcMenuLight,
    IcCopy: IcCopyLight,
    IcEdit: IcEditLight,
    IcNoticeClear: IcNoticeClearLight,
    IcNoticeWarning: IcNoticeWarningLight,
    IcNoticeError: IcNoticeErrorLight,
    IcContactEmail: IcContactEmailLight,
    IcClosePrimary: IcClosePrimaryLight,
    IcGooglePlay: IcGooglePlayLight,
    IcAppStore: IcAppStoreLight,
    IcPaginationNext: IcPaginationNextLight,
    IcPaginationPrev: IcPaginationPrevLight,
    IcSearch: IcSearchLight,
    IcRecord: IcRecordLight,
    FeatureSecure: FeatureSecureLight,
    FeaturePrivate: FeaturePrivateLight,
    FeatureReserves: FeatureReservesLight,
    IcEmpty: IcEmptyLight,
    IcCalendar: IcCalendarLight,
    IcGoMessage: IcGoMessageLight,
    IcQrcode: IcQrcodeLight,
    IcBatch: IcBatchLight,
    IcOpenInNew: IcOpenInNewLight,
  },
  [Theme.Dark]: {
    IcAdd: IcAddDark,
    IcAddSmall: IcAddSmallDark,
    IcAnnouncement: IcAnnouncementDark,
    IcAnnouncementRed: IcAnnouncementRedDark,
    IcTheme: IcThemeDark,
    IcAccount: IcAccountDark,
    IcOrder: IcOrderDark,
    IcProperty: IcPropertyDark,
    IcSupport: IcSupportDark,
    IcTrade: IcTradeDark,
    IcAgency: IcAgencyDark,
    IcReport: IcReportDark,
    IcBack: IcBackDark,
    IcForward: IcForwardDark,
    IcMenu: IcMenuDark,
    IcCopy: IcCopyDark,
    IcEdit: IcEditDark,
    IcNoticeClear: IcNoticeClearDark,
    IcNoticeWarning: IcNoticeWarningDark,
    IcNoticeError: IcNoticeErrorDark,
    IcContactEmail: IcContactEmailDark,
    IcClosePrimary: IcClosePrimaryDark,
    IcGooglePlay: IcGooglePlayDark,
    IcAppStore: IcAppStoreDark,
    IcPaginationNext: IcPaginationNextDark,
    IcPaginationPrev: IcPaginationPrevDark,
    IcSearch: IcSearchDark,
    IcRecord: IcRecordDark,
    FeatureSecure: FeatureSecureDark,
    FeaturePrivate: FeaturePrivateDark,
    FeatureReserves: FeatureReservesDark,
    IcEmpty: IcEmptyDark,
    IcCalendar: IcCalendarDark,
    IcGoMessage: IcGoMessageDark,
    IcQrcode: IcQrcodeDark,
    IcBatch: IcBatchDark,
    IcOpenInNew: IcOpenInNewDark,
  },
};
