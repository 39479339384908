import { IconButton } from '@mui/material';
import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'src/context/ThemeContext';
import { BankAccount } from 'src/model/Bank';

type Props = {
  batchArray: (BankAccount & {
    status: string | null;
  })[];
};

const Card = ({ batchArray }: Props) => {
  const { t } = useTranslation();
  const { IcOpenInNew } = useContext(ThemeContext).image;

  return (
    <>
      {batchArray.map((v) => {
        const handleOpenInNew = () => {
          if (!v.imageUrl) return;
          const tab = window.open(v.imageUrl, '_blank');
          if (!tab) return;
          tab.focus();
        };

        return (
          <div key={v.id}>
            <div className="my-[15px] flex flex-col gap-1 text-[14px] leading-[21px]">
              {v.paymentMethodType === 'bank' && (
                <>
                  <div className="flex items-center gap-[15px]">
                    <div className="font-bold text-grey-700">
                      {t('bankAccount.desc.accountName')}
                    </div>
                    <div>{v.name}</div>
                  </div>
                  <div className="flex items-center gap-[15px]">
                    <div className="font-bold text-grey-700">
                      {t('bankAccount.desc.bankInformation')}
                    </div>
                    <div>{v.bankName}</div>
                  </div>
                  <div className="flex items-center gap-[15px]">
                    <div className="font-bold text-grey-700">{t('bankAccount.desc.accountId')}</div>
                    <div>{v.account}</div>
                  </div>
                  <div className="flex items-center gap-[15px]">
                    <div className="font-bold text-grey-700">
                      {t('bankAccount.desc.bankProvinceCity')}
                    </div>
                    <div>
                      {v.province}/{v.city}
                    </div>
                  </div>
                  <div className="flex items-center gap-[15px]">
                    <div className="font-bold text-grey-700">
                      {t('bankAccount.desc.bankBranchName')}
                    </div>
                    <div>{v.bankBranchName}</div>
                  </div>
                </>
              )}
              {v.paymentMethodType === 'alipay' && (
                <>
                  <div className="flex items-center gap-[15px]">
                    <div className="font-bold text-grey-700">
                      {t('bankAccount.desc.alipayAccountName')}
                    </div>
                    <div>{v.name}</div>
                  </div>
                  <div className="flex items-center gap-[15px]">
                    <div className="font-bold text-grey-700">
                      {t('bankAccount.desc.alipayAccount')}
                    </div>
                    <div>{v.account}</div>
                  </div>
                  {v.imageUrl ? (
                    <div className={'flex flex-row  items-center'}>
                      <div> {t('bankAccount.desc.alipayQRCodeUploaded')}</div>
                      <div>
                        <IconButton
                          onClick={handleOpenInNew}
                          sx={{ padding: 0, margin: '2px 4px 0' }}
                        >
                          <img className="w-[24px]" src={IcOpenInNew} alt="open-in-new" />
                        </IconButton>
                      </div>
                    </div>
                  ) : (
                    <div>{t('bankAccount.desc.alipayQRCodeUnuploaded')}</div>
                  )}
                </>
              )}
              {v.status && (
                <div className="mt-1 flex items-center gap-2">
                  <div
                    className={classNames({
                      'text-green dark:text-lime': v.status === 'success',
                      'text-crimson dark:text-red': v.status === 'fail',
                      'text-khaki dark:text-yellow':
                        v.status === 'pending' || v.status === 'processing',
                    })}
                  >
                    {v.status !== 'success'
                      ? t(`account.desc.status.${v.status}`)
                      : t('account.desc.success')}
                  </div>
                  {v.status === 'fail' && (
                    <div className="break-all text-[12px] text-crimson dark:text-red">
                      {t('account.desc.error')}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
          </div>
        );
      })}
    </>
  );
};

export default Card;
