import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import { DepositRecord } from 'src/model/Deposit';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<DepositRecord>;
  navigateCallback: (record: DepositRecord) => void;
};

const DepositCard = ({ data, navigateCallback }: Props) => {
  const { t } = useTranslation();

  const onDetailClick = (record: DepositRecord) => () => {
    navigateCallback(record);
  };

  const elementMapFunction = (
    record: DepositRecord,
    index: number,
    array: Array<DepositRecord>,
  ) => (
    <div key={record.id}>
      <div className="flex flex-col gap-[4px] pt-[15px]">
        <div className="flex flex-row gap-[15px]">
          <div className="text-[14px] text-dark-500 dark:text-grey-700">
            {t('record.desc.coin')}
          </div>
          <div className="text-[14px]">{record.coin.toUpperCase()}</div>
        </div>
        <div className="flex flex-row gap-[15px]">
          <div className="text-[14px] text-dark-500 dark:text-grey-700">
            {t('record.desc.amount')}
          </div>
          <div className="text-[14px]">{bnFormat(record.amount)}</div>
        </div>
        <div className="mt-[6px] flex flex-col gap-[2px] text-[12px] text-grey-500 dark:text-grey-700">
          <div>{record.address}</div>
          <div>{format(Date.parse(record.createdAt), 'yyyy/MM/dd HH:mm:ss')}</div>
        </div>
        <div className="mt-[6px] flex flex-row-reverse">
          <Button appearance="text" onClick={onDetailClick(record)}>
            {t('record.act.detail')}
          </Button>
        </div>
      </div>
      <div
        className={classNames('mt-[13px] h-[1px] bg-light-200 dark:bg-dark-700', {
          hidden: index >= array.length - 1,
        })}
      />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default DepositCard;
