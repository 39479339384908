import { format } from 'date-fns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { BankAccount } from 'src/model/Bank';
import { TradingForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { createOrder, updateOrder } from 'src/service/orderService';
import { bnFormat } from 'src/util/bigNumber';

const TradingConfirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const state = location.state as (TradingForm & { bankAccount: BankAccount }) | null;
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);

  useEffect(() => {
    if (state === null) navigate(Page.Trading);
  }, []);

  const onSubmit = (action: 'saveChange' | 'saveAndOpen') => () => {
    if (state === null) return;
    if (state.orderId !== undefined)
      updateOrder(state.orderId, action, {
        price: Number(state.price),
        openAmount: Number(state.amount),
        minTotal: Number(state.minTotal),
        maxTotal: Number(state.maxTotal),
        suspendedAt: (state.suspendedAt?.length ?? 0) > 0 ? state.suspendedAt : null,
        bankAccountId: state.bankAccountId,
      })
        .then(() => navigate(Page.MyTrade, { replace: true }))
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
    else
      createOrder({
        base: state.base,
        quote: state.quote,
        price: Number(state.price),
        openAmount: Number(state.amount),
        minTotal: Number(state.minTotal),
        maxTotal: Number(state.maxTotal),
        suspendedAt: (state.suspendedAt?.length ?? 0) > 0 ? state.suspendedAt : undefined,
        bankAccountId: state.bankAccountId,
        clientId: Date.now().toString(),
      })
        .then(() => navigate(Page.MyTrade, { replace: true }))
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onCancel = () => {
    navigate(Page.Trading, { replace: true, state });
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] text-[27px] font-[600] sm:mt-[20px]">
        {t('tradingConfirm.heading')}
      </div>
      <div className="mt-[30px] rounded-[12px] bg-white dark:bg-black-500">
        <div className="flex flex-col flex-wrap gap-[20px] px-[20px] pb-[30px] pt-[25px] xs:flex-row xs:gap-x-[10px] xs:gap-y-[20px] xs:px-[45px] xs:pb-[30px] xs:pt-[40px]">
          <div className="flex justify-between xs:block xs:w-[calc((100%-20px)/3)]">
            <div className="text-grey-700 dark:text-grey-300">
              {t('tradingConfirm.desc.tradeType')}
            </div>
            <div>{t('tradingConfirm.desc.sell')}</div>
          </div>
          <div className="flex justify-between xs:block xs:w-[calc((100%-20px)/3)]">
            <div className="text-grey-700 dark:text-grey-300">
              {t('tradingConfirm.desc.coinType')}
            </div>
            <div>{state?.base.toUpperCase()}</div>
          </div>
          <div className="flex justify-between xs:block xs:w-[calc((100%-20px)/3)]">
            <div className="text-grey-700 dark:text-grey-300">
              {t('tradingConfirm.desc.currency')}
            </div>
            <div>{state?.quote.toUpperCase()}</div>
          </div>
        </div>
        <div className="mx-[15px] my-0 h-[1px] bg-light-200 dark:bg-dark-700 xs:mx-[40px]" />
        <div className="flex flex-col flex-wrap gap-[20px] px-[20px] pb-[30px] pt-[25px] xs:flex-row xs:gap-x-[10px] xs:gap-y-[20px] xs:px-[45px] xs:pb-[30px] xs:pt-[40px]">
          <div className="flex justify-between xs:block xs:w-[calc((100%-20px)/3)]">
            <div className="text-grey-700 dark:text-grey-300">
              {t('tradingConfirm.desc.unitPrice')}
            </div>
            <div>{`${bnFormat(state?.price ?? '')} ${state?.quote.toUpperCase()}`}</div>
          </div>
          <div className="flex justify-between xs:block xs:w-[calc((100%-20px)/3)]">
            <div className="text-grey-700 dark:text-grey-300">
              {t('tradingConfirm.desc.amount')}
            </div>
            <div>{`${bnFormat(state?.amount ?? '')} ${state?.base.toUpperCase()}`}</div>
          </div>
          <div className="flex justify-between xs:block xs:w-[calc((100%-20px)/3)]">
            <div className="text-grey-700 dark:text-grey-300">
              {t('tradingConfirm.desc.paymentLimit')}
            </div>
            <div>
              {`${bnFormat(state?.minTotal ?? '')} ~ ${bnFormat(
                state?.maxTotal ?? '',
              )} ${state?.quote.toUpperCase()}`}
            </div>
          </div>
          {(state?.suspendedAt?.length ?? 0) > 0 && (
            <div className="flex justify-between xs:block xs:w-[calc((100%-20px)/3)]">
              <div className="text-grey-700 dark:text-grey-300">
                {t('tradingConfirm.desc.automaticWithdrawal')}
              </div>
              <div>{format(Date.parse(state?.suspendedAt ?? ''), 'yyyy/MM/dd HH:mm:ss')}</div>
            </div>
          )}
        </div>
        <div className="mx-[15px] my-0 h-[1px] bg-light-200 dark:bg-dark-700 xs:mx-[40px]" />
        <div className="px-[15px] pb-[40px] pt-[30px] xs:px-[40px]">
          <div className="pb-[15px] text-grey-700 dark:text-grey-300">
            {t('tradingConfirm.desc.receivePayment')}
          </div>
          {!isBiggerThanMd && (
            <div className="flex flex-col gap-1 text-[14px] leading-[1.5]">
              {state?.bankAccount.paymentMethodType === 'bank' && (
                <>
                  <div className="flex gap-[15px]">
                    <div className="text-grey-700 dark:text-grey-300">
                      {t('tradingConfirm.desc.accountName')}
                    </div>
                    <div>{state?.bankAccount.name}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="text-grey-700 dark:text-grey-300">
                      {t('tradingConfirm.desc.bank')}
                    </div>
                    <div>{state?.bankAccount.bankName}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="text-grey-700 dark:text-grey-300">
                      {t('tradingConfirm.desc.bankAccount')}
                    </div>
                    <div>{state?.bankAccount.account}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="text-grey-700 dark:text-grey-300">
                      {t('tradingConfirm.desc.bankProvinceCity')}
                    </div>
                    <div>
                      {state?.bankAccount.province}/{state?.bankAccount.city}
                    </div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="text-grey-700 dark:text-grey-300">
                      {t('tradingConfirm.desc.bankBranchName')}
                    </div>
                    <div>{state?.bankAccount.bankBranchName}</div>
                  </div>
                </>
              )}
              {state?.bankAccount.paymentMethodType === 'alipay' && (
                <>
                  <div className="flex gap-[15px]">
                    <div className="text-grey-700 dark:text-grey-300">
                      {t('tradingConfirm.desc.alipayAccountName')}
                    </div>
                    <div>{state?.bankAccount.name}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="text-grey-700 dark:text-grey-300">
                      {t('tradingConfirm.desc.alipayAccount')}
                    </div>
                    <div>{state?.bankAccount.account}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="text-grey-700 dark:text-grey-300">
                      {t('tradingConfirm.desc.alipayQRCode')}
                    </div>
                    <div>
                      {state?.bankAccount.imageUrl
                        ? t('tradingConfirm.desc.alipayQRCodeUploaded')
                        : t('tradingConfirm.desc.alipayQRCodeUnuploaded')}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {isBiggerThanMd && (
            <div className="text-[14px] leading-[1.5]">
              {state?.bankAccount.paymentMethodType === 'bank' && (
                <>
                  <div className="flex flex-1 flex-row text-grey-700 dark:text-grey-300">
                    <span className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('tradingConfirm.desc.accountName')}
                    </span>
                    <span className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('tradingConfirm.desc.bank')}
                    </span>
                    <span className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('tradingConfirm.desc.bankAccount')}
                    </span>
                    <span className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('tradingConfirm.desc.bankProvinceName')}
                    </span>
                    <span className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('tradingConfirm.desc.bankCityName')}
                    </span>
                    <span className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('tradingConfirm.desc.bankBranchName')}
                    </span>
                  </div>
                  <div className="flex flex-1 flex-row">
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {state?.bankAccount.name}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {state?.bankAccount.bankName}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {state?.bankAccount.account}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {state?.bankAccount.province}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {state?.bankAccount.city}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {state?.bankAccount.bankBranchName}
                    </div>
                  </div>
                </>
              )}
              {state?.bankAccount.paymentMethodType === 'alipay' && (
                <>
                  <div className="flex flex-1 flex-row text-grey-700 dark:text-grey-300">
                    <span className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('tradingConfirm.desc.alipayAccountName')}
                    </span>
                    <span className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('tradingConfirm.desc.alipayAccount')}
                    </span>
                    <span className="box-border w-4/6 px-[12px] py-[16px]">
                      {t('tradingConfirm.desc.alipayQRCode')}
                    </span>
                  </div>
                  <div className="flex flex-1 flex-row">
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {state?.bankAccount.name}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {state?.bankAccount.account}
                    </div>
                    <div className="box-border w-4/6 break-all md:px-[12px] md:py-0">
                      {state?.bankAccount.imageUrl
                        ? t('tradingConfirm.desc.alipayQRCodeUploaded')
                        : t('tradingConfirm.desc.alipayQRCodeUnuploaded')}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-wrap justify-between gap-[24px] px-[15px] pb-[40px] pt-0 xs:px-[40px]">
          <div>
            <Button appearance="outlined" onClick={onCancel}>
              {t('tradingConfirm.act.cancel')}
            </Button>
          </div>
          <div className="flex gap-[20px]">
            {state?.orderId === undefined && (
              <Button onClick={onSubmit('saveAndOpen')}>{t('tradingConfirm.act.submit')}</Button>
            )}
            {state?.orderId && (state?.suspendedAt?.length ?? 0) === 0 && (
              <Button onClick={onSubmit('saveChange')}>{t('tradingConfirm.act.save')}</Button>
            )}
            {state?.orderId && (
              <Button onClick={onSubmit('saveAndOpen')}>
                {t('tradingConfirm.act.saveAndOpen')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingConfirm;
