import {
  GetAnnouncementIdResponse,
  GetAnnouncementParams,
  GetAnnouncementResponse,
  PostAnnouncementRequest,
  PostAnnouncementResponse,
  PutAnnouncementRequest,
  PutAnnouncementResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getAnnouncement = async (params?: GetAnnouncementParams) => {
  try {
    return await http.authGet<GetAnnouncementResponse>('announcements', { params });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getAnAnnouncement = async (id: string) => {
  try {
    return await http.authGet<GetAnnouncementIdResponse>(`announcements/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const createAnnouncement = async (data: PostAnnouncementRequest) => {
  try {
    return await http.authPost<PostAnnouncementResponse, PostAnnouncementRequest>('announcements', {
      data,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const updateAnnouncement = async (id: string, data: PutAnnouncementRequest) => {
  try {
    return await http.authPut<PutAnnouncementResponse, PutAnnouncementRequest>(
      `announcements/${id}`,
      {
        data,
      },
    );
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const deleteAnnouncement = async (id: string) => {
  try {
    return await http.authDelete(`announcements/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const readAnnouncement = async (id: string) => {
  try {
    return await http.authPatch(`announcements/${id}/read`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getAnnouncement,
  getAnAnnouncement,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  readAnnouncement,
};
