import { useTranslation } from 'react-i18next';
import Radio from 'src/component/Radio';
import { BankAccount } from 'src/model/Bank';

type Props = {
  name: string;
  accountArray: BankAccount[];
  defaultIndex?: number;
};

const PaymentTable = ({ name, accountArray, defaultIndex }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-[15px] mt-[30px] font-bold text-black-900 dark:text-white">
        {t('trading.desc.bankCard')}
      </div>
      <div className="border-0 md:flex md:flex-row md:border-b md:border-solid md:border-b-light-200 dark:md:border-b-dark-700">
        <div className="pt-[3px] md:w-[40px] md:pt-0" />
        <div className="flex flex-1 flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
          <span className="box-border w-1/6 px-[12px] py-[16px]">
            {t('trading.desc.accountName')}
          </span>
          <span className="box-border w-1/6 px-[12px] py-[16px]">{t('trading.desc.bank')}</span>
          <span className="box-border w-1/6 px-[12px] py-[16px]">
            {t('trading.desc.bankAccount')}
          </span>
          <span className="box-border w-1/6 px-[12px] py-[16px]">
            {t('trading.desc.bankProvinceName')}
          </span>
          <span className="box-border w-1/6 px-[12px] py-[16px]">
            {t('trading.desc.bankCityName')}
          </span>
          <span className="box-border w-1/6 px-[12px] py-[16px]">
            {t('trading.desc.bankBranchName')}
          </span>
        </div>
      </div>
      {accountArray
        .filter((v) => v.paymentMethodType === 'bank')
        .map((v, index) => (
          <div key={v.id}>
            <div className="flex px-0 py-[16px]">
              <div className="mr-[8px] mt-[1px] flex flex-row items-start justify-center pt-[3px] md:m-0 md:w-[40px] md:items-center md:pt-0">
                <Radio value={name + ',' + v.id} defaultChecked={index === defaultIndex} />
              </div>
              <label htmlFor={name + ',' + v.id} className="flex flex-1 flex-row text-[14px]">
                <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">{v.name}</div>
                <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">{v.bankName}</div>
                <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">{v.account}</div>
                <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">{v.province}</div>
                <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">{v.city}</div>
                <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                  {v.bankBranchName}
                </div>
              </label>
            </div>
            <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
          </div>
        ))}
      <div className="mb-[15px] mt-[30px] font-bold text-black-900 dark:text-white">
        {t('trading.desc.alipay')}
      </div>
      <div className="border-0 md:flex md:flex-row md:border-b md:border-solid md:border-b-light-200 dark:md:border-b-dark-700">
        <div className="pt-[3px] md:w-[40px] md:pt-0" />
        <div className="flex flex-1 flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
          <span className="box-border w-1/6 px-[12px] py-[16px]">
            {t('trading.desc.alipayAccountName')}
          </span>
          <span className="box-border w-5/6 px-[12px] py-[16px]">
            {t('trading.desc.alipayAccount')}
          </span>
        </div>
      </div>
      {accountArray
        .filter((v) => v.paymentMethodType === 'alipay')
        .map((v, index) => (
          <div key={v.id}>
            <div className="flex px-0 py-[16px]">
              <div className="mr-[8px] mt-[1px] flex flex-row items-start justify-center pt-[3px] md:m-0 md:w-[40px] md:items-center md:pt-0">
                <Radio value={name + ',' + v.id} defaultChecked={index === defaultIndex} />
              </div>
              <label htmlFor={name + ',' + v.id} className="flex flex-1 flex-row text-[14px]">
                <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">{v.name}</div>
                <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">{v.account}</div>
                <div className="box-border w-4/6 break-all md:px-[12px] md:py-0">
                  {v.imageUrl
                    ? t('trading.desc.alipayQRCodeUploaded')
                    : t('trading.desc.alipayQRCodeUnuploaded')}
                </div>
              </label>
            </div>
            <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
          </div>
        ))}
    </div>
  );
};

export default PaymentTable;
