import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AutoComplete from 'src/component/AutoComplete';
import AutoCompleteOption from 'src/component/AutoCompleteOption';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import FormSelect from 'src/component/FormSelect';
import SelectOption from 'src/component/SelectOption';
import { Bank } from 'src/model/Bank';
import { BankAccountForm } from 'src/model/Form';

type Props = {
  bank: Bank[];
  onSubmit: (data: BankAccountForm) => void;
};

const BankForm = ({ bank, onSubmit }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<BankAccountForm>();

  const [bankInput, setBankInput] = useState<string>('');

  return (
    <Form className="mx-auto mt-[55px]" methods={methods} onSubmit={onSubmit}>
      <div className="mb-[15px] mt-[40px] text-[20px] font-bold">
        {t('bankAccount.desc.bankInformation')}
      </div>
      <div className="md:flex md:flex-row">
        <div className="mt-[20px] xs:px-[30px] md:w-1/3">
          <FormSelect
            name="region"
            label={t('bankAccount.desc.bankCountryRegion')}
            defaultValue="china"
            asterisked
          >
            <SelectOption value="china">{t('bankAccount.desc.china')}</SelectOption>
          </FormSelect>
        </div>
        <div
          className="mt-[20px] xs:px-[30px] md:w-1/3"
          onBlur={() => {
            if (methods.getValues('bankId') === '') setBankInput('');
          }}
        >
          <AutoComplete
            value={bankInput}
            label={t('bankAccount.desc.selectBank')}
            asterisked
            onChange={(v) => {
              setBankInput(v);
              methods.setValue('bankId', bank.find((o) => o.name === v)?.id ?? '');
            }}
          >
            {bank.map((v) => (
              <AutoCompleteOption key={v.id} value={v.name}>
                {v.name}
              </AutoCompleteOption>
            ))}
          </AutoComplete>
        </div>
        <div className="mt-[20px] xs:px-[30px] md:w-1/3">
          <FormInput
            name="bankBranchName"
            label={t('bankAccount.desc.bankBranchName')}
            type="text"
            asterisked
            required
          />
        </div>
      </div>
      <div className="md:flex md:flex-row">
        <div className="mt-[20px] xs:px-[30px] md:w-1/3">
          <FormInput
            name="province"
            label={t('bankAccount.desc.bankProvince')}
            type="text"
            asterisked
            required
          />
        </div>
        <div className="mt-[20px] xs:px-[30px] md:w-1/3">
          <FormInput
            name="city"
            label={t('bankAccount.desc.bankCity')}
            type="text"
            asterisked
            required
          />
        </div>
        <div className="mt-[20px] xs:px-[30px] md:w-1/3">
          <FormSelect
            name="type"
            label={t('bankAccount.desc.type')}
            defaultValue="personal"
            asterisked
          >
            <SelectOption value="personal">{t('bankAccount.act.personal')}</SelectOption>
            <SelectOption value="corporate">{t('bankAccount.act.corporate')}</SelectOption>
          </FormSelect>
        </div>
      </div>
      <div className="mb-[15px] mt-[40px] text-[20px] font-bold">
        {t('bankAccount.desc.accountInformation')}
      </div>
      <div className="mt-[20px] xs:px-[30px]">
        <FormInput
          name="account"
          label={t('bankAccount.desc.accountId')}
          patternRule={/^[0-9]+([ -][0-9]+)*$/}
          type="text"
          asterisked
          required
        />
      </div>
      <div className="mt-[20px] xs:px-[30px]">
        <FormInput
          name="name"
          label={t('bankAccount.desc.accountName')}
          type="text"
          asterisked
          required
        />
      </div>
      <div className="mt-[24px] pr-[30px] text-end">
        <Button size="large" type="submit">
          {t('bankAccount.act.submit')}
        </Button>
      </div>
    </Form>
  );
};

export default BankForm;
