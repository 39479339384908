import {
  GetCommissionsIdAdminResponse,
  GetCommissionsIdResponse,
  GetCommissionsParams,
  GetCommissionsResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getCommissionsId = async (id: string) => {
  try {
    return await http.authGet<GetCommissionsIdResponse>(`commissions/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getCommissionsIdAdmin = async (id: string) => {
  try {
    return await http.authGet<GetCommissionsIdAdminResponse>(`commissions/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getCommissions = async (
  params: GetCommissionsParams,
  headers?: { [key: string]: string },
) => {
  try {
    return await http.authGet<GetCommissionsResponse, GetCommissionsParams>('commissions', {
      params,
      headers,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getCommissionsId,
  getCommissionsIdAdmin,
  getCommissions,
};
