import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { Fill } from 'src/model/Order';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data?: Fill[];
};

const OrderAllTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: Fill) => (
    <div key={order.id} className="flex flex-col">
      <div className="flex flex-row">
        <div className="box-border w-[16.66%] break-words pr-[10px]">
          <div>{order.id}</div>
        </div>
        <div className="box-border w-[16.66%] break-words pr-[10px]">
          <>{format(Date.parse(order.createdAt), 'yyyy/MM/dd HH:mm:ss')}</>
        </div>
        <div className="box-border w-[16.66%] break-words pr-[10px]">
          <div>{`${bnFormat(order.amount)} ${order.base.toUpperCase()}`}</div>
        </div>
        <div className="box-border w-[16.66%] break-words pr-[10px]">
          <div>{`${bnFormat(order.price)} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="box-border w-[16.66%] break-words pr-[10px]">
          <div>{`${bnFormat(order.total)} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="box-border w-[8.33%] break-words pr-[10px]">
          <div className="text-[12px] text-green dark:text-lime">
            {t(`allOrders.desc.statusDisplay.${order.status}`)}
          </div>
        </div>
        <div className="box-border w-[8.33%] break-words pr-[10px]">
          <Button appearance="text" onClick={() => navigate(Page.Order + '/' + order.id)}>
            {t('allOrders.act.detail')}
          </Button>
        </div>
      </div>
      <div className="my-[15px] h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div className="mt-[15px]">
      <div className="flex flex-row text-[14px] font-bold text-grey-500 dark:text-grey-700">
        <div className="w-[16.66%]">{t('allOrders.desc.orderId')}</div>
        <div className="w-[16.66%]">{t('allOrders.desc.date')}</div>
        <div className="w-[16.66%]">{t('allOrders.desc.amount')}</div>
        <div className="w-[16.66%]">{t('allOrders.desc.unitPrice')}</div>
        <div className="w-[16.66%]">{t('allOrders.desc.total')}</div>
        <div className="w-[8.33%]">{t('allOrders.desc.status')}</div>
      </div>
      <div className="my-[15px] h-[1px] bg-light-200 dark:bg-dark-700" />
      {data?.map(elementMapFunction)}
    </div>
  );
};

export default OrderAllTable;
