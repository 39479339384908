import axios, { AxiosError } from 'axios';
import { t } from 'i18next';
import { Ui } from 'src/constant/Env';
import { resetAuth } from 'src/redux/authSlice';
import { dispatch } from 'src/redux/store';
import { emitter } from './eventBus';

export type BitopError = {
  code: number;
  debug: unknown;
  errors: [];
  message: string;
};

export const axiosError = (e: unknown) => {
  if (axios.isAxiosError(e)) return e as AxiosError<BitopError>;
  throw t('error.common.unexpected');
};

export const defaultErrorMessage = (e: AxiosError<BitopError>) => {
  const status = e.response?.status;
  const code = e.response?.data.code ?? -1;
  if (status === 401 && code === 1) {
    localStorage.removeItem('token');
    dispatch(resetAuth());
    emitter.emit('auth-expired');

    return t('error.common.E-401-1');
  }
  if (Ui === 'admin' && status === 401 && code === 9) {
    emitter.emit('force-tfa-process');

    return t('error.common.E-401-9');
  }
  if (
    (status === 400 && [0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12].includes(code)) ||
    (status === 401 && [0, 2, 4, 5, 6, 8, 9, 10].includes(code)) ||
    (status === 403 && code === 0) ||
    (status === 404 && code === 0) ||
    (status === 405 && code === 0) ||
    (status === 409 && code === 0) ||
    (status === 422 && code === 0) ||
    (status === 500 && code === 0) ||
    (status === 501 && code === 0) ||
    (status === 503 && code === 0)
  )
    return t(`error.common.E-${status}-${code}`);

  return t('error.common.unexpected');
};
