import { t } from 'i18next';
import { GetAuthMeResponse, PatchAuthMeRequest } from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getOwnInfo = async () => {
  try {
    return await http.authGet<GetAuthMeResponse>('auth/me');
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const patchMe = async (data: PatchAuthMeRequest) => {
  try {
    await http.authPatch('auth/me', { data });
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 400 && error.response.data.code === 0)
      throw t('error.me.oldPasswordError');
    throw defaultErrorMessage(error);
  }
};

export default {
  getOwnInfo,
  patchMe,
};
