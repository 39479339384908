import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import { Ui } from 'src/constant/Env';
import { Page } from 'src/constant/Page';
import IcSuccess from 'src/image/ic-success.svg';

const WithdrawalSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { userId: string } | null;

  useEffect(() => {
    if (!state?.userId)
      navigate(Ui === 'admin' ? Page.UserAdmin : Page.Withdrawal, { replace: true });
  }, []);

  return (
    <div>
      <BackButton />
      <div className="mx-0 my-[40px] flex items-center justify-center sm:mx-0 sm:mb-[40px] sm:mt-[70px]">
        <img className="w-[72px]" src={IcSuccess} />
      </div>
      <div className="mb-[10px] text-center text-[24px] font-bold">
        {t('withdrawal.desc.successHead')}
      </div>
      <div className="mb-[40px] text-center text-[14px]">{t('withdrawal.desc.successText')}</div>
      <div className="text-center">
        <Button
          className="px-[40px] py-[13px] font-bold"
          size="large"
          onClick={() => {
            if (Ui === 'admin' && state?.userId !== 'xx')
              navigate(Page.WithdrawalRecord, { state: { userId: state?.userId } });
            else if (Ui === 'admin' && state?.userId === 'xx') navigate(Page.WithdrawalRecord);
            else navigate(`${Page.Record}?type=withdrawal`, { replace: true });
          }}
        >
          {t('withdrawal.act.gotoRecord')}
        </Button>
      </div>
    </div>
  );
};

export default WithdrawalSuccess;
