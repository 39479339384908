import { configureStore, PayloadAction, Store } from '@reduxjs/toolkit';
import apiReducer, { ApiState } from './apiSlice';
import authReducer, { AuthState } from './authSlice';
import coinReducer, { CoinState } from './coinSlice';
import rebateReducer, { RebateState } from './rebateSlice';
import settingReducer, { SettingState } from './settingSlice';
import uiReducer, { UiState } from './uiSlice';

export type RootState = {
  api: ApiState;
  auth: AuthState;
  coin: CoinState;
  rebate: RebateState;
  setting: SettingState;
  ui: UiState;
};

let store: Store<RootState>;

export const configStore = () => {
  store = configureStore({
    reducer: {
      api: apiReducer,
      auth: authReducer,
      coin: coinReducer,
      rebate: rebateReducer,
      setting: settingReducer,
      ui: uiReducer,
    },
  });

  return store;
};

export const getState = () => store.getState();

export const dispatch = <T>(action: PayloadAction<T>) => store.dispatch(action);
