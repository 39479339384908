import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Pagination from 'src/component/Pagination';
import { ThemeContext } from 'src/context/ThemeContext';
import { usePagination } from 'src/hook/usePagination';
import { Announcement as AnnouncementData } from 'src/model/Announcement';
import { openSnackbar } from 'src/redux/uiSlice';
import { getAnnouncementList } from 'src/service/announcementService';

const Announcement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { IcEmpty } = useContext(ThemeContext).image;
  const navigate = useNavigate();
  const [announcementList, setAnnouncementList] = useState<AnnouncementData[]>([]);
  const { limit, offset, setCount, paginationProps } = usePagination();

  useEffect(() => {
    getAnnouncementList({ offset, limit })
      .then((res) => {
        setAnnouncementList(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [limit, offset]);

  const onClick = (id: string) => () => {
    navigate(id);
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        <div>{t('announcement.heading')}</div>
      </div>
      {announcementList.length === 0 && (
        <div className="mt-[70px] flex flex-col items-center text-[16px] sm:mt-[140px]">
          <img src={IcEmpty} />
          {t('desc.noRecord')}
        </div>
      )}
      {announcementList.length > 0 && (
        <div>
          <div className="mt-[16px] rounded-[12px] bg-white dark:bg-black-800">
            {announcementList.map((v, i) => (
              <div key={v.id}>
                <div
                  className="flex cursor-pointer flex-col gap-[16px] p-[16px]"
                  onClick={onClick(v.id)}
                >
                  <div className="flex items-center gap-[10px]">
                    {v.readAt === null && (
                      <div className="h-[6px] w-[6px] rounded-full bg-crimson dark:bg-red" />
                    )}
                    <div>{v.subject}</div>
                  </div>
                  <div>{format(Date.parse(v.publishedAt), 'yyyy-MM-dd HH:mm')}</div>
                </div>
                {announcementList.length - 1 > i && (
                  <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
                )}
              </div>
            ))}
          </div>
          <div className="mt-[15px] flex flex-row-reverse">
            <Pagination {...paginationProps} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Announcement;
