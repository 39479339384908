import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from 'src/component/Alert';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormDatetimePicker from 'src/component/FormDatetimePicker';
import FormNumberInput from 'src/component/FormNumberInput';
import FormSelect from 'src/component/FormSelect';
import SelectOption from 'src/component/SelectOption';
import { Severity } from 'src/constant/Notification';
import { Page } from 'src/constant/Page';
import { BankAccount } from 'src/model/Bank';
import { TradingForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { initTrading } from 'src/service/orderService';
import { bn, bnFixed, bnFormat } from 'src/util/bigNumber';
import PaymentMethodsList from './component/PaymentMethodsList';

const Trading = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const stateTradingForm = location.state as TradingForm | null;
  const methods = useForm<TradingForm>({
    defaultValues: stateTradingForm
      ? {
          ...stateTradingForm,
        }
      : undefined,
  });
  const { crypto, fiat } = useSelector((rootState: RootState) => rootState.coin);
  const [exchangeRate, setExchangeRate] = useState<string>();
  const [bankAccount, setBankAccount] = useState<BankAccount[]>();
  const [balance, setBalance] = useState<string>();
  const [defaultCoin, setDefaultCoin] = useState<string>();
  const [defaultFiat, setDefaultFiat] = useState<string>();
  const [coinDecimal, setCoinDecimal] = useState<number>(0);
  const [fiatDecimal, setFiatDecimal] = useState<number>(0);
  const [fiatPriceDecimal, setFiatPriceDecimal] = useState<number>(0);
  const [minOrderAmount, setMinOrderAmount] = useState<string | null>();
  const [maxOrderAmount, setMaxOrderAmount] = useState<string | null>();
  const [minOrderTotal, setMinOrderTotal] = useState<string | null>();
  const [maxOrderTotal, setMaxOrderTotal] = useState<string | null>();
  const formData = useWatch({ control: methods.control });
  const bankAccountId = useMemo(
    () =>
      (bankAccount?.findIndex((value) => value.id === formData.bankAccountId) ?? -1) >= 0
        ? formData.bankAccountId
        : undefined,
    [bankAccount, formData.bankAccountId],
  );
  const amountInputHint = useMemo(() => {
    const isDataReady = formData.base !== undefined && balance !== undefined;

    if (!isDataReady) return '';
    let res = `${t('trading.desc.youHave')} ${bnFormat(balance)} ${formData.base?.toUpperCase()}`;

    if (minOrderAmount && maxOrderAmount)
      res += `\n${t('trading.desc.orderAmountHint')} ${bnFormat(
        minOrderAmount,
      )} ${formData.base?.toUpperCase()} - ${bnFormat(
        maxOrderAmount,
      )} ${formData.base?.toUpperCase()}`;
    else if (minOrderAmount && maxOrderAmount === null)
      res += `\n${t('trading.desc.orderAmountHint')} ${bnFormat(
        minOrderAmount,
      )} ${formData.base?.toUpperCase()} -`;
    else if (minOrderAmount === null && maxOrderAmount)
      res += `\n${t('trading.desc.orderAmountHint')} 0 - ${bnFormat(
        maxOrderAmount,
      )} ${formData.base?.toUpperCase()}`;

    return res;
  }, [balance, formData.base, t]);

  useEffect(() => {
    if (!crypto[0] || !fiat[0]) return;

    setDefaultCoin(crypto[0].id);
    setDefaultFiat(fiat[0].id);
  }, [crypto, fiat]);

  useEffect(() => {
    if (!formData.base || !formData.quote) return;

    initTrading(formData.base, formData.quote)
      .then(([resRate, resBankAccount, resBalance]) => {
        setExchangeRate(resRate.price);
        setBankAccount(resBankAccount);
        setBalance(resBalance.free);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [formData.base, formData.quote]);

  useEffect(() => {
    if (!formData.base) return;

    setCoinDecimal(crypto.find((v) => v.id === formData.base)?.decimal ?? 0);
    setMinOrderAmount(crypto.find((v) => v.id === formData.base)?.minOrderAmount);
    setMaxOrderAmount(crypto.find((v) => v.id === formData.base)?.maxOrderAmount);
  }, [formData.base, crypto]);

  useEffect(() => {
    if (!formData.quote) return;

    setFiatDecimal(fiat.find((v) => v.id === formData.quote)?.decimal ?? 0);
    setFiatPriceDecimal(fiat.find((v) => v.id === formData.quote)?.priceDecimal ?? 0);
    setMinOrderTotal(fiat.find((v) => v.id === formData.quote)?.minOrderTotal);
    setMaxOrderTotal(fiat.find((v) => v.id === formData.quote)?.maxOrderTotal);
  }, [formData.quote, fiat]);

  useEffect(() => {
    const { price, amount, minTotal, maxTotal } = methods.getValues();

    const diff = bn(price)
      .minus(exchangeRate ?? '')
      .div(exchangeRate ?? '');
    if (bn(price).gt(exchangeRate ?? ''))
      methods.setError('price', { message: t('trading.desc.gtRefrencePrice') });
    else if (diff.lt(-0.2))
      methods.setError('price', { message: t('trading.desc.diffReferencePrice') });
    else methods.clearErrors('price');

    if (bn(amount).gt(balance ?? ''))
      methods.setError('amount', { message: t('trading.desc.insufficientBalance') });
    else if (minOrderAmount && bn(amount).lt(minOrderAmount))
      methods.setError('amount', { message: t('trading.desc.lessThanMinOrderAmount') });
    else if (maxOrderAmount && bn(amount).gt(maxOrderAmount))
      methods.setError('amount', { message: t('trading.desc.greaterThanMinOrderAmount') });
    else methods.clearErrors('amount');

    if (bn(price).times(amount).lt(minTotal))
      methods.setError('minTotal', { message: t('trading.desc.exceedTotal') });
    else if (minOrderTotal && bn(minTotal).lt(minOrderTotal))
      methods.setError('minTotal', { message: t('trading.desc.lessThanSystem') });
    else methods.clearErrors('minTotal');

    if (bn(minTotal).gt(maxTotal))
      methods.setError('maxTotal', { message: t('trading.desc.lessThanMin') });
    else if (maxOrderTotal && bn(maxTotal).gt(maxOrderTotal))
      methods.setError('maxTotal', { message: t('trading.desc.greaterThanSystem') });
    else methods.clearErrors('maxTotal');
  }, [formData, t]);

  const onPreview = (data: TradingForm) => {
    navigate(Page.TradingConfirm, {
      state: {
        ...data,
        bankAccount: bankAccount?.find((v) => v.id === data.bankAccountId),
      },
    });
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] flex flex-row justify-between text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('trading.heading')}
        <div className="flex h-fit flex-row gap-[12px]">
          <Button appearance="text" onClick={() => navigate(Page.Trade, { replace: true })}>
            {t('trading.act.trade')}
          </Button>
          <Button appearance="text" onClick={() => navigate(Page.MyTrade, { replace: true })}>
            {t('trading.act.myTrade')}
          </Button>
        </div>
      </div>
      {crypto.length !== 0 && fiat.length !== 0 && defaultCoin && defaultFiat && (
        <Form methods={methods} onSubmit={onPreview}>
          <div className="pt-[30px] xs:flex xs:flex-row xs:px-0 xs:py-[30px]">
            <div className="relative box-border flex flex-col xs:w-[33.33%] xs:px-[15px] xs:py-0">
              <span className="text-[14px] text-grey-700 dark:text-grey-300">
                {t('trading.desc.tradeType')}
              </span>
              <span className="mx-0 my-[8px] text-[16px]">{t('trading.desc.sell')}</span>
            </div>
            <div className="mt-[25px] box-border xs:m-0 xs:w-[33.33%] xs:px-[15px] xs:py-0">
              <FormSelect
                name="base"
                label={t('trading.desc.coinType')}
                defaultValue={defaultCoin}
                asterisked
              >
                {crypto.map((value) => (
                  <SelectOption key={value.id} value={value.id}>
                    {value.id.toUpperCase()}
                  </SelectOption>
                ))}
              </FormSelect>
            </div>
            <div className="mt-[25px] box-border xs:m-0 xs:w-[33.33%] xs:px-[15px] xs:py-0">
              <FormSelect
                name="quote"
                label={t('trading.desc.currency')}
                defaultValue={defaultFiat}
                asterisked
              >
                {fiat.map((v) => (
                  <SelectOption key={v.id} value={v.id}>
                    {v.id.toUpperCase()}
                  </SelectOption>
                ))}
              </FormSelect>
            </div>
          </div>
          <div className="mx-[15px] my-0 xs:h-[1px] xs:bg-light-200 dark:xs:bg-dark-700" />
          <div className="border-0 border-b-[1px] border-solid border-b-light-200 px-0 py-[30px] dark:border-b-dark-700">
            <div className="xs:flex xs:flex-row">
              <div className="relative box-border xs:w-[33.33%] xs:px-[15px] xs:py-0">
                <FormNumberInput
                  name="price"
                  label={t('trading.desc.unitPrice')}
                  decimal={fiatPriceDecimal}
                  asterisked
                  required
                />
                <Button
                  appearance="text"
                  className="absolute right-0 top-0 xs:right-[15px]"
                  type="button"
                  onClick={() => methods.setValue('price', bnFixed(exchangeRate ?? ''))}
                >
                  {t('trading.act.apply')}
                </Button>
              </div>
              <div className="relative mt-[25px] box-border flex flex-col xs:m-0 xs:w-[33.33%] xs:px-[15px] xs:py-0">
                <span className="text-[14px] text-grey-700 dark:text-grey-300">
                  {t('trading.desc.marketPrice')}
                </span>
                <span className="mx-0 my-[8px] text-[16px]">
                  {exchangeRate ? bnFormat(exchangeRate) : '-'}
                </span>
                <span className="absolute right-0 top-[26px] text-grey-700 dark:text-grey-300 xs:right-[15px]">
                  {formData.quote?.toUpperCase()}/{formData.base?.toUpperCase()}
                </span>
              </div>
            </div>
            <div className="xs:mt-[24px] xs:flex xs:flex-row">
              <div className="relative mt-[25px] box-border xs:m-0 xs:w-[33.33%] xs:px-[15px] xs:py-0">
                <FormNumberInput
                  name="amount"
                  label={t('trading.desc.amount')}
                  hint={amountInputHint}
                  decimal={coinDecimal}
                  asterisked
                  required
                />
                <Button
                  appearance="text"
                  className="absolute right-0 top-0 xs:right-[15px]"
                  type="button"
                  onClick={() => methods.setValue('amount', bnFixed(balance ?? ''))}
                >
                  {t('trading.act.max')}
                </Button>
              </div>
              <div className="relative mt-[25px] box-border flex flex-col xs:m-0 xs:w-[33.33%] xs:px-[15px] xs:py-0">
                <span className="text-[14px] text-grey-700 dark:text-grey-300">
                  {t('trading.desc.receive')}
                </span>
                <span className="mx-0 my-[8px] text-[16px]">
                  {formData.price && formData.amount
                    ? bn(formData.price).times(formData.amount).dp(fiat[0].decimal, 1).toFormat()
                    : '-'}
                </span>
                <span className="absolute right-0 top-[26px] text-grey-700 dark:text-grey-300 xs:right-[15px]">
                  {formData.quote?.toUpperCase()}
                </span>
              </div>
            </div>
            <div className="xs:mt-[24px] xs:flex xs:flex-row">
              <div className="relative mt-[25px] box-border xs:m-0 xs:w-[33.33%] xs:px-[15px] xs:py-0">
                <FormNumberInput
                  name="minTotal"
                  label={t('trading.desc.minimalLimit')}
                  hint={t('trading.desc.minLimit', {
                    default: minOrderTotal ? bnFormat(minOrderTotal) : '-',
                    currency: formData.quote?.toUpperCase(),
                  })}
                  decimal={fiatDecimal}
                  asterisked
                  required
                />
                <span className="absolute right-0 top-[26px] text-grey-700 dark:text-grey-300 xs:right-[15px]">
                  {formData.quote?.toUpperCase()}
                </span>
              </div>
              <div className="relative mt-[25px] box-border xs:m-0 xs:w-[33.33%] xs:px-[15px] xs:py-0">
                <FormNumberInput
                  name="maxTotal"
                  label={t('trading.desc.maximalLimit')}
                  hint={t('trading.desc.maxLimit', {
                    default: maxOrderTotal ? bnFormat(maxOrderTotal) : '-',
                    currency: formData.quote?.toUpperCase(),
                  })}
                  decimal={fiatDecimal}
                  asterisked
                  required
                />
                <Button
                  appearance="text"
                  className="absolute right-0 top-0 xs:right-[15px]"
                  type="button"
                  onClick={() =>
                    methods.setValue(
                      'maxTotal',
                      bn(methods.getValues('amount'))
                        .times(methods.getValues('price'))
                        .toFixed(fiatDecimal ?? 0, 3),
                    )
                  }
                >
                  {t('trading.act.max')}
                </Button>
                <span className="absolute right-0 top-[26px] text-grey-700 dark:text-grey-300 xs:right-[15px]">
                  {formData.quote?.toUpperCase()}
                </span>
              </div>
            </div>
            <div className="xs:mt-[24px] xs:flex xs:flex-row">
              <div className="relative mt-[25px] box-border xs:m-0 xs:w-[33.33%] xs:px-[15px] xs:py-0">
                <FormDatetimePicker
                  name="suspendedAt"
                  label={t('trading.desc.suspendDatetime')}
                  defaultValue={
                    stateTradingForm?.suspendedAt
                      ? new Date(stateTradingForm?.suspendedAt)
                      : undefined
                  }
                />
                <Button
                  appearance="text"
                  className="absolute right-0 top-0 xs:right-[15px]"
                  type="button"
                  onClick={() => methods.setValue('suspendedAt', '')}
                >
                  {t('trading.act.clear')}
                </Button>
              </div>
            </div>
            <Alert severity={Severity.Clear} className="mt-[30px]">
              {t('trading.desc.unfinishedNotice')}
            </Alert>
          </div>
          <div className="mt-[30px] text-[16px] font-bold">{t('trading.desc.receivePayment')}</div>
          {bankAccount !== undefined && bankAccount.length > 0 ? (
            <PaymentMethodsList
              name="bankAccountId"
              bankArray={bankAccount}
              defaultSelected={stateTradingForm?.bankAccountId}
            />
          ) : (
            <Alert severity={Severity.Warning} className="mt-[30px]">
              <Trans
                i18nKey="trading.desc.noPaymentMethodNotice"
                values={{ link: t('trading.act.account') }}
                components={[
                  <Button key={0} appearance="text" onClick={() => navigate(Page.Account)} />,
                ]}
              />
            </Alert>
          )}
          <div className="mt-[40px] flex flex-row-reverse">
            <Button
              size="large"
              type="submit"
              disabled={
                bankAccountId === undefined || Object.keys(methods.formState.errors).length !== 0
              }
            >
              {t('trading.act.preview')}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Trading;
