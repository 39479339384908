import { IconButton } from '@mui/material';
import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'src/context/ThemeContext';
import { BankAccount } from 'src/model/Bank';

type Props = {
  batchArray: (BankAccount & {
    status: string | null;
  })[];
};

const Table = ({ batchArray }: Props) => {
  const { t } = useTranslation();
  const { IcOpenInNew } = useContext(ThemeContext).image;
  const elementsMapFunction = (
    v: BankAccount & {
      status: string | null;
    },
  ) => {
    const handleOpenInNew = () => {
      if (!v.imageUrl) return;
      const tab = window.open(v.imageUrl, '_blank');
      if (!tab) return;
      tab.focus();
    };

    return (
      <div key={v.id}>
        <div className="flex items-center text-[14px]">
          {v.paymentMethodType === 'bank' && (
            <>
              <div className="box-border w-2/12 px-3 pb-5 pt-4">{v.name}</div>
              <div className="box-border w-2/12 px-3 pb-5 pt-4">{v.bankName}</div>
              <div className="box-border w-3/12 px-3 pb-5 pt-4">{v.account}</div>
              <div className="box-border w-1/12 px-3 pb-5 pt-4">{v.province}</div>
              <div className="box-border w-1/12 px-3 pb-5 pt-4">{v.city}</div>
              <div className="box-border w-1/12 px-3 pb-5 pt-4">{v.bankBranchName}</div>
            </>
          )}
          {v.paymentMethodType === 'alipay' && (
            <>
              <div className="box-border w-2/12 px-3 pb-5 pt-4">{v.name}</div>
              <div className="box-border w-3/12 px-3 pb-5 pt-4">{v.account}</div>
              <div className="box-border w-6/12 px-3 pb-5 pt-4">
                {v.imageUrl ? (
                  <div className={'flex flex-row items-center'}>
                    <div> {t('bankAccount.desc.alipayQRCodeUploaded')}</div>
                    <div>
                      <IconButton
                        onClick={handleOpenInNew}
                        sx={{ padding: 0, margin: '2px 4px 0' }}
                      >
                        <img className="w-[24px]" src={IcOpenInNew} alt="open-in-new" />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <div>{t('bankAccount.desc.alipayQRCodeUnuploaded')}</div>
                )}
              </div>
            </>
          )}
          <div className="box-border w-2/12 px-3 pb-5 pt-4">
            {v.status && (
              <div>
                <div
                  className={classNames({
                    'text-green dark:text-lime': v.status === 'success',
                    'text-crimson dark:text-red': v.status === 'fail',
                    'text-khaki dark:text-yellow':
                      v.status === 'pending' || v.status === 'processing',
                  })}
                >
                  {v.status !== 'success'
                    ? t(`account.desc.status.${v.status}`)
                    : t('account.desc.success')}
                </div>
                {v.status === 'fail' && (
                  <div className="break-all text-[12px] text-crimson dark:text-red">
                    {t('account.desc.error')}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      </div>
    );
  };

  return (
    <div>
      <div className="mb-[15px] mt-[30px] font-bold text-black-900 dark:text-white">
        {t('bankAccount.desc.paymentType.bank')}
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <div className="flex">
        <div className="box-border w-2/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.accountName')}
        </div>
        <div className="box-border w-2/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.bankInformation')}
        </div>
        <div className="box-border w-3/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.accountId')}
        </div>
        <div className="box-border w-1/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.bankProvince')}
        </div>
        <div className="box-border w-1/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.bankCity')}
        </div>
        <div className="box-border w-1/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.bankBranchName')}
        </div>
        <div className="box-border w-2/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300" />
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      {batchArray.filter((v) => v.paymentMethodType === 'bank').map(elementsMapFunction)}
      <div className="mb-[15px] mt-[30px] font-bold text-black-900 dark:text-white">
        {t('bankAccount.desc.paymentType.alipay')}
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <div className="flex">
        <div className="box-border w-2/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.alipayAccountName')}
        </div>
        <div className="box-border w-8/12 break-words px-3 py-4 text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('bankAccount.desc.alipayAccount')}
        </div>
      </div>
      {batchArray.filter((v) => v.paymentMethodType === 'alipay').map(elementsMapFunction)}
    </div>
  );
};

export default Table;
