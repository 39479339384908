import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import { Ui } from 'src/constant/Env';
import { Page } from 'src/constant/Page';
import { TwoStepVerificationForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { signin } from 'src/service/authService';

type Props = {
  open: boolean;
  onClose: () => void;
  loginData: { email: string; password: string };
  redirectPath?: string;
};

const TfaModal = ({ open, onClose, loginData, redirectPath }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm<TwoStepVerificationForm>();

  const onSubmit = (data: TwoStepVerificationForm) => {
    onClose();
    methods.reset();
    signin({ ...loginData, otp: `gauth=${data.code}` })
      .then(() => {
        if (redirectPath) {
          navigate(redirectPath);

          return;
        }

        navigate(Ui === 'admin' ? Page.Home : Page.Trade);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={onClose}>
      <div data-testid="tfa-modal">
        <div className="text-[32px] font-bold">{t('tfaModal.heading')}</div>
        <Form methods={methods} onSubmit={onSubmit} className="mt-[25px]">
          <FormInput label={t('tfaModal.label')} name="code" autoFocus />
          <div className="mt-[65px] flex justify-end gap-[20px]">
            <Button appearance="secondary" onClick={onClose} type="button">
              {t('act.cancel')}
            </Button>
            <Button type="submit" data-testid="tfa-btn">
              {t('act.submit')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default TfaModal;
