import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import { ThemeContext } from 'src/context/ThemeContext';
import { PageForm } from 'src/model/Form';

type Props = {
  page: number;
  pageCount: number;
  setPage?: (v: number) => void;
};

const Pagination = ({ page, pageCount, setPage }: Props) => {
  const [error, setError] = useState<boolean>(false);
  const { IcPaginationNext, IcPaginationPrev } = useContext(ThemeContext).image;
  const methods = useForm<PageForm>();
  useEffect(() => {
    methods.setValue('page', page.toString());
  }, [page]);

  const isValidPage = (v: number) => v >= 1 && v <= pageCount;

  const onPageChange = (v: number) => {
    if (!isValidPage(v)) return;
    setPage && setPage(v);
  };

  const onSubmit = (data: PageForm) => {
    const inputPage = Number(data.page);
    if (isValidPage(inputPage)) {
      setError(false);
      setPage && setPage(inputPage);
    } else setError(true);
  };

  return (
    <div className="flex h-[30px] w-min gap-[10px]">
      <div
        className="flex h-[30px] w-[20px] cursor-pointer items-center justify-center leading-[30px]"
        onClick={() => onPageChange(page - 1)}
      >
        <img className="cursor-pointer" src={IcPaginationPrev} />
      </div>
      <Form methods={methods} onSubmit={onSubmit}>
        <FormInput
          name="page"
          defaultValue={page}
          className={twMerge(
            'h-[30px] w-[44px] rounded-[4px] border-[1px] border-solid border-light-400 px-[8px] py-[3px] focus:border-[1px] focus:border-solid focus:border-light-400 dark:border-dark-500 dark:focus:border-dark-500',
            classNames({
              'border-crimson focus:border-crimson dark:border-red focus:dark:border-red': error,
            }),
          )}
          regex={new RegExp('^\\d*$')}
        />
      </Form>
      <div className="leading-[30px]">/</div>
      <div className="leading-[30px]">{pageCount < 1 ? '-' : pageCount}</div>
      <div
        className="flex h-[30px] w-[20px] cursor-pointer items-center justify-center leading-[30px]"
        onClick={() => onPageChange(page + 1)}
      >
        <img className="cursor-pointer" src={IcPaginationNext} />
      </div>
    </div>
  );
};

export default Pagination;
