import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import { WithdrawalRecord } from 'src/model/Withdrawal';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<WithdrawalRecord>;
  navigateCallback: (record: WithdrawalRecord) => void;
  onCancel: (id: string) => void;
  onVerifyClick: (record: WithdrawalRecord) => void;
};

const WithdrawalCard = ({ data, navigateCallback, onCancel, onVerifyClick }: Props) => {
  const { t } = useTranslation();

  const onDetailClick = (record: WithdrawalRecord) => () => {
    navigateCallback(record);
  };

  const elementMapFunction = (
    record: WithdrawalRecord,
    index: number,
    array: Array<WithdrawalRecord>,
  ) => (
    <div key={record.id}>
      <div className="mt-[15px] flex flex-col gap-[4px]">
        <div className="flex flex-row items-center gap-[15px] text-[14px]">
          <div className="text-dark-500 dark:text-grey-700">{t('record.desc.coin')}</div>
          <div>{record.coin.toUpperCase()}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px] text-[14px]">
          <div className="text-dark-500 dark:text-grey-700">{t('record.desc.status')}</div>
          <div className="flex gap-[5px]">
            <div>{t(`record.desc.statusDisplay.${record.status}`)}</div>
            {record.status === 'created' && (
              <Button appearance="text" type="button" onClick={() => onVerifyClick(record)}>
                {t('record.desc.verify')}
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center gap-[15px] text-[14px]">
          <div className="text-dark-500 dark:text-grey-700">{t('record.desc.amount')}</div>
          <div>{bnFormat(record.amount)}</div>
        </div>
        <div className="mt-[6px] flex flex-col gap-[2px] text-[12px] text-grey-500 dark:text-grey-700">
          <div>{record.address}</div>
          <div>{format(Date.parse(record.createdAt), 'yyyy/MM/dd HH:mm:ss')}</div>
        </div>
        <div className="mt-[6px] flex justify-end gap-[10px]">
          {(record.status === 'created' || record.status === 'verified') && (
            <Button
              appearance="text"
              className="text-crimson dark:text-red"
              onClick={() => onCancel(record.id)}
            >
              {t('act.cancel')}
            </Button>
          )}
          <Button appearance="text" onClick={onDetailClick(record)}>
            {t('record.act.detail')}
          </Button>
        </div>
      </div>
      <div
        className={classNames('mt-[13px] h-[1px] bg-light-200 dark:bg-dark-700', {
          hidden: index >= array.length - 1,
        })}
      />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default WithdrawalCard;
