import { useContext, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import Alert from 'src/component/Alert';
import { Severity } from 'src/constant/Notification';
import { ThemeContext } from 'src/context/ThemeContext';
import { openSnackbar } from 'src/redux/uiSlice';
import { activateOtp } from 'src/service/authService';

const Step2 = () => {
  const { IcCopy } = useContext(ThemeContext).image;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [secret, setSecret] = useState<string>('');
  const [uri, setUri] = useState<string>('');

  useEffect(() => {
    activateOtp('gauth')
      .then((res) => {
        setSecret(res.secret);
        setUri(res.uri);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, []);

  const onCopy = () => {
    dispatch(openSnackbar({ message: t('message.copied') }));
  };

  return (
    <div>
      <div className="mt-[10px] text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('enableTfa.heading')}
      </div>
      <div className="mt-[30px] text-[20px] font-bold">{t('enableTfa.desc.scanQrcode')}</div>
      <div className="mt-[20px] flex flex-col rounded-[12px] bg-white dark:bg-black-800 sm:flex-row">
        <div className="p-[30px] sm:basis-[33%] sm:p-[40px]">
          <div className="mx-auto my-0 box-border h-[160px] w-[160px] bg-white p-[10px]">
            <QRCode value={uri} size={140} />
          </div>
        </div>
        <div className="flex flex-col gap-[30px] p-[20px] sm:grow-[1] sm:p-[40px]">
          <div className="text-center text-[16px] font-bold">
            {t('enableTfa.desc.enterCodeManually')}
          </div>
          <div className="flex flex-row items-center justify-center text-[20px] font-bold">
            <div>{secret}</div>
            <CopyToClipboard text={secret} onCopy={onCopy}>
              <img src={IcCopy} className="ml-[10px] cursor-pointer" />
            </CopyToClipboard>
          </div>
          <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
          <div className="flex flex-col gap-[10px]">
            <Alert severity={Severity.Clear}>{t('enableTfa.desc.saveKeyNotice')}</Alert>
            <Alert severity={Severity.Warning}>{t('enableTfa.desc.resettingWarning')}</Alert>
          </div>
        </div>
      </div>
      <div className="mt-[40px] text-[16px] font-bold">{t('enableTfa.desc.haveScanned')}</div>
    </div>
  );
};

export default Step2;
