import { isValidElement, ReactNode, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import SelectContext from 'src/context/SelectContext';
import IcSorting from 'src/image/ic-sorting.svg';
import Popover from './Popover';

export type Props = {
  children: ReactNode | ReactNode[];
  className?: string;
  defaultValue?: string;
  value?: string;
  onChange?: (v: string) => void;
};

const SortingSelect = ({
  children,
  className,
  defaultValue,
  value: controlledSelectedValue,
  onChange,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<{ value: string; children: ReactNode }[]>([]);
  const [selected, setSelected] = useState<string>(defaultValue ?? '');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOptions(
      (Array.isArray(children) ? children : [children]).map((child) =>
        isValidElement(child) ? child.props : null,
      ),
    );
  }, []);

  const handleChange = (v: string) => {
    setOpen(false);
    if (controlledSelectedValue === undefined) setSelected(v);

    onChange && onChange(v);
  };

  return (
    <SelectContext.Provider value={{ current: controlledSelectedValue ?? selected, handleChange }}>
      <div className="box-border flex h-[30px] flex-row items-center gap-2 rounded-[4px] border border-solid border-light-500 px-1 py-0 dark:border-dark-500">
        <img src={IcSorting} />
        <div
          className={twMerge('cursor-pointer text-black-900 dark:text-white', className)}
          onClick={() => setOpen(!open)}
          ref={ref}
        >
          {options.find((v) => v.value === (controlledSelectedValue ?? selected))?.children}
        </div>
      </div>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        cssProperties={{ width: 'fit-content' }}
      >
        {children}
      </Popover>
    </SelectContext.Provider>
  );
};

export default SortingSelect;
