import { useEffect, useMemo, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { MediaQuery } from 'src/constant/Media';
import { getOffset, getPageCount } from 'src/util/paginationHelper';

const LIMIT_UNDER_1200 = 5;
const LIMIT_OVER_1200 = 10;

type State = {
  defaultLimit?: number;
  defaultPage?: number;
};

const getDefaultLimit = (isBiggerThanMd: boolean) =>
  isBiggerThanMd ? LIMIT_OVER_1200 : LIMIT_UNDER_1200;

export const usePagination = ({ defaultLimit, defaultPage }: State = {}) => {
  const [page, setPage] = useState<number>(defaultPage ?? 1);
  const [count, setCount] = useState<number>();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const [limit, setLimit] = useState<number>(defaultLimit ?? getDefaultLimit(isBiggerThanMd));
  const offset = useMemo(() => getOffset(page, limit), [page]);
  const pageCount = useMemo(() => getPageCount(count ?? 0, limit), [count]);

  useEffect(() => {
    setCount(undefined);
  }, [limit]);

  useEffect(() => {
    setLimit(defaultLimit ?? getDefaultLimit(isBiggerThanMd));
  }, [isBiggerThanMd]);

  return {
    defaultLimit: getDefaultLimit(isBiggerThanMd),
    limit,
    offset,
    setLimit,
    setCount,
    paginationProps: { page, setPage, pageCount },
  };
};
