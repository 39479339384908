import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import { DepositRecord } from 'src/model/Deposit';
import { openSnackbar } from 'src/redux/uiSlice';
import { getDepositsWithId } from 'src/service/propertyService';
import { bnFormat } from 'src/util/bigNumber';

const DepositDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { IcCopy } = useContext(ThemeContext).image;
  const { recordId } = useParams();
  const [record, setRecord] = useState<DepositRecord>();

  useEffect(() => {
    if (recordId === undefined) {
      navigate(Page.Record, { replace: true });

      return;
    }

    getDepositsWithId(recordId)
      .then((res) => {
        setRecord(res);
      })
      .catch(() => {
        navigate(Page.Record, { replace: true });
      });
  }, [recordId]);

  const onCopy = () => {
    dispatch(openSnackbar({ message: t('message.copied') }));
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('recordDetail.heading', { type: t('recordDetail.desc.deposit') })}
      </div>
      {record && (
        <>
          <div className="text-[16px] font-bold">{`${bnFormat(
            record.amount,
          )} ${record.coin.toUpperCase()}`}</div>
          <div className="xs:flex">
            <div className="py-[20px] xs:w-1/2">
              <div className="text-[16px] text-grey-700 dark:text-grey-300">
                {t('recordDetail.desc.type')}
              </div>
              <div className="text-[16px]">
                {record.transactionHash === null
                  ? t('recordDetail.desc.internalTransfer')
                  : t('recordDetail.desc.deposit')}
              </div>
            </div>
            <div className="py-[20px] xs:w-1/2">
              <div className="text-[16px] text-grey-700 dark:text-grey-300">
                {t('recordDetail.desc.time')}
              </div>
              <div className="text-[16px]">
                {format(Date.parse(record.createdAt), 'yyyy/MM/dd HH:mm:ss')}
              </div>
            </div>
          </div>
          <div className="my-[10px] h-[1px] bg-light-200 dark:bg-dark-700" />
          <div className="flex flex-col">
            <div className="py-[20px]">
              <div className="flex flex-row items-center justify-between text-[16px] leading-[21px] text-grey-700 dark:text-grey-300 xs:justify-start">
                {t('recordDetail.desc.address')}
                <CopyToClipboard onCopy={onCopy} text={record.address}>
                  <img src={IcCopy} className="ml-[10px] cursor-pointer" />
                </CopyToClipboard>
              </div>
              <div className="text-[14px]">{record.address}</div>
            </div>
            {record.transactionHash && (
              <div className="py-[20px]">
                <div className="flex flex-row items-center justify-between text-[16px] leading-[21px] text-grey-700 dark:text-grey-300 xs:justify-start">
                  {t('recordDetail.desc.txId')}
                  <CopyToClipboard onCopy={onCopy} text={record.transactionHash}>
                    <img src={IcCopy} className="ml-[10px] cursor-pointer" />
                  </CopyToClipboard>
                </div>
                <div className="text-[14px]">{record.transactionHash}</div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DepositDetail;
