import classNames from 'classnames';
import { useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import Modal from 'src/component/Modal';
import { MediaQuery } from 'src/constant/Media';
import { ThemeContext } from 'src/context/ThemeContext';
import { openSnackbar } from 'src/redux/uiSlice';

type Props = {
  open: boolean;
  handleClose: () => void;
  userId: string;
};

const InvitationModal = ({ open, handleClose, userId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { IcCopy } = useContext(ThemeContext).image;
  const isBiggerThanXsm = useMediaPredicate(MediaQuery.Xsm);
  const url = `${location.origin}/auth/register?invitation=${userId}`;

  const onCopy = () => {
    dispatch(openSnackbar({ message: t('message.copied') }));
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <div className="text-black-900 dark:text-white">
        <div className="mb-[20px] text-[32px] font-bold">{t('account.desc.inviteCode')}</div>
        <div>{t('account.desc.inviteHint1')}</div>
        <div
          className={classNames(
            'bg-light-200 dark:bg-black-800',
            'mb-[25px] mt-[15px] py-[16px] pl-[15px] pr-[0px]',
            'font-bold text-grey-700 dark:text-grey-300',
            'flex items-center justify-between gap-[5px]',
          )}
        >
          <div>{userId}</div>
          <CopyToClipboard onCopy={onCopy} text={userId}>
            <img src={IcCopy} className="cursor-pointer pr-[10px]" />
          </CopyToClipboard>
        </div>
        <div>{t('account.desc.inviteHint2')}</div>
        <div className="mt-[15px] flex gap-[10px]">
          <div
            className={classNames(
              'h-[96px] w-[96px] bg-white p-[5px]',
              'xs:h-[120px] xs:w-[120px]',
            )}
          >
            <QRCode value={url} size={isBiggerThanXsm ? 120 : 96} />
          </div>
          <div
            className={classNames(
              'flex items-center justify-between gap-[10px]',
              'font-bold text-grey-700 dark:text-grey-300',
              'bg-light-200 dark:bg-black-800',
              'py-[10px] pl-[15px] pr-[0px]',
              'xs:h-auto',
            )}
          >
            <div className="break-all">{url}</div>
            <CopyToClipboard onCopy={onCopy} text={url}>
              <img src={IcCopy} className="cursor-pointer pr-[10px]" />
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InvitationModal;
