import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import { EditDisplayNameForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { updateSlug } from 'src/service/accountService';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const EditDisplayNameModal = ({ open, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm<EditDisplayNameForm>();

  const onSubmit = (dataForm: EditDisplayNameForm) => {
    methods.reset();
    handleClose();
    updateSlug(dataForm.displayName)
      .then(() =>
        dispatch(
          openSnackbar({ message: t('account.desc.updateSuccessfully'), severity: 'notify' }),
        ),
      )
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Form className="flex flex-col" methods={methods} onSubmit={onSubmit}>
        <div className="text-[32px] font-bold text-black-900 dark:text-white">
          {t('account.desc.changeNickName')}
        </div>
        <div className="mt-[25px]">
          <FormInput
            label={t('account.desc.nickName')}
            name="displayName"
            type="text"
            required
            asterisked
          />
        </div>
        <div className="mt-[40px] text-right">
          <Button type="submit">{t('act.submit')}</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditDisplayNameModal;
