import { createContext } from 'react';

type SelectContextType = { current: string; handleChange: (v: string) => void; filter?: boolean };
const SelectContext = createContext<SelectContextType>({
  current: '',
  handleChange: () => undefined,
  filter: true,
});

export default SelectContext;
