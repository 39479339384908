import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import { WithdrawalRecord } from 'src/model/Withdrawal';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<WithdrawalRecord>;
  navigateCallback: (record: WithdrawalRecord) => void;
  onCancel: (id: string) => void;
  onVerifyClick: (record: WithdrawalRecord) => void;
};

const WithdrawalTable = ({ data, navigateCallback, onCancel, onVerifyClick }: Props) => {
  const { t } = useTranslation();

  const onDetailClick = (record: WithdrawalRecord) => () => {
    navigateCallback(record);
  };

  const elementMapFunction = (
    record: WithdrawalRecord,
    index: number,
    array: Array<WithdrawalRecord>,
  ) => (
    <div key={record.id} className="flex flex-col">
      <div className="flex flex-row">
        <div className="box-border w-[calc(100%/11)] break-words pr-[10px]">
          {record.coin.toUpperCase()}
        </div>
        <div className="box-border w-[calc(100%/11)] break-words pr-[10px]">
          <div>{t(`record.desc.statusDisplay.${record.status}`)}</div>
          {record.status === 'created' && (
            <Button appearance="text" type="button" onClick={() => onVerifyClick(record)}>
              {t('record.desc.verify')}
            </Button>
          )}
        </div>
        <div className="box-border w-[calc(100%/11*2)] break-words pr-[10px]">
          {bnFormat(record.amount)}
        </div>
        <div className="box-border w-[calc(100%/11*2)] break-words pr-[10px] text-grey-500 dark:text-grey-700">
          {format(Date.parse(record.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </div>
        <div className="box-border w-[calc(100%/11*4)] break-words pr-[10px] text-[12px]">
          {record.address}
        </div>
        <div className="box-border flex w-[calc(100%/11)] items-center justify-end gap-[10px] break-words pr-[10px]">
          {(record.status === 'created' || record.status === 'verified') && (
            <Button
              appearance="text"
              className="text-crimson dark:text-red"
              onClick={() => onCancel(record.id)}
            >
              {t('act.cancel')}
            </Button>
          )}
          <Button appearance="text" onClick={onDetailClick(record)}>
            {t('record.act.detail')}
          </Button>
        </div>
      </div>
      <div
        className={classNames('my-[15px] h-[1px] bg-light-200 dark:bg-dark-700', {
          hidden: index >= array.length - 1,
        })}
      />
    </div>
  );

  return (
    <div className="mt-[15px]">
      <div className="flex flex-row text-[14px] font-bold text-grey-500 dark:text-grey-700">
        <div className="w-[calc(100%/11)]">{t('record.desc.coin')}</div>
        <div className="w-[calc(100%/11)]">{t('record.desc.status')}</div>
        <div className="w-[calc(100%/11*2)]">{t('record.desc.amount')}</div>
        <div className="w-[calc(100%/11*2)]">{t('record.desc.date')}</div>
        <div className="w-[calc(100%/11*4)]">{t('record.desc.address')}</div>
      </div>
      <div className="my-[15px] h-[1px] bg-grey-200 dark:bg-dark-700" />
      {data.map(elementMapFunction)}
    </div>
  );
};

export default WithdrawalTable;
