import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { Image, Theme } from 'src/constant/Theme';

/**
 * usage example:
 *
 * const { toggleTheme } = useContext(ThemeContext);
 * <Button onClick={toggleTheme}>switch</Button>
 */

type ThemeContextType = {
  theme: Theme;
  toggleTheme: () => void;
  image: { [key: string]: string };
};
export const ThemeContext = createContext<ThemeContextType>({
  theme: Theme.Light,
  toggleTheme: () => undefined,
  image: {},
});

type ThemeProviderProps = {
  children: ReactNode;
};

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<Theme>(Theme.Light);

  const getCurrentTheme = useCallback(() => {
    let res: Theme = (localStorage.getItem('theme') as Theme) ?? Theme.Light;
    if (!Object.values(Theme).includes(res)) res = Theme.Light;

    return res;
  }, []);

  useEffect(() => {
    const current = getCurrentTheme();
    document.documentElement.classList.toggle(Theme.Dark, current === Theme.Dark);
    setTheme(current);
  }, []);

  const toggleTheme = () => {
    const current = getCurrentTheme();
    const newTheme = current === Theme.Light ? Theme.Dark : Theme.Light;
    localStorage.setItem('theme', newTheme);
    document.documentElement.classList.toggle(Theme.Dark, newTheme === Theme.Dark);
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, image: Image[theme] }}>
      {children}
    </ThemeContext.Provider>
  );
};
