import { Drawer as MuiDrawer } from '@mui/material';
import classNames from 'classnames';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import useNav from 'src/hook/useNav';
import IcArrowRight from 'src/image/ic-arrow-right.svg';
import IcLogo from 'src/image/ic-logo.svg';
import { RootState } from 'src/redux/store';
import Button from './Button';
import MenuLanguage from './MenuLanguage';

type Props = { open: boolean; onClose: () => void };

const Drawer = ({ open, onClose }: Props) => {
  const [langOpen, setLangOpen] = useState<boolean>(false);
  const langRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const nav = useNav();
  const {
    toggleTheme,
    image: { IcAnnouncement, IcAnnouncementRed, IcTheme },
  } = useContext(ThemeContext);
  const {
    auth: { accountInfo },
    ui: { unreadAnnouncement },
  } = useSelector((rootState: RootState) => rootState);

  const onNavigate = (path: string) => () => {
    navigate(path);
    onClose();
  };

  return (
    <>
      <MuiDrawer
        anchor="left"
        open={open}
        onClose={onClose}
        classes={{
          paper:
            '!text-[inherit] !bg-white dark:!bg-black-800 box-border !block w-[256px] py-[20px] px-0',
        }}
      >
        <div className="pl-[30px]">
          <img className="cursor-pointer" src={IcLogo} />
        </div>
        <div className="flex h-[48px] items-center pl-[30px] text-[20px] font-bold text-dark-500 dark:text-grey-300">
          {accountInfo?.slug ?? '-'}
        </div>
        <div className="box-border flex h-[60px] gap-[15px] pb-0 pl-[30px] pr-0 pt-[10px]">
          <img className="h-[30px] cursor-pointer" src={IcTheme} onClick={toggleTheme} />
          <img
            className="h-[30px] cursor-pointer"
            src={unreadAnnouncement > 0 ? IcAnnouncementRed : IcAnnouncement}
            onClick={onNavigate(Page.Announcement)}
          />
        </div>
        {nav.map((v) => (
          <div
            key={v.locale}
            className={twMerge(
              'flex h-[50px] cursor-pointer items-center gap-[20px] pl-[30px] pr-[20px] hover:bg-light-100 dark:hover:bg-black-700',
              classNames({
                'bg-light-200 dark:bg-black-500': location.pathname.startsWith(v.path),
              }),
            )}
            onClick={onNavigate(v.path)}
          >
            <img src={v.img} />
            <div>{t(v.locale)}</div>
          </div>
        ))}
        <div
          className="ml-[30px] flex h-[41px] w-fit cursor-pointer items-center text-[14px]"
          onClick={() => setLangOpen(!langOpen)}
          ref={langRef}
        >
          <div>{t('language')}</div>
          <img src={IcArrowRight} />
        </div>
        <div className="ml-[30px] flex h-[41px] w-fit cursor-pointer items-center text-[14px]">
          <Button
            appearance="text"
            className="text-dark-700 dark:text-white"
            onClick={onNavigate(Page.SignOut)}
          >
            {t('auth.logout')}
          </Button>
        </div>
      </MuiDrawer>
      <MenuLanguage open={langOpen} onClose={() => setLangOpen(false)} anchorEl={langRef.current} />
    </>
  );
};

export default Drawer;
