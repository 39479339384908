import {
  GetStatementsCommissionParams,
  GetStatementsCommissionResponse,
  GetStatementsParams,
  GetStatementsResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getStatements = async (params: GetStatementsParams) => {
  try {
    return await http.authGet<GetStatementsResponse>('statements/ask-rebate', { params });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getStatementsCommission = async (params: GetStatementsCommissionParams) => {
  try {
    return await http.authGet<GetStatementsCommissionResponse>('statements/commission', { params });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getStatements,
  getStatementsCommission,
};
