import { LegacyRef, useContext } from 'react';
import { ThemeContext } from 'src/context/ThemeContext';
import IcClose from 'src/image/ic-close.svg';

type Props = {
  message?: string;
  onClose: () => void;
  onNavigate?: () => void;
  top: number;
  myRef: LegacyRef<HTMLDivElement>;
};

const Toast = ({ message, onClose, onNavigate, top, myRef }: Props) => {
  const { IcGoMessage } = useContext(ThemeContext).image;

  return (
    <div
      className="fixed right-[15px] top-[15px] mx-auto my-0 w-[calc(100%-30)] animate-[fadein_0.3s] rounded-[5px] bg-white shadow-[0_8px_15px_0_#00000017] transition-top duration-300 dark:bg-black-500 xs:w-[420px]"
      style={{ top }}
      ref={myRef}
    >
      <div className="box-border flex h-[34px] items-center justify-end border-0 border-b-[1px] border-solid border-light-200 p-[5px] dark:border-dark-700">
        <img className="h-[24px] cursor-pointer" src={IcClose} onClick={onClose} />
      </div>
      <div className="flex gap-[10px] pb-[15px] pl-[15px] pr-[10px] pt-[13px]">
        <div className="flex-1">{message}</div>
        <img
          className="cursor-pointer self-end"
          src={IcGoMessage}
          onClick={() => {
            onClose();
            onNavigate && onNavigate();
          }}
        />
      </div>
    </div>
  );
};

export default Toast;
