import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useQuery from 'src/hook/useQuery';
import { RootState } from 'src/redux/store';
import { loadNamespace } from 'src/service/i18nService';

type Query = { mode?: string };

const Privacy = () => {
  const { t } = useTranslation();
  const { workload } = useSelector((rootState: RootState) => rootState.ui);
  const query = useQuery<Query>();

  useEffect(() => {
    loadNamespace();
    if (query.mode) localStorage.setItem('theme', query.mode);
  }, []);

  if (workload > 0) return <></>;

  return (
    <div className="p-[40px]">
      <h1>{t('app:privacy.h1')}</h1>
      <p>{t('app:privacy.sec-1-p-1')}</p>
      <p>{t('app:privacy.sec-1-p-2')}</p>
      <p>{t('app:privacy.sec-1-p-3')}</p>
      <h2>{t('app:privacy.sec-2-h2')}</h2>
      <h3>{t('app:privacy.sec-2-h3-1')}</h3>
      <h4>{t('app:privacy.sec-2-h4-1')}</h4>
      <p>{t('app:privacy.sec-2-p-1')}</p>
      <h4>{t('app:privacy.sec-2-h4-2')}</h4>
      <p>{t('app:privacy.sec-2-p-2')}</p>
      <h3>{t('app:privacy.sec-2-h3-2')}</h3>
      <h4>{t('app:privacy.sec-2-h4-3')}</h4>
      <p>{t('app:privacy.sec-2-p-3')}</p>
      <h4>{t('app:privacy.sec-2-h4-4')}</h4>
      <p>{t('app:privacy.sec-2-p-4')}</p>
      <h2>{t('app:privacy.sec-3-h2')}</h2>
      <h3>{t('app:privacy.sec-3-h3-1')}</h3>
      <p>{t('app:privacy.sec-3-p-1')}</p>
      <h3>{t('app:privacy.sec-3-h3-2')}</h3>
      <p>{t('app:privacy.sec-3-p-2')}</p>
      <h3>{t('app:privacy.sec-3-h3-3')}</h3>
      <p>{t('app:privacy.sec-3-p-3')}</p>
      <h3>{t('app:privacy.sec-3-h3-4')}</h3>
      <p>{t('app:privacy.sec-3-p-4')}</p>
      <h3>{t('app:privacy.sec-3-h3-5')}</h3>
      <p>{t('app:privacy.sec-3-p-5')}</p>
      <h3>{t('app:privacy.sec-3-h3-6')}</h3>
      <p>{t('app:privacy.sec-3-p-6')}</p>
      <h2>{t('app:privacy.sec-4-h2')}</h2>
      <p>{t('app:privacy.sec-4-p-1')}</p>
      <p>{t('app:privacy.sec-4-p-2')}</p>
      <h2>{t('app:privacy.sec-5-h2')}</h2>
      <p>{t('app:privacy.sec-5-p-1')}</p>
    </div>
  );
};

export default Privacy;
